import * as writeXlsxFile from './write-excel-file.min.js';

export type ExcelColumn = {
  align?: ('left' | 'center' | 'right'),
  alignVertical?: ('top' | 'center' | 'bottom'),
  height?: number,
  span?: number,
  rowSpan?: number,
  wrap?: boolean,
  fontWeight?: 'bold',
  color?: string,
  backgroundColor?: string,
  borderColor?: string,
  borderStyle?: string,
  format?: string
};

export type ExcelColumnValue = ({ value: (Number | String | Date | Boolean) } & ExcelColumn);
export type ExcelColumnItem = ({ width: Number } & ExcelColumn);

export type ExcelRow = {
  value: (Number | String | Date | Boolean),
  type: ('Number' | 'String' | 'Date' | 'Boolean'),
  align?: ('left' | 'center' | 'right'),
  alignVertical?: ('top' | 'center' | 'bottom'),
};

export type ExcelOptions = {
  filePath?: string,
  fileName?: string,
  fontFamily?: string,
  fontSize?: number,
  orientation?: 'landscape',
  dateFormat?: string,
  stickyRowsCount?: number,
  stickyColumnsCount?: number,
  sheet?: string,
  sheets?: string[],
  columns?: ExcelColumnItem[]
}

export function toPascal(str: string) {
  let tmp = '';
  let un = false;

  for (let i = 0; i < str.length; i++) {
    if (i === 0) tmp += str[0].toUpperCase();
    else if (un) {
      tmp += str[i].toUpperCase();
      un = false;
    } else if (['-', '_', ' '].includes(str[i])) un = true;
    else tmp += str[i].toLowerCase();
  }

  return tmp;
}

export function getFileDate() {
  let d = new Date().toLocaleString();
  return d.replace(/\/|, | |\:/g, '-');
}

export function writeExcelFile(data: (ExcelColumnValue[] | ExcelRow[])[], options: ExcelOptions) {
  data.map((r: any) => r.map((c: any) => {
    if (c.type) {
      switch (c.type) {
        case 'String':
          c.type = String;
          break;
        case 'Number':
          c.type = Number;
          break;
        case 'Date':
          c.type = Date;
          break;
        case 'Boolean':
          c.type = Boolean;
          break;
      }
    }
  }));

  writeXlsxFile(data, options);
}