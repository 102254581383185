import '../shared/extensions/string'
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { QaToolsService } from './services/qa-tools.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QrImg } from './modals/qr-img/qr-img';
import { Device } from './classes/device';
import { ImeiIccidEdit } from './modals/imei-iccid-edit/imei-iccid-edit';
import { MessageService } from 'app/shared/components/message/message.service';
import { Alert } from 'app/shared/components/message/message.alert.enum';
import { AuthService } from 'app/shared/auth/auth.service';

@Component({
  selector: 'app-qa-tools',
  templateUrl: './qa-tools.component.html',
  styleUrls: ['./qa-tools.component.scss']
})

export class QaToolsComponent implements OnInit, OnDestroy {
  @ViewChild('virtDiv') virtDiv;

  public unitType = 'Kiwi';
  public sensorType = 'TRP';
  public devices: Device[] = [];
  public virtualDevices: Device[] = [];
  private sensorDevices: Device[] = [];
  private refreshInd;

  public btnText = "Add Virtual Kiwi Device"
  public readonly sensors = [
    "TRP","MSE","BAT","RED","BAP","SRE","BTY","BLG","PIN","PIR","FFC","TLT","RGE", "SND","LTH","SRN"
  ]

  public readonly userFields = [
    { field: 'device_serial', display: "Device Serial"},
    { field: 'type', display: "Type", sort: true },
    { field: 'unit_imei', display: "IMEI/ICCID" }
  ]

  public readonly virtualFields = [
    { field: 'device_serial', display: "Device Serial"},
    { field: 'unit_imei', display: "IMEI/ICCID" },
  ]

  public unitTypes: uTypes[] = [
    { name: 'Kiwi', model: 'SHB' },
    { name: 'PIR', model: 'SHP' },
    { name: 'Hub', model: 'SHH' },
    { name: 'Sensor', model: `${this.sensorType}` }
  ]
  users: any[];
  userDevices: any[] = [];

  constructor(
    private api: QaToolsService,
    private modalService: NgbModal,
    private _messageService: MessageService,
    private changeDetector : ChangeDetectorRef,
    public auth: AuthService
  ) { }

  async ngOnInit() {
    await this.getDevices();
    this.getVirtualDevices();

    this.refreshInd = setInterval(() => {
      this.getVirtualDevices();
    } , 10000); 
  }

  ngOnDestroy() {
    clearInterval(this.refreshInd);
    this.refreshInd = 0;
  }

  async getDevices() {
    this.devices = await this.api.getDevices();
    let temp = await this.api.getUserDevices();
    let hubsensors = await this.api.getHubSensors();
    hubsensors.forEach(ele => temp.unshift(ele));
    this.userDevices = temp;
    console.log(this.userDevices);
    temp = [];
  }

  async getVirtualDevices(scroll = false) {
    let temp = await this.api.getVirtualDevices(this.devices);
    this.sensorDevices = await this.api.getSensors(this.userDevices);
    this.sensorDevices.forEach(ele => temp.push(ele));
    this.virtualDevices = temp;
    temp = [];
   
    this.changeDetector.detectChanges();
    if (scroll && this.unitType === 'Sensor') {
      // scroll to bottom
      this.virtDiv.nativeElement.scrollTop = this.virtDiv.nativeElement.scrollHeight;
    } else if (scroll) {
      // scroll to top
      this.virtDiv.nativeElement.scrollTop = 0;
    }
  }

  private refreshDevices() {
    this.getDevices()
    this.getVirtualDevices();
  }

  async delete(serial) {
    let table = 'deviceserialmaster'
    let d = this.virtualDevices.find(e => e.device_serial === serial);
    if (d.unit_iccid === '') table = 'sensorserialmaster';
    
    await this.api.deleteVirtualDevice(`/${table}/${d.device_id}`);
    this.getVirtualDevices();
  }

  async addVirtual() {
    let unit = this.unitTypes.find(e => e.name === this.unitType);
    if (unit.name === "Sensor") {
      await this.api.addVirtualSensor(this.sensorType);
    } else {
      await this.api.addVirtualDevice(unit.model);
    }
    this.getVirtualDevices(true);
  }

  addAcct(device) {
    let endpoint = `addDevice`;
    var body: any = { device_serial: device.device_serial };

    if (!device.unit_iccid) {
      endpoint = `hubsensors`;
      body = { sensor_serial: device.device_serial }
    }
    this.api.addDevice(endpoint, body).then(result => {
      this.refreshDevices();
    }, (e: ErrorEvent) => {
      this._messageService.setMessage(Alert.DANGER, e.error.error.message);
    });
  }

  async rmAcct(device, rem_dsm = false) {
    let endpoint = `/devices/${device.device_id}`
    let param = `rem_dsm=${rem_dsm}`

    if (device.unit_iccid === '') {
      endpoint = `/hubsensors/${device.device_id}`;
    }
    this.api.deleteDevice(endpoint, param).then(res => {
      this.refreshDevices();
    }, (e: ErrorEvent) => {
      this._messageService.setMessage(Alert.DANGER, e.error.error.message)
    })
  }

  enlargeQr(d) {
    const modalRef = this.modalService.open(QrImg, { size: 'sm' });
    modalRef.componentInstance.serial = d.device_serial;
    modalRef.componentInstance.qr = d.qr;
  }

  async editImeiIccid(d, addAcc = false) {
    if (!d.unit_imei) return this.addAcct(d);
    console.log(d, addAcc);

    const modalRef = this.modalService.open(ImeiIccidEdit, { size: 'sm' });
    modalRef.componentInstance.imei = d.unit_imei;
    modalRef.componentInstance.iccid = d.unit_iccid;

    if (!addAcc) {
      modalRef.componentInstance.note = 'Updating the IMEI/ICCID will remove it' +
      'from Virtual Devices list and it can only be added to an account with the app';
    } else {
      modalRef.componentInstance.note = 'Click "Submit" to activate device; "Cancel" to ' + 
      'skip activation. If you skip activation, "Remove from Account" to update IMEI/ICCID'
    }

    let dsm = await this.api.getDeviceSerialMaster(d.device_serial)

    modalRef.result.then((result) => {
      if (result === false && addAcc) {
        this.addAcct(d);
      } else if (result.imei) {
        this.api.updateImeiIccid(result.imei, result.iccid, d.device_serial, dsm).then((res) => {
          d.unit_imei = result.imei;
          d.unit_iccid = result.iccid;

          if (addAcc) this.addAcct(d);
          else this.refreshDevices();
        });
      }
    });
  }

  onSelect(value) {
    this.unitType = value;

    if (this.unitType === "Sensor") {
      this.btnText = `Add Virtual ${this.sensorType} Sensor`;
    } else {
      this.btnText = `Add Virtual ${this.unitType} Device`;
    }
  }
}

interface uTypes {
  name: String,
  model: String
}
