import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MessageService } from 'app/shared/components/message/message.service';
import { Router } from '@angular/router';
import { Alert } from '../shared/components/message/message.alert.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AuthService } from 'app/shared/auth/auth.service';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss']
})
export class TablesComponent implements AfterViewInit {
  @ViewChild('filterInput') filterInput: ElementRef;
  @ViewChild('currentPageFilter') currentPageFilter: ElementRef;

  private apiBase = environment.API_BASE_URL;

  totalRecords: number = 0;
  pageDensity: number = 50;
  pages: number = 0;

  currentPage: number = 1;
  currentTable: string = 'users';

  filter: string = '';
  filterBy: string = 'id';

  orderBy: string = 'created';
  orderDir: string = 'DESC';

  columns: string[] = [''];
  rows: any[] = [];

  tables: string[] = [
    'users',
    'user-billing-info',
    'unit-command',
    'deviceserialmaster',
    'devices',
    'subscriptions',
    'vzwreqs',
    'device-events',
    'battery-trackings',
    'sensorserialmaster',
    'hubsensors',
    'sensor-histories',
    'businesses',
    'business-accounts',
    'notification-points',
    'segments',
    'firmware',
    'firmware-storage',
    'vfota-firm',
    'serial-mappings',
    'factory-users',
    'work-orders',
    'stage-data',
    'service-tags',
    'prod-data',
    'labels',
  ];

  psqlTables: string[] = [
    'prod-data',
    'service-tags',
    'sensorserialmaster',
    'deviceserialmaster',
    'unit-command',
    'battery-trackings',
    'device-events',
    'sensor-histories',
    'vzwreqs'
  ]

  linkedColumns: string[] = [
    'device_serial',
    'device_id',
    'sensor_serial',
    'sensor_id',
    'business_id',
    'user_id',
  ];

  orderOpts: string[] = [
    'DESC',
    'ASC'
  ];

  linking = {
    device: '/#/device/edit/',
    sensor: '/#/sensor/details/',
    business: '/#/enterprise-customer/detail/',
    user: '/#/user/detail/',
    deviceOrSensor: (data: string, rowData: any[]) => {
      let serial = (rowData ? (rowData.find((e) => e[0] === 'device_serial'))[1] : data);
      return `${(serial.substring(0, 2) === 'SH' ? this.linking.device : this.linking.sensor)}${serial}`;
    }
  };

  linkMap: { [table: string]: { [column: string]: (string | ((data: string, rowData?: any[]) => string)) } } = {
    users: {
      id: this.linking.user,
      business_id: this.linking.business,
      pendingBusinessId: this.linking.business
    },
    devices: {
      id: this.linking.device,
      device_serial: this.linking.device,
      business_id: this.linking.business,
      user_id: this.linking.user
    },
    hubsensors: {
      id: this.linking.sensor,
      sensor_serial: this.linking.sensor,
      business_id: this.linking.business,
      user_id: this.linking.user
    },
    subscriptions: {
      device_id: this.linking.device,
      device_serial: this.linking.device,
      user_id: this.linking.user
    },
    vzwreqs: {
      device_id: this.linking.device,
      device_serial: this.linking.device,
    },
    businesses: {
      id: this.linking.business,
      account_owner_id: this.linking.user
    },
    sensorserialmaster: { sensor_serial: this.linking.sensor },
    deviceserialmaster: { device_serial: this.linking.device },
    'user-billing-info': { user_id: this.linking.user },
    'business-accounts': { business_id: this.linking.business },
    'notification-points': { business_id: this.linking.business },
    'unit-command': {
      device_id: this.linking.deviceOrSensor,
      device_serial: this.linking.deviceOrSensor,
      hub_serial: this.linking.device
    },
    'device-events': {
      device_id: this.linking.device,
      device_serial: this.linking.device,
      user_id: this.linking.user
    },
    'sensor-histories': {
      sensor_id: this.linking.sensor,
      sensor_serial: this.linking.sensor,
      user_id: this.linking.user
    },
    'battery-trackings': {
      device_id: this.linking.device,
      device_serial: this.linking.device,
    }
  }

  get urlFilter() {
    let tmp = `filter[order]=${this.orderBy}%20${this.orderDir}`;

    //Switch between using 'ilike' and 'like' based on if the table is held in postgres 
    //    - ilike allows the filter to be case-insensitive
    //    - MySQL and like case-insensitive by default
    if (this.filter !== '') tmp += `&filter[where][${this.filterBy}][${this.psqlTables.includes(this.currentTable) ? 'ilike' : 'like'}]=%${this.filter}%`;
    return tmp;
  }

  get isMaster() {
    return this.auth?.isMaster;
  }

  get urlWhere() {
    return (this.filter === '' ? '' : `where[${this.filterBy}][${this.psqlTables.includes(this.currentTable) ? 'ilike' : 'like'}]=%${this.filter}%`);
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private auth: AuthService,
    private msg: MessageService
  ) { }

  async ngAfterViewInit() {
    let initializing = true;

    //Run the search after a keyup and no new events occurred after half a second
    fromEvent(this.filterInput.nativeElement, 'keyup').pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => this.filterEvent.bind(this)())
    ).subscribe();

    //Change the current page after a keyup and no new events occurred after half a second
    fromEvent(this.currentPageFilter.nativeElement, 'keyup').pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        if (initializing) return;

        this.currentPage = Number(this.currentPage);
        if (this.currentPage < 1) this.currentPage = 1;
        if (this.currentPage > this.pages) this.currentPage = this.pages;

        this.getRows();
      })
    ).subscribe();

    await this.getTableInfoAndRows(true);
    initializing = false;
  }

  camelToPascal(str: string) {
    let rtn: string = '';
    str.split('').map((c: string, ind: number) => {
      if (ind === 0 || str[(ind - 1)] === '-') rtn += c.toUpperCase();
      else if (c === '-') rtn += ' ';
      else rtn += c;
    });

    return rtn;
  }

  async setTable(table) {
    this.currentTable = table;
    this.currentPage = 1;

    await this.getTableInfoAndRows(true);
  }

  pageBack(first?: boolean) {
    if (first) {
      this.currentPage = 1;
    } else {
      if (this.currentPage === 1) return;
      this.currentPage--;
    }

    this.getRows();
  }

  pageForward(last?: boolean) {
    if (last) {
      this.currentPage = this.pages;
    } else {
      if (this.currentPage === this.pages) return;
      this.currentPage++;
    }

    this.getRows();
  }

  async filterEvent(type?: string, data?: any) {
    switch (type) {
      case 'filter-by':
        this.filterBy = data;
        break;
      case 'order-by':
        this.orderBy = data;
        break;
      case 'density':
        this.pageDensity = data;
        break;
      case 'order-dir':
        this.orderDir = data;
        break;
    }

    if (type !== 'filter-by' || this.filter !== '') {
      this.currentPage = 1;
      await this.getTableInfoAndRows(false, (!type || type.indexOf('order') === -1));
    }
  }

  private keyPaste: boolean = false;
  filterKeyDown(e: KeyboardEvent) {
    if (e.key === 'Control') return;
    if (e.key === 'v' && e.ctrlKey) this.keyPaste = true;
  }

  filterOnPaste() {
    if (this.keyPaste) this.keyPaste = false;
    else this.filterEvent();
  }

  pageKeyDown(event: KeyboardEvent) {
    if (['Backspace', 'Delete', 'Enter', 'ArrowLeft', 'ArrowRight'].includes(event.key)) return;
    if (isNaN(Number.parseInt(event.key))) return this.cancel(event);
  }

  //Stop/ cancel a keydown event
  cancel(event: KeyboardEvent) {
    event.stopImmediatePropagation();
    event.preventDefault();
    event.cancelBubble = true;
    return false;
  }

  async updateField(field: string, rowIndex: number, target: HTMLButtonElement, sibling: HTMLElement) {
    let row = this.rows[rowIndex];
    let value = row.find(dt => dt[0] === field)[1].replace(/\n/g, '');

    //Remove event listeners and attributes when a field edit is completed or canceled
    let finish = () => {
      sibling.removeAttribute('contenteditable');
      sibling.onkeydown = undefined;
      sibling.onkeyup = undefined;

      target.innerText = 'Edit';
      target.removeAttribute('disabled');
      target.removeAttribute('data-type');

      target.nextElementSibling.setAttribute('hidden', '');
      (target.nextElementSibling as any).onclick = undefined;
    }

    //If the target button is labeled Edit, apply the events and attributes to make the field editable
    //    Otherwise, save the new data to the DB
    if (target.innerText === 'Edit') {
      sibling.setAttribute('contenteditable', 'true');
      sibling.onkeydown = (e: any) => {
        if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)) return;

        let sel = window.getSelection().toString();
        if (e.key !== 'Enter' && !sel.length) {
          if (field === 'unit_imei' && sibling.innerText.length === 15) return this.cancel(e);
          if (field === 'unit_iccid' && sibling.innerText.length === 20) return this.cancel(e);
        }

        if (e.key === 'Enter') return e.preventDefault();
        if (isNaN(Number.parseInt(e.key))) return this.cancel(e);
      };

      sibling.onkeyup = (e: any) => {
        let lengthError = 0;
        if (e.target.innerText !== '0') {
          if (field === 'unit_imei' && e.target.innerText.length !== 15) lengthError = 15;
          if (field === 'unit_iccid' && e.target.innerText.length !== 20) lengthError = 20;
        }

        if (e.target.innerText !== value && !lengthError) {
          target.removeAttribute('disabled');
          if (e.key === 'Enter') this.updateField(field, rowIndex, target, sibling);
        } else target.setAttribute('disabled', 'true');
      };

      target.innerText = 'Save';
      target.setAttribute('disabled', 'true');
      target.setAttribute('data-type', 'save');

      target.nextElementSibling.removeAttribute('hidden');
      //If cancel is clicked, reset the field data to org data and clear any test selection
      (target.nextElementSibling as any).onclick = () => {
        sibling.innerText = value;
        finish();

        let sel = window.getSelection();
        sel.removeAllRanges();
      }

      //Select the field text when edit id clicked
      let range = document.createRange();
      range.selectNodeContents(sibling);
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else {
      let _value = sibling.innerText;
      let id = row.find(dt => dt[0] === 'id')[1];
      let url = `${this.apiBase}/${this.currentTable}/${id}`;

      let data = {};
      data[field] = _value;

      try {
        await this.http.patch(url, data, { observe: 'response' }).toPromise();

        let columnInd = row.findIndex(c => c[0] === field);
        this.rows[rowIndex][columnInd][1] = _value;
      } catch (ex) {
        let err = (ex.error?.error?.message || ex.message);
        if (!err) err = 'Unknown error occurred in getRows method';

        this.msg.setMessage(Alert.DANGER, err);
      }

      finish();
    }
  }

  private async getTableInfoAndRows(tableChange: boolean, infoRequired?: boolean) {
    (window as any).showLoader();

    var infoRecv;
    if (infoRequired || infoRequired === undefined) {
      //If the event is a change in order-by or order-dir we do not need to re-grab the table info
      //    Instead, just grab the row data
      infoRecv = await this.getTableInfo.bind(this)();
    }

    if (infoRequired === false || infoRecv) await this.getRows.bind(this)(tableChange);
    (window as any).hideLoader();
  }

  private async getRows(tableChange?: boolean) {
    try {
      let skip = ((this.currentPage - 1) * this.pageDensity);
      let url = `${this.apiBase}/${this.currentTable}?${this.urlFilter}&filter[limit]=${this.pageDensity}&filter[skip]=${skip}`;
      let rtn = await this.http.get<any[]>(url, { observe: 'response' }).toPromise();
      if (!rtn?.body?.length) {
        this.columns = ['No data to display'];
        this.rows = [];
        this.totalRecords = 0;
        this.currentPage = 0;
        this.pages = 0;

        return;
      }

      this.columns = [];
      this.rows = [];

      this.columns.push('row');
      //Run through the first object of the results to push the column/ fields names
      Object.keys(rtn.body[0]).map(col => this.columns.push(col));

      rtn.body.map((d: any, ind: number) => {
        //Add a row field and it page based index and convert the row object to a entries array [[field], [data]]
        let dt = [['row', (skip + ind + 1)], ...Object.entries(d)];

        //Find and timestamps and convert to readable local time.
        dt.map((d: any, i: number) => {
          if (typeof (d[1]) === 'string' && (d[1] as string).match(/[0-9]{4}-[0-9]{2}-[0-9]{2}T/)) (dt[i][1] as string) = new Date((dt[i][1] as string)).toLocaleString();
          if (this.hasLink(d[0], d[1])) d.push(this.createLink(d[0], d[1], dt));
        });

        this.rows.push(dt);
      });

      if (tableChange) {
        let curFilterBy = this.filterBy;

        //Automatically change the filter by field based on the best option available
        if (this.columns.includes('device_serial')) this.filterBy = 'device_serial';
        else if (this.columns.includes('sensor_serial')) this.filterBy = 'sensor_serial';
        else if (this.columns.includes('serial')) this.filterBy = 'serial';
        else if (this.columns.includes('tag')) this.filterBy = 'tag';
        else if (this.columns.includes('prefix')) this.filterBy = 'prefix';
        else if (this.columns.includes('name')) this.filterBy = 'name';
        else if (this.columns.includes('version')) this.filterBy = 'version';
        else if (this.columns.includes('firmware_id')) this.filterBy = 'firmware_id';
        else if (this.columns.includes('vfota_pn')) this.filterBy = 'vfota_pn';
        else if (this.columns.includes('email')) this.filterBy = 'email';
        else if (this.columns.includes('contact_email')) this.filterBy = 'contact_email';
        else this.filterBy = 'id';

        //Re-grab the row data if the filter by field changed
        if (this.filterBy !== curFilterBy && this.filter !== '') this.getRows();
      }
    } catch (ex) {
      let err = (ex.error?.error?.message || ex.message);
      if (!err) err = 'Unknown error occurred in getRows method';

      this.msg.setMessage(Alert.DANGER, err);

      this.columns = [];
      this.rows = [];
      this.totalRecords = 0;
      this.currentPage = 0;
      this.pages = 0;
    }
  }

  private async getTableInfo() {
    var rtn: any;

    try {
      //The users table does not have a count method, no query the table data with the smallest footprint and use the returned length
      let url = `${this.apiBase}/${this.currentTable}${(this.currentTable === 'users' ? `?${this.urlFilter}&filter[fields][realm]=true` : `/count?${this.urlWhere}`)}`;
      rtn = await this.http.get<any[]>(url, { observe: 'response' }).toPromise();

      this.totalRecords = (rtn.body.count || rtn.body.length || 0);
      this.pages = Math.ceil(this.totalRecords / this.pageDensity);

      return true;
    } catch (ex) {
      let err = (ex.error?.error?.message || ex.message);
      if (!err) err = 'Unknown error occurred in getTableInfo method';

      this.msg.setMessage(Alert.DANGER, err);

      this.columns = [];
      this.rows = [];
      this.totalRecords = 0;
      this.currentPage = 0;
      this.pages = 0;
    }

    return false;
  }

  private hasLink(column: string, data: string) {
    if (data === '' || data === '0' || !this.linkMap[this.currentTable] || !this.linkMap[this.currentTable][column]) return false;
    return true;
  }

  private createLink(column: string, data: string, rowData: any[]) {
    if (typeof (this.linkMap[this.currentTable][column]) === 'function') return (this.linkMap[this.currentTable][column] as any)(data, rowData);
    return `${this.linkMap[this.currentTable][column]}${data}`;
  }
}
