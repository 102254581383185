<ng-container *ngIf="scroll === true && sticky === true; else noScroll">
  <div class="table-scroll">
    <table class="table table-striped" matSort (matSortChange)="sortData($event)" id="table"  mdbTable mdbTableScroll stickyHeader="true">
      <thead>
        <tr>
          <th *ngFor="let f of fields" mat-sort-header="{{ f.field }}" scope="col">{{ f.display }}</th>
        </tr>
      </thead>
      
      <ng-template [ngTemplateOutlet]="content"></ng-template>
    </table>
  </div>
</ng-container>

<ng-template #noScroll>
  <ng-container *ngIf="scroll === false && sticky === true; else noStickyHeader">
    <table class="table table-bordered table-striped" matSort (matSortChange)="sortData($event)" id="table">
      <thead>
        <tr>
          <th *ngFor="let f of fields" mat-sort-header="{{ f.field }}" scope="col">{{ f.display }}</th>
        </tr>
      </thead>
    
      <ng-template [ngTemplateOutlet]="content"></ng-template>
    </table>
  </ng-container>
</ng-template>

<ng-template #noStickyHeader>
  <div [class]="(scroll ? 'table-scroll' : '')" #scrollList>
    <table  class="table table-striped" matSort (matSortChange)="sortData($event)" id="table"  mdbTable mdbTableScroll>
      <thead>
        <tr>
          <th *ngFor="let f of fields" mat-sort-header="{{ f.field }}" scope="col">{{ f.display }}</th>
        </tr>
      </thead>
      
      <ng-template [ngTemplateOutlet]="content"></ng-template>
    </table>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
