import { Component, OnInit } from '@angular/core';
import { FotaService } from '../../fota.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'app/http-client.service';
import { SearchService } from 'app/shared/components/search/search.service';
import { MessageService } from 'app/shared/components/message/message.service';
import { Alert } from '../../../../shared/components/message/message.alert.enum';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss']
})
export class DeviceListComponent implements OnInit {

  public devices: any[];
  public deviceCount: number = 0;
  public pageCount: number = null;
  public pageDensity: number = 50;
  public currentPage: number = 0;
  public disableNext: boolean = false;
  public disablePrevious: boolean = true;
  public showLoader: boolean = true;
  public columns = [];
  public rows: any[][] = new Array();
  public tableData;
  public pId: number;
  public pSearchTerm: string;
  public pCurrentPage: number;
  public pPageDensity: number;
  public title = null;

  constructor(
    private _fotaService: FotaService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _httpClientService: HttpClientService,
    private _searchService: SearchService,
    private _messageService: MessageService
  ) { }

  ngOnInit() {
    // Parse optional parameters
    this.pId = +this._activatedRoute.snapshot.paramMap.get('id');
    this.pSearchTerm = this._activatedRoute.snapshot.paramMap.get('st');
    this.pCurrentPage = +this._activatedRoute.snapshot.paramMap.get('cp');
    this.pPageDensity = +this._activatedRoute.snapshot.paramMap.get('pd');

    if (this.pCurrentPage != 0) {
      this.currentPage = +this._activatedRoute.snapshot.paramMap.get('cp');
    }
    if (this.pPageDensity != 0) {
      this.pageDensity = +this._activatedRoute.snapshot.paramMap.get('pd');
    }

    this.title = this._fotaService.getTitle();
    this.getData();
    this.configDisabledFlags();
  }

  loadTableData() {
    this.tableData = [];
    this.rows = [];
    this.columns = ["ID", "Serial Number", "Email", "Current Firmware", "Allowed Firmware"];

    this.devices.forEach((ele) => {
      this.tableData.push([ele.id, ele.device_serial, ele.email, ele.unit_currentfirm, ele.unit_allowedfirm]);
    });
    this.configDisabledFlags();
  }

  private configDisabledFlags() {
    let data = this._searchService.configDisabledFlags(this.currentPage, this.disableNext, this.disablePrevious, this.pageCount);
    this.disableNext = data[0];
    this.disablePrevious = data[1];
  }

  // Get the device count and data
  public getData(param = { cp: this.currentPage, skip: this.currentPage * this.pageDensity }) {

    this.currentPage = param.cp;
    let skip = param.skip;

    this._httpClientService.getDeviceCount('').then((res) => {
      this.deviceCount = res.count;
      this.pageCount = Math.ceil(this.deviceCount / this.pageDensity);
      this._httpClientService.getDevices(this.pageDensity, skip, '').then((res) => {
        this.devices = res;
        this.loadTableData();
        this.showLoader = false;
      }, (error) => {
        this._messageService.setMessage(Alert.DANGER, error);
      });
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  onSetPageDensity(val) {
    this.pageDensity = val;
    this.currentPage = 0;
    this.getData();
  }

  onShowDetail(row) {
    this.pId = row[0];
    let navParams = { cp: this.currentPage, pd: this.pageDensity, st: '', url: 'fota/skyhawk/device/list' };
    this._router.navigate(['/device', this.pId, navParams]);
  }
}

