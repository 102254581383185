import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { HttpClientService } from 'app/http-client.service';
import { Alert } from 'app/shared/components/message/message.alert.enum';
import { MessageService } from 'app/shared/components/message/message.service';
import { SearchService } from 'app/shared/components/search/search.service';
import {
  CreateBusinessService,
  BusinessDetail,
  BusinessUser,
  BusinessBillingInfo,
  CustomPricing
} from '../create-business-modal/services/create-business.service';

import { BusinessOwnerComponent } from '../create-business-modal/sections/business-owner/business-owner.component';
import { BillingInformationComponent } from '../create-business-modal/sections/billing-information/billing-information.component';
import { CustomPricingComponent } from '../create-business-modal/sections/custom-pricing/custom-pricing.component';
import { BusinessDetailsComponent } from '../create-business-modal/sections/business-details/business-details.component';
import { ReviewComponent } from '../create-business-modal/sections/review/review.component';

@Component({
  selector: 'app-enterprise-list',
  templateUrl: './enterprise-list.component.html',
  styleUrls: ['./enterprise-list.component.scss']
})
export class EnterpriseListComponent implements OnInit {

  business: { name: string, city: string, state: string }
  users = [];
  userCount: number = 0;
  pageCount: number = null;
  pageDensity;
  currentPage: number;
  disableNext: boolean;
  disablePrevious: boolean;
  showLoader: boolean;
  pId: string;
  pSearchTerm: string;
  pageDensityValues: number[] = [10, 25, 50];
  sortData = false;

  placeholder = "Search by business name/email";
  public title = "Total Business Count";
  private searchTerm: AbstractControl;
  private goToPage: AbstractControl;

  fields = [
    { field: 'name', display: 'Business Name' },
    { field: 'city', display: 'City' },
    { field: 'state', display: 'State' }
  ]

  constructor(
    private _router: Router,
    private _searchService: SearchService,
    private _httpClientService: HttpClientService,
    private createBus: CreateBusinessService,
    public messageService: MessageService
  ) { }

  ngOnInit() {
    this.initVariables();

    this.searchTerm.setValue('');
    this.goToPage.setValue(1);

    // Parse optional parameters
    let params = JSON.parse(sessionStorage.getItem("ecParams"));

    this.pId = params?.id;

    if (params?.pd) {
      this.pageDensity = (+params?.pd);
      this.currentPage = (+params?.cp);
      this.pSearchTerm = params?.st;
      return this.getDataWithParams();
    }

    this.getData();
  }

  private getDataWithParams() {
    this.goToPage.setValue(this.currentPage + 1);
    this.searchTerm.setValue(this.pSearchTerm);

    this.getData({ cp: this.currentPage, skip: (this.currentPage * this.pageDensity) });
  }

  // Get the device count and data
  public getData(params = { cp: this.currentPage, skip: 0 }, sortData = false) {

    this.sortData = sortData;
    this.currentPage = params.cp;
    var skip = params.skip;

    if (skip <= 0) {
      skip = 0;
    }
    this.showLoader = true;
    this.getBusinessData(skip);
  }
  private getBusinessData(skip: number) {
    this._httpClientService.searchBusinesses(null, null, this.searchTerm.value).subscribe(res => {
      this.userCount = res.length;
      this.pageCount = Math.ceil(this.userCount / this.pageDensity);

      if (this.userCount === 0) {
        this.currentPage = -1;
        this.goToPage.setValue(0);
      }

      let data = this._searchService.configDisabledFlags(this.currentPage, this.disableNext, this.disablePrevious, this.pageCount);
      this.disableNext = data[0];
      this.disablePrevious = data[1];
      this.getBusinesses(skip);
    }, (error) => {
      this.messageService.setMessage(Alert.DANGER, error);
    });
  }

  businesses:any = [];
  private getBusinesses(skip: number) {
    this._httpClientService.searchBusinesses(this.pageDensity, skip, this.searchTerm.value).subscribe(res => {
      this.businesses = res;
      this.showLoader = false;
    }, (error) => {
      this.messageService.setMessage(Alert.DANGER, error);
    });
  }

  onShowDetail(busId: string) {
    let navParams = { cp: this.currentPage, pd: this.pageDensity, st: this.searchTerm.value };
    this._router.navigate(['./enterprise-customer/detail', busId, navParams]);
  }

  onSetPageDensity(val) {
    this.pageDensity = val;
    this.goToPage.setValue(1);
    this.getData({ cp: 0, skip: 0 }, true);
  }

  private initVariables() {
    this.searchTerm = this._searchService.searchTerm;
    this.goToPage = this._searchService.goToPage;
    this.pageDensity = 10;
    this.currentPage = 0;
    this.disableNext = false;
    this.disablePrevious = true;
    this.pageDensityValues = [10, 25, 50]
    this.showLoader = true;
  }

  async createBusiness() {
    this.createBus.reset();

    let result = await this.createBus.open(BusinessDetailsComponent, 'Create Business - Business Details');
    if (result) result = await this.createBus.open(BusinessOwnerComponent, 'Create Business - Business Owner');
    if (result) result = await this.createBus.open(BusinessOwnerComponent, 'Create Business - Billing User');
    if (result) result = await this.createBus.open(BillingInformationComponent, 'Create Business - Business Billing Info');
    if (result) result = await this.createBus.open(CustomPricingComponent, 'Create Business - Custom Pricing');
    if (result) result = await this.createBus.open(ReviewComponent, 'Create Business - Review Data');

    //Loop though the models being updated and the review page
    //  until the user submits or cancels the business creation
    while (typeof (result) === 'string') {
      switch (result) {
        case 'details':
          await this.createBus.open(BusinessDetailsComponent, 'Create Business - Business Details', true);
          break;
        case 'owner':
          await this.createBus.open(BusinessOwnerComponent, 'Create Business - Business Owner', true);
          break;
        case 'billing-user':
          await this.createBus.open(BusinessOwnerComponent, 'Create Business - Billing User', true);
          break;
        case 'billing-info':
          await this.createBus.open(BillingInformationComponent, 'Create Business - Business Billing Info', true);
          break;
        case 'custom-pricing':
          await this.createBus.open(CustomPricingComponent, 'Create Business - Custom Pricing', true);
          break;
      }

      result = await this.createBus.open(ReviewComponent, 'Create Business - Review Data', true);
    }

    if (!result) this.messageService.setMessage(Alert.DANGER, 'User canceled business creation...');
    else {
      let data: CreateBusinessService = this.createBus;

      let segments = await this._httpClientService.getSegments();
      //Find the segment ID based on the segment name
      let segId: number = segments.find(e => e.name === data.businessDetail.businessType.toLowerCase()).id;

      try {
        delete data.businessDetail.businessType;
        let rtn: any = await this._httpClientService.createBusiness({
          ...data.businessDetail,
          phone: this.convertPhoneNumber(data.businessDetail.phone),
          address_2: (data.businessDetail.address_2 || ' '), //address_2 cannot be an empty string or null, set it to a space
          segment_id: segId,
          account_owner_id: ' ' //account_owner_id cannot be an empty string or null, set it to a space
        });

        let busId: string = rtn.id;
        let ownerId = await this.addUser(busId, segId, data.businessOwner, true);
        let billingAdminId = ownerId;
        //Create new account if billing user is not the business owner
        if (data.businessOwner.email !== data.businessBillingUser.email) {
          billingAdminId = await this.addUser(busId, segId, data.businessBillingUser, false);
        }

        //Delete netTermDays if the billing account isn't set to Net 30
        if (!data.businessBillingInfo.netTermDays) delete data.businessBillingInfo.netTermDays;
        delete data.businessBillingInfo.taxExempt; //Not currently in use, delete for now

        rtn = await this._httpClientService.addBusinessBilling(busId, {
          ...data.businessBillingInfo,
          firstName: data.businessBillingUser.first_name,
          lastName: data.businessBillingUser.last_name,
          email: data.businessBillingUser.email,
          company: data.businessDetail.name,
          ownerId,
          billingAdminId
        });

        let cusPriceObj: any = {};
        this.convertCustomPrice(data.customPricing.hub, 'bb_custom_hub_price', cusPriceObj);
        this.convertCustomPrice(data.customPricing.sensors, 'bb_custom_sensor_price', cusPriceObj);
        this.convertCustomPrice(data.customPricing.kiwi, 'bb_custom_kiwi_price', cusPriceObj);
        this.convertCustomPrice(data.customPricing.kiwiir, 'bb_custom_kiwiir_price', cusPriceObj);

        //Only add custom pricing if one or more value is greater than 0
        if (Object.keys(cusPriceObj).length) await this._httpClientService.updateBusinessBilling(busId, cusPriceObj);

        this.messageService.setMessage(Alert.SUCCESS, `Business: ${data.businessDetail.name} added successfully!`);
        this.getData();
      } catch (ex: any) {
        let errData: any = ex.error;
        if (errData && typeof (errData) === 'string') errData = JSON.parse(errData); //If an API error, parse the inner error message

        this.messageService.setMessage(Alert.DANGER, (errData?.message || ex.message));
      }
    }
  }

  //Remove unwanted chars from and phone number sting
  private convertPhoneNumber(value: string) {
    return value.replace(/(\(|\)| |\-)/g, '');
  }

  //Apply the custom price value if it is above 0 and not and empty string
  private convertCustomPrice(value: string, key: string, priceObj: any): string {
    if (!value || value === '00.00' || value === '0.00') return;
    priceObj[key] = value.replace('.', '');
  }

  private async addUser(busId: string, segId: number, data: any, owner: boolean): Promise<string> {
    let fullRights = data.fullAdminRights;
    var userId: string;
    var rtn: any;

    //Create the user if it doesn't already exist
    if (!data.existingUser) {
      delete data.fullAdminRights;
      delete data.existingUser;
      delete data.userId;

      rtn = await this._httpClientService.signupUser({
        ...data,
        phone: this.convertPhoneNumber(data.phone),
        marketing_allowed: 0,
        logintype: 'email',
        segment_id: segId
      });

      rtn = JSON.parse(rtn);
      userId = rtn.id;
    } else {
      userId = data.userId;

      delete data.fullAdminRights;
      delete data.existingUser;
      delete data.userId;
    }

    //Apply the business_id to the user records
    if (owner) await this._httpClientService.addBusinessOwner(busId, userId);
    else await this._httpClientService.addBillingUser(busId, userId);

    let currentRoles = await this._httpClientService.getRoles(userId);
    let newRole = ((owner || fullRights) ? 'busadm' : 'busfin');

    if (!currentRoles.includes(newRole)) {
      let roles = {};
      roles[newRole] = true;

      //If needed add the busadm or busfin roles to the user
      await this._httpClientService.updateRoles(userId, roles);
    }

    return userId;
  }
}
