import { AfterViewInit, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateBusinessService } from '../../services/create-business.service';
import { ModeledForm, ModeledControl, ValidationTypes } from 'app/shared/modeled-form/modeled-form';

@Component({
  selector: 'app-billing-information',
  templateUrl: './billing-information.component.html',
  styleUrls: ['./billing-information.component.scss']
})
export class BillingInformationComponent implements AfterViewInit {
  sameAddress: boolean = false;
  private init: boolean = false;

  constructor(
    private _activeModal: NgbActiveModal,
    public createBus: CreateBusinessService
  ) { }

  ngAfterViewInit() {
    setTimeout(() => this.init = true, 50);
  }

  billingForm = new ModeledForm({
    address: new ModeledControl({
      objectRef: { propertyKey: 'address1', holdingObject: this.createBus.businessBillingInfo },
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.minLength, data: 2 }
      ],
      displayErrors: true,
      debounceMs: 500
    }),
    address2: new ModeledControl({
      objectRef: { propertyKey: 'address2', holdingObject: this.createBus.businessBillingInfo },
      debounceMs: 500
    }),
    city: new ModeledControl({
      objectRef: { propertyKey: 'city', holdingObject: this.createBus.businessBillingInfo },
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.minLength, data: 2 }
      ],
      displayErrors: true,
      debounceMs: 500
    }),
    state: new ModeledControl({
      objectRef: { propertyKey: 'state', holdingObject: this.createBus.businessBillingInfo },
      validators: [
        { type: ValidationTypes.required },
        {
          type: ValidationTypes.custom,
          data: this.checkSelectValue
        }
      ],
      displayErrors: true,
      debounceMs: 500
    }),
    zip: new ModeledControl({
      objectRef: { propertyKey: 'zip', holdingObject: this.createBus.businessBillingInfo },
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.minLength, data: 5 },
        { type: ValidationTypes.maxLength, data: 9 }
      ],
      displayErrors: true,
      debounceMs: 500
    }),
    sage: new ModeledControl({
      objectRef: { propertyKey: 'sageId', holdingObject: this.createBus.businessBillingInfo },
      validators: [{ type: ValidationTypes.required }],
      displayErrors: true,
      debounceMs: 500
    }),
    paymentMethod: new ModeledControl({
      initialValue: !this.createBus.businessBillingInfo.allowDirectDebit,
      displayErrors: true,
      debounceMs: 500
    }),
    paymentTerms: new ModeledControl({
      initialValue: this.createBus.businessBillingInfo.autoCollection,
      displayErrors: true,
      debounceMs: 500
    }),
    taxExempt: new ModeledControl({
      objectRef: { propertyKey: 'taxExempt', holdingObject: this.createBus.businessBillingInfo },
      initialValue: (this.createBus.businessBillingInfo.taxExempt || false),
      displayErrors: true,
      debounceMs: 500
    })
  });

  //Don't allow the form to validate if the selected option is: 'Chose...' or an empty string
  private checkSelectValue(value: string): (string | null) {
    let check = ['', 'Choose...']
    return (check.includes(value) ? 'Selected value invalid..!' : null);
  }

  public setChecked() {
    this.sameAddress = (<HTMLInputElement>document.getElementById('sameAddress') as HTMLInputElement).checked;

    if (this.sameAddress) {
      this.billingForm.controls.address.value = this.createBus.businessDetail.address_1;
      this.billingForm.controls.address2.value = this.createBus.businessDetail.address_2;
      this.billingForm.controls.city.value = this.createBus.businessDetail.city;
      this.billingForm.controls.state.value = this.createBus.businessDetail.state;
      this.billingForm.controls.zip.value = this.createBus.businessDetail.zip;
    } else {
      this.billingForm.controls.address.value = '';
      this.billingForm.controls.address2.value = '';
      this.billingForm.controls.city.value = '';
      this.billingForm.controls.state.value = '';
      this.billingForm.controls.zip.value = '';
    }
  }

  public decline() {
    this._activeModal.close(false);
  }

  public next() {
    this.billingForm.submit();

    //paymentTerms: true = 'Monthly', false = 'Net 30'
    let paymentTerms = this.billingForm.values.paymentTerms;

    //paymentMethod: true = 'ACH', false = 'Credit Card
    this.createBus.businessBillingInfo.allowDirectDebit = !(this.billingForm.values.paymentMethod as boolean);
    this.createBus.businessBillingInfo.autoCollection = (paymentTerms as boolean);

    if (!paymentTerms) this.createBus.businessBillingInfo.netTermDays = 30;
    this._activeModal.close(true);
  }

  public toggleRadio(key: string) {
    this.billingForm.controls[key].value = !this.billingForm.controls[key].value;
    this.billingForm.controls[key].evaluate();
  }

  public getControlValue(key: string) {
    if (!this.init || !this.billingForm.controls) return false;
    return this.billingForm.controls[key]?.value;
  }

  public evalForm() {
    this.billingForm.evaluate();
  }
}
