import { Component, OnInit } from '@angular/core';
import { FotaService } from '../../fota.service';
import { environment } from 'environments/environment'
import { FotaFirmModel } from '../../models/fotafirm.model';
import { AuthService } from 'app/shared/auth/auth.service';

@Component({
  selector: 'app-firmware-list',
  templateUrl: './firmware-list.component.html',
  styleUrls: ['./firmware-list.component.scss']
})
export class FirmwareListComponent implements OnInit {
  fotaFirmFiles: FotaFirmModel[];
  errorMessage = '';

  constructor(
    private _fotaService: FotaService,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.getCurrentFirmware();
  }

  onDelete(f) {
    if (confirm(`Are you sure you want to delete the following firmware instance: ${f.id}?`)) {
      this._fotaService.deleteFirmwareFile(f.id).subscribe((response) => {
        let i = this.fotaFirmFiles.indexOf(f);
        this.fotaFirmFiles.splice(i, 1);
        this.errorMessage = null;
      },(err) => {
        this.errorMessage = err.error.error.message;
      });
    }
  }

  private getCurrentFirmware() {
    this._fotaService.getCurrentFirmware().subscribe((data) => {
      this.fotaFirmFiles = data;
    }, (error) => {
      this.errorMessage = error;
    });
  }
}
