import { Injectable } from '@angular/core';
import { HttpClientService } from 'app/http-client.service';

@Injectable({
    providedIn: 'root'
})
export class AvailableSerials {
    private _prefix: string = '';
    private _serials: string[] = [];

    public serials: string[] = [];

    constructor(private http: HttpClientService) { }

    private filterSerials(filter?: string) {
        this.serials = [];

        if (filter) this.serials = this._serials.filter((e: any) => e.indexOf(filter) !== -1);
        else this.serials = this._serials;
    }

    async searchDevices(prefix?: string, filter?: string) {
        if (!this._serials.length || (prefix && this._prefix !== prefix)) {
            let devs = await this.http.getAvailableDevices(prefix);
            if (!devs) return;

            this._serials = devs;
        }

        if (prefix) this._prefix = prefix;
        this.filterSerials(filter);
    }

    async searchSensors(prefix?: string, filter?: string) {
        if (!this._serials.length || (prefix && this._prefix !== prefix)) {
            let sens = await this.http.getAvailableSensors(prefix);
            if (!sens) return;

            this._serials = sens;
        }

        if (prefix) this._prefix = prefix;
        this.filterSerials(filter);
    }

    async searchAll(filter?: string) {
        if (!this._serials.length) {
            let devs = await this.http.getAvailableDevices();
            let sens = await this.http.getAvailableSensors();

            if (!devs || !sens) return;
            this._serials = [...devs, ...sens];
        }

        this.filterSerials(filter);
    }

    reset() {
        this._prefix = '';
        this._serials = [];
        this.serials = [];
    }
}