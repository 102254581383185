<div class="wrapper sidebar-lg" [dir]="options.direction">
    <div class="app-sidebar" data-active-color="white" data-background-color="primary">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <app-navbar></app-navbar>
    <div class="main-panel">
        <div [hidden]=!HTTPActivity class="loaderCircle"></div>
        <div class="main-content">
            <div class="content-wrapper">
                <div class="container-fluid">
                    <router-outlet></router-outlet>
                    <p class="text-center">API: <span class="text-warning"> {{ API_URL }}</span></p>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</div>
