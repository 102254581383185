<h1>Device List</h1>
<div class="content-body">
  <div [hidden]="!showLoader" class="loaderCircle"></div>

  <app-table-header [title]="title" [deviceCount]="deviceCount" [currentPage]="currentPage" [pageCount]="pageCount"></app-table-header>

  <app-search (onSearch)="getData($event, true)" (onDisplayPage)="getData($event, true)" [pageCount]="pageCount" 
    [placeholder]="placeholder" [pageDensity]="pageDensity">
  </app-search>

  <app-table-pagination [disableNext]="disableNext" [disablePrevious]="disablePrevious" [pageDensity]="pageDensity"
    (onSetPageDensity)="onSetPageDensity($event)" (onDisplayNext)="getData($event, true)"
    (onDisplayPrevious)="getData($event, true)" [currentPage]="currentPage">
  </app-table-pagination>

  <app-message></app-message>
  <div class="horizontal-scroll">
    <app-table-header-sort [fields]="fields" [(data)]="devices" [_sortData]="sortData">
      <tbody>
        <tr *ngFor="let d of devices" [class.table-active]="d.id===pId">
          <td *ngIf="d.user_id" class="btn-link" (click)="onShowUserDetail(d.user_id)">{{ d.user_id }}</td>
          <td *ngIf="!d.user_id && d.business_id" class="btn-link" (click)="onShowBusinessDetail(d.business_id)">{{d.business?.name}} (Unassigned Enterprise Device)</td>
          <td class="btn-link" (click)="onShowEdit(d.id)">{{ d.device_serial }}</td>
          <td class="email-overflow">{{ d.email }}</td>
          <td>{{ d.devicename }}</td>
          <td>{{ d.unit_currentfirm }}</td>
          <td>{{ d.lifetime_tc }}</td>
          <td *ngIf="d.service_tag">{{ d.service_tag }}</td>
        </tr>
      </tbody>
    </app-table-header-sort>
  </div>
</div>


