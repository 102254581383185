import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../shared/dialog/confirmation-dialog/confirmation-dialog.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClientService } from 'app/http-client.service';
import { MessageService } from 'app/shared/components/message/message.service';
import { Alert } from '../../shared/components/message/message.alert.enum';
import { SearchService } from 'app/shared/components/search/search.service';
import { UnitSwapDialog } from 'app/shared/dialog/unit-swap-selector/unit-swap-selector';
import { AuthService } from 'app/shared/auth/auth.service';

@Component({
  selector: 'app-sensor-details',
  templateUrl: './sensor-details.component.html',
  styleUrls: ['./sensor-details.component.scss']
})
export class SensorDetailsComponent implements OnInit {
  id: string = null;
  serial: string = null;
  currentPage: number = 0;
  pageDensity: number = 50;
  sensor: any = [];
  dataLoaded = false;

  userRelated = [];
  firmwareRelated = [];
  networkRelated = [];
  trackingRelated = [];
  logRelated = [];
  sortData: boolean = false;
  serialmaster: any = [];
  exportData = [];
  fileName:any;

  public kibanaUrl = "https://vpc-skyhawk-t3k64b4m7jgpdhr4dy53qj6fuu.us-east-1.es.amazonaws.com";
  public deviceLogSearch = `${this.kibanaUrl}/_plugin/kibana/app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-7d,mode:quick,to:now))` +
    `&_a=(columns:!(_source),index:'43fa9240-b424-11ec-ab4b-fd26466117cb',interval:auto,query:(language:lucene,query:'fields.clientData.data.fromUnit.rfData.serial:`;
  public userDetailLink: string = null;
  public subscription = [];
  public logCount: number = 0;
  public pageCount: number = null;
  public disableNext: boolean;
  public disablePrevious: boolean;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _confirmationDialogService: ConfirmationDialogService,
    private _fb: FormBuilder,
    private _httpClientService: HttpClientService,
    private _messageService: MessageService,
    private _searchService: SearchService,
    private _swapDialog: UnitSwapDialog,
    public auth: AuthService
  ) { }

  // Form Setup
  updateForm = this._fb.group({
    deviceName: ['', [Validators.required, Validators.minLength(2)]],
    deviceDescription: ['', Validators.required]
  });
  get deviceName() { return this.updateForm.get('deviceName'); }
  get deviceDescription() { return this.updateForm.get('deviceDescription'); }
  // End Form Setup

  ngOnInit() {
    let params = this._activatedRoute.snapshot.params;

    if (params.id.length === 11) this.serial = params.id;
    else this.id = params.id;
    this.id = params.id;

    sessionStorage.setItem("sensorParams", JSON.stringify(params));

    this.getData();
  }

  onUpdate() {
    let title = `Please Confirm Update`;
    let message = `Are you sure you want to update the device with the following id: ${this.id}?`;
    this._confirmationDialogService.confirm(title, message, 'Update', 'Cancel', 'warning').then(async (confirmed) => {
      if (confirmed === true) {
        await this._httpClientService.updateSensor(this.id, this.deviceName.value, this.deviceDescription.value);

        this._messageService.setMessage(Alert.SUCCESS, "Device updated");

        this.getData();

      }
    }).catch((error) => {
      this._messageService.setMessage(Alert.DANGER, 'An error was detected...');
      console.log(error);
    }
    );
  }

  onDelete() {
    let title = `Please Confirm Deletion`;
    let message = `Are you sure you want to delete the device with the following Id: ${this.id}?`;
    this._confirmationDialogService.confirm(title, message, 'Delete', 'Cancel', 'danger').then(async (confirmed) => {
      if (confirmed === true) {
        await this._httpClientService.deleteSensor(this.sensor.id);

        this._messageService.setMessage(Alert.SUCCESS, "Device deleted");

        alert('You will be redirected back to the device list.');
        this._router.navigate(['/sensor/list']);

      }
    }).catch((error: ErrorEvent) => {
      this._messageService.setMessage(Alert.DANGER, error.message);
      console.log(error);
    }
    );
  }

  private getData(sortData = false) {
    this.sortData = sortData;

    this._httpClientService.getSensor(this.id, this.serial).subscribe(async (res) => {
      this.sensor = (res[0] || res);
      if (!this.id) this.id = this.sensor.id;

      await this.getSensorSerialMaster();
      this.getUserRelated();
      this.getSubscriptions();
      this.getSensorDetail();
      this.getLogs();

      this.dataLoaded = true;
      this.updateForm.patchValue({
        deviceName: this.sensor.devicename,
        deviceDescription: this.sensor.devicedesc
      });
    }, (err) => {
      this._messageService.setMessage(Alert.DANGER, err);
    });
  }

  getSensorSerialMaster() {
    return this._httpClientService.getSensorSerialMaster(this.sensor.sensor_serial, null).then(res => {
      this.serialmaster = res[0];
    });
  }

  onResetTC() {
    this._httpClientService.resetSensorTC(this.sensor.id).subscribe(() => {
      this._messageService.setMessage(Alert.SUCCESS, "Lifetime Trigger Count reset");
      this.getData();
    }, (error) => {
      console.log(error);
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getUserRelated() {
    this._httpClientService.getUser(this.sensor.user_id).subscribe((user: any) => {
      this.userRelated = [
        { attribute: 'First Name', value: user.first_name },
        { attribute: 'Last Name', value: user.last_name },
        { attribute: 'User ID', value: user.id },
        { attribute: 'Device ID', value: this.sensor.id },
        { attribute: 'Email', value: user.email },
        { attribute: 'Phone', value: this.formatPhone(user.phone) }
      ];
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
    this.userDetailLink = `/user/detail/${this.sensor.user_id}`;
  }

  formatPhone(number) {
    if (number == null || number.length < 10) {
      return "Invalid phone number";
    }
    if (number.length === 10)
      return `(${number.substr(0, 3)}) ${number.substr(3, 3)}-${number.substr(6, 4)}`;

    if (number.length === 11)
      return `${number.substr(0, 1)} (${number.substr(1, 3)}) ${number.substr(4, 3)}-${number.substr(7, 4)}`;
  }

  private getSubscriptions() {
    this._httpClientService.getSensorSubscriptions(this.sensor.id).subscribe(res => {
      this.subscription = [res];
    }, (error) => {
      console.error(error);
    });
  }

  private getSensorDetail() {
    let dev = this.sensor;

    this.trackingRelated = [
      { attribute: 'Notes', value: dev.sensr_device_notes },
      { attribute: 'Type', value: dev.sensr_type },
      { attribute: 'Status', value: this.serialmaster?.status },
      { attribute: 'Service Tag', value: this.serialmaster?.service_tag },
      { attribute: 'Sensor Volts', value: dev.sensr_volts },
      { attribute: 'Battery Low', value: dev.sensr_lowbat },
      { attribute: 'Last Active', value: new Date(dev.sensr_lastActive).toLocaleString() },
      { attribute: 'Email Toggle', value: dev.alert_email_toggle },
      { attribute: 'Text Toggle', value: dev.alert_text_toggle },
      { attribute: 'Signal Strength', value: dev.sensr_rssi },
      { attribute: 'Powered On', value: dev.sensr_power },
      { attribute: 'Heartbeat', value: dev.sensr_heartbeat },
      { attribute: 'Reed status', value: dev.sensr_reed_status },
      { attribute: 'Lifetime Trigger Count', value: this.sensor.lifetime_tc },
    ]
  }

  public getLogs(params = { cp: this.currentPage, skip: 0 }, sortData = false) {
    this.disableNext = true;
    this.disablePrevious = true;
    this.sortData = sortData;
    this.currentPage = params.cp;
    var skip = params.skip;

    if (skip <= 0) {
      skip = 0;
    }
    this.deviceLogSearch += `${this.sensor.sensor_serial}'),sort:!('@timestamp',desc))`;
    this.fileName = `sensor-detail-${this.sensor.sensor_serial}-logs`;
    this._httpClientService.getUnitLogsCount(this.sensor.sensor_serial).subscribe(res => {
      this.logCount = res.count;
      this.pageCount = Math.ceil(this.logCount / this.pageDensity);

      if (this.logCount === 0) {
        this.currentPage = -1;
      }

      this._httpClientService.getUnitLogs(this.pageDensity, skip, this.sensor.sensor_serial).subscribe((res) => {
        this.logRelated = res;
        for (let i = 0; i < this.logRelated.length; i++) {
          this.exportData.push({
            'Timestamp': new Date(this.logRelated[i].created).toLocaleString("en-US"),
            'Comm': this.logRelated[i].command_format,
            'Triggers(s)': this.logRelated[i].triggers,
            'Signal': this.logRelated[i].signal,
            'Voltage': this.logRelated[i].volts,
            'Hub Serial': this.logRelated[i].hub_serial,
            'Comm ID': this.logRelated[i].sensor_comm_id,
          });
          let from = new Date(this.logRelated[i].created);
          let to = new Date(from.getTime() + 5000);
          this.logRelated[i].kibanaSearchUrl = `${this.kibanaUrl}/_plugin/kibana/app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:'${from.toISOString()}',mode:absolute,to:'${to.toISOString()}'))&_a=(columns:!(_source),index:'43fa9240-b424-11ec-ab4b-fd26466117cb',interval:auto,query:(language:lucene,query:'fields.clientData.data.fromUnit.rfData.serial:${this.sensor.sensor_serial}'),sort:!('@timestamp',desc))`;
      }
      }, (err) => {
        console.error(err);
      });

      let data = this._searchService.configDisabledFlags(this.currentPage, this.disableNext, this.disablePrevious, this.pageCount);
      this.disableNext = data[0];
      this.disablePrevious = data[1];
    });
  }

  onSetPageDensity(val) {
    this.pageDensity = val;
    this.currentPage = 0;
    this.getData(true);
  }

  async onSwap() {
    let new_serial = await this._swapDialog.show(this.sensor.sensor_serial.substring(0, 3), true);
    if (!new_serial) return this._messageService.setMessage(Alert.DANGER, 'Sensor Swap Canceled');;
    
    let confirm = await this._confirmationDialogService.confirm('Sensor Swap', `Are you sure you want to swap: ${this.sensor.sensor_serial} for: ${new_serial}?`, 'Yes').catch((error) => {
      this._messageService.setMessage(Alert.DANGER, 'Sensor Swap failed');
      console.log(error);
    });

    if (!confirm) return;

    this._httpClientService.swapSensor(this.sensor.id, (new_serial as string)).subscribe((res) => {
      this._messageService.setMessage(Alert.SUCCESS, `Sensor: ${this.sensor.sensor_serial} has been swapped for: ${new_serial}`);
    }, (e: ErrorEvent) => {
      this._messageService.setMessage(Alert.DANGER, e.error.error.message);
    });
  }
}
