import { Injectable } from '@angular/core';
import { AvailableSerials } from 'app/services/available-serials.service';

@Injectable({
    providedIn: 'root'
})
export class UnitSwapDialog {
    private _handler: { resolve: Function, reject: Function };
    private _main: HTMLElement;
    private _table: HTMLElement;
    private _selected: (HTMLElement | undefined);
    private _filter: HTMLInputElement;
    private _requiresTesting: HTMLInputElement;
    private _rows: HTMLElement[] = [];
    private _submit: HTMLElement;
    private _mainMouseDown: boolean = false;
    private _sensorSwap: boolean = false;

    private debounceInd;
    private debounceValue;

    constructor(private available: AvailableSerials) { }

    private addRow(text: string) {
        let row = document.createElement('span');
        row.style.width = '100%';
        row.style.fontSize = 'large';
        row.style.padding = '15px';
        row.style.borderBottom = '1px solid #d3d7da';
        row.style.cursor = 'pointer';
        row.style.whiteSpace = 'nowrap';
        row.className = 'swap-row';
        row.innerText = text;

        if (text !== 'No useable serials found') {
            row.onclick = ((ev: any) => {
                this._rows.map((e: HTMLElement) => e.removeAttribute('selected'));
                this._selected = ev.target;
                this._selected.setAttribute('selected', 'true');
                this._submit.removeAttribute('disabled');
            }).bind(this);
        } else {
            row.style.pointerEvents = 'none';
        }

        this._rows.push(row);
        this._table.append(row);
    }

    private filterSerials() {
        if (this.debounceInd) return;

        this.debounceInd = setInterval((async () => {
            if (this.debounceValue !== this._filter.value) {
                this.debounceValue = this._filter.value;
                return;
            }

            this._rows.map((r: HTMLElement) => r.remove());
            this._rows = [];

            await this.available.searchDevices(null, this._filter.value.toUpperCase());
            this.available.serials.map((serial: string) => this.addRow(serial));

            if (!this._rows.length) this.addRow('No useable serials found');

            clearInterval(this.debounceInd);
            this.debounceValue = undefined;
            this.debounceInd = undefined;
        }).bind(this), 500);
    }

    private async generateDialog(prefix: string) {
        this._main = document.createElement('div');
        this._main.id = 'swap-sensor-main';
        this._main.style.position = 'absolute';
        this._main.style.top = '0';
        this._main.style.left = '0';
        this._main.style.height = '100%';
        this._main.style.width = '100%';
        this._main.style.backgroundColor = 'rgba(73, 73, 73, 0.6)';
        this._main.style.display = 'flex';
        this._main.style.flexDirection = 'column';
        this._main.style.alignItems = 'center';
        this._main.style.zIndex = '10000';
        this._main.style.cursor = 'pointer';
        this._main.style.paddingTop = '10%';
        this._main.onmousedown = ((ev: any) => {
            let elm: any = document.elementFromPoint(ev.pageX, ev.pageY);
            if (elm?.id && elm.id === 'swap-sensor-main') this._mainMouseDown = true;
            else this._mainMouseDown = false;
        }).bind(this);
        this._main.onmouseup = ((ev: any) => {
            let elm: any = document.elementFromPoint(ev.pageX, ev.pageY);
            if (elm?.id && elm.id === 'swap-sensor-main' && this._mainMouseDown) {
                this._selected = undefined;
                this.finish();
            }
        }).bind(this);

        let panel = document.createElement('div');
        panel.style.height = 'min-content';
        panel.style.width = 'min-content';
        panel.style.maxHeight = '80%';
        panel.style.maxWidth = '80%';
        panel.style.backgroundColor = 'white';
        panel.style.borderRadius = '3px';
        panel.style.padding = '3px';
        panel.style.display = 'flex';
        panel.style.flexDirection = 'column';
        panel.style.cursor = 'default';

        let title = document.createElement('span');
        title.innerText = 'Select New Serial';
        title.style.color = 'white';
        title.style.backgroundColor = '#0073AB';
        title.style.fontSize = 'x-large';
        title.style.padding = '20px';
        title.style.height = 'min-content';
        title.style.whiteSpace = 'nowrap';

        let body = document.createElement('div');
        body.style.display = 'flex';
        body.style.flexDirection = 'column';
        body.style.border = '1px solid #d3d7da';
        body.style.overflow = 'auto';
        body.style.marginLeft = '10px';
        body.style.marginRight = '10px';

        this._table = document.createElement('div');
        this._table.style.display = 'flex';
        this._table.style.flexDirection = 'column';

        let inputs = document.createElement('div');
        inputs.style.margin = '10px';

        this._submit = document.createElement('button');
        this._submit.className = 'swap-btn';
        this._submit.innerText = 'Submit';
        this._submit.setAttribute('disabled', 'true');
        this._submit.onclick = (() => {
            this.finish();
        }).bind(this);

        let cancel = document.createElement('button');
        cancel.className = 'swap-btn';
        cancel.innerText = 'Cancel';
        cancel.onclick = (() => {
            this._selected = undefined;
            this.finish();
        }).bind(this);

        body.append(this._table);
        inputs.append(this._submit, cancel);
        panel.append(title, body, inputs);

        var units: any;
        if (this._sensorSwap) await this.available.searchSensors(prefix);
        else await this.available.searchDevices(prefix);

        units = this.available.serials;

        if (units?.length) {
            this._filter = document.createElement('input');
            this._filter.placeholder = 'Enter Serial to Filter';
            this._filter.style.border = 'none';
            this._filter.style.outline = 'none';
            this._filter.style.border = '1px solid #d3d7da';
            this._filter.style.margin = '10px';
            this._filter.onpaste = this.filterSerials.bind(this);
            this._filter.oncut = this.filterSerials.bind(this);
            this._filter.onkeyup = ((e: any) => {
                if (e.target.value === '') this._filter.style.textTransform = '';
                else this._filter.style.textTransform = 'uppercase';

                this.filterSerials.bind(this)();
            }).bind(this);

            body.insertAdjacentElement('beforebegin', this._filter);

            if (!this._sensorSwap) {
                let reqHolder = document.createElement('div');
                reqHolder.style.marginLeft = '10px';
                reqHolder.style.marginTop = '6px';

                this._requiresTesting = document.createElement('input');
                this._requiresTesting.type = 'checkbox';
                this._requiresTesting.style.marginRight = '3px';
                this._requiresTesting.checked = true;

                let reqLabel = document.createElement('span');
                reqLabel.innerText = 'Requires Testing';
                reqLabel.style.fontSize = 'medium';
                reqLabel.onclick = (() => {
                    this._requiresTesting.checked = !this._requiresTesting.checked;
                }).bind(this);

                reqHolder.append(this._requiresTesting, reqLabel);
                body.insertAdjacentElement('afterend', reqHolder);
            }

            units.map((serial: string) => this.addRow(serial));
        } else {
            this.addRow('No useable serials found');
        }

        this._main.append(panel);
        document.body.append(this._main);
        document.body.setAttribute('swap-open', 'true');
    }

    private finish() {
        var rtn: any;
        if (this._sensorSwap) rtn = this._selected?.innerText;
        else rtn = {
            new_serial: this._selected?.innerText,
            req_testing: (this._requiresTesting?.checked === true)
        };

        this._handler.resolve(rtn);
        document.body.removeAttribute('swap-open');

        clearInterval(this.debounceInd);
        this.debounceValue = undefined;
        this.debounceInd = undefined;

        this.available.reset();
        this._main.remove();
        this._main = undefined;
        this._table = undefined;
        this._selected = undefined;
        this._handler = undefined;
        this._filter = undefined;
        this._submit = undefined;
        this._mainMouseDown = false;
        this._sensorSwap = false;
        this._rows = [];
    }

    show(prefix: string, sensorSwap?: boolean): Promise<(string | { new_serial: string, req_testing: boolean })> {
        this._sensorSwap = sensorSwap;

        return new Promise(async (res, rej) => {
            await this.generateDialog(prefix);

            this._handler = {
                resolve: res,
                reject: rej
            };
        });
    }
}