import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeviceListComponent } from './device-list/device-list.component';
import { DeviceEditComponent } from './device-edit/device-edit.component';

const routes: Routes = [
  { path: 'list', component: DeviceListComponent, data: { title: 'Device List' } },
  { path: 'edit/:id', component: DeviceEditComponent, data: { title: 'Device Edit' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeviceRoutingModule { }

export const DEVICE_ROUTING_COMPONENTS = [
  DeviceListComponent,
  DeviceEditComponent
];

