import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'app/http-client.service';
import { MessageService } from 'app/shared/components/message/message.service';
import { Alert } from '../../shared/components/message/message.alert.enum';
import { SearchService } from 'app/shared/components/search/search.service';
import { writeExcelFile, getFileDate } from 'app/classes/excel/excel-writer';

@Component({
  selector: 'app-device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.scss']
})
export class DeviceDetailComponent implements OnInit {
  @Input() deviceId: string;

  public device;
  userRelated = [];
  firmwareRelated = [];
  networkRelated = [];
  trackingRelated = [];
  logRelated = [];
  activationTxt: string;
  sortData: boolean = false;

  // Params for the Kabana log search link
  public kibanaUrl = "https://vpc-skyhawk-t3k64b4m7jgpdhr4dy53qj6fuu.us-east-1.es.amazonaws.com";
  public deviceLogSearch = `${this.kibanaUrl}/_plugin/kibana/app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-7d,mode:` +
    `quick,to:now))&_a=(columns:!(_source),index:'43fa9240-b424-11ec-ab4b-fd26466117cb',interval:auto,query:(language:lucene,query:'fields.clientData.device_serial:`;
  public userDetailLink: string = null;
  public firmwareUpdateLink: string = null;
  public subscriptions;
  public vzreqs;
  public user;
  public logCount: number = 0;
  public pageCount: number = null;
  public pageDensity: number = 50;
  public currentPage: number = 0;
  public disableNext: boolean;
  public disablePrevious: boolean;
  private batteryChangeTC = 0;
  private serviceTag: any;
  private deactiveIn: any;
  private unit_autocheckin: any;
  exportData = [];
  fileName:any;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _httpClientService: HttpClientService,
    private _messageService: MessageService,
    private _searchService: SearchService
  ) { }

  ngOnInit() {
    this.deviceId = this._activatedRoute.snapshot.paramMap.get('id');
    if (this.deviceId) {
      this.getDevice();
    } else {
      this._messageService.setMessage(Alert.DANGER, "Device not found");
    }
  }

  onResetTC() {
    this._httpClientService.resetTC(this.device.id).subscribe((res) => {
      this._messageService.setMessage(Alert.SUCCESS, "Lifetime Trigger Count reset");
      this.getDevice();
    }, (error) => {
      console.log(error);
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getUserRelated() {
    this._httpClientService.getUser(this.device.user_id).subscribe((res) => {
      this.user = res;
      this.userRelated = [
        { attribute: 'First Name', value: this.user.first_name },
        { attribute: 'Last Name', value: this.user.last_name },
        { attribute: 'User ID', value: this.user.id },
        { attribute: 'Device ID', value: this.device.id },
        { attribute: 'Email', value: this.user.email },
        { attribute: 'Phone', value: this.formatPhone(this.user.phone) }
      ];
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
    this.userDetailLink = "/user/detail/" + this.device.user_id;
  }

  formatPhone(number) {
    if (number == null || number.length < 10) {
      return "Invalid phone number";
    }
    if (number.length === 10)
      return `(${number.substr(0, 3)}) ${number.substr(3, 3)}-${number.substr(6, 4)}`;

    if (number.length === 11)
      return `${number.substr(0, 1)} (${number.substr(1, 3)}) ${number.substr(4, 3)}-${number.substr(7, 4)}`;
  }

  private getFirmwareRelated() {
    this.firmwareRelated = [
      { attribute: 'Current Firmware', value: this.device.unit_currentfirm },
      { attribute: 'Allowed Firmware', value: this.device.unit_allowedfirm },
      { attribute: 'Subscription Status', value: this.device.subscription_status },
      { attribute: 'Disarm Status', value: this.device.disarmstatus },
      { attribute: 'Is Activated', value: this.device.isActivated },
      { attribute: 'Is Active', value: this.device.isactive },
      { attribute: 'Is GPS On', value: this.device.isgpson },
      { attribute: 'In App Notify', value: this.device.isinAppNotify }
    ];
    this.firmwareUpdateLink = "/fota/skyhawk/device/update";
  }

  private getNetworkRelated() {
    this.networkRelated = [] // reset array
    this.networkRelated.push(
      { attribute: 'ICCID', value: this.device.unit_iccid },
      { attribute: 'IMEI', value: this.device.unit_imei }
    );
  }

  private getSubscriptions() {
    this._httpClientService.getDeviceSubscriptions(this.device.device_serial).subscribe((res) => {
      this.subscriptions = res;
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getVzwreqs() {
    this._httpClientService.getVzreqs(this.device.device_serial).subscribe((res) => {
      this.checkForUnknownDate(res);
      this.vzreqs = res;
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private checkForUnknownDate(res) {
    for (let i = 0; i < res.length; i++) {
      let created = res[i].created;

      if (created === 0) {
        res[i].created = "Unknown";
      } else {
        res[i].created = new Date(created).toLocaleString();
      }
    }
  }

  private async getDeviceDetail() {
    let dev = this.device;
    let model = dev.device_serial.substring(0, 3);
    // `pir_adjust` (key) corresponds to app sensitivty (value)
    let appSensitivity = { 1: 5, 2: 4, 3: 3, 4: 2, 5: 1 }

    dev.deactivate === 1 ? this.activationTxt = "Reactivate" : this.activationTxt = "Deactivate";
    await this.getServiceTag();

    this.trackingRelated = [
      { attribute: 'Device Status', value: dev.deviceStatus },
      { attribute: 'Battery Low?', value: dev.isBatteryLow },
      { attribute: 'Signal Low?', value: dev.isSignalLow },
      { attribute: 'Last Active', value: new Date(dev.lastActive).toLocaleString() },
      { attribute: 'Sensor Adjust', value: dev.sensor_adjust },
      { attribute: 'Email Toggle', value: dev.alert_email_totgle },
      { attribute: 'Text Toggle', value: dev.alert_text_toggle },
      { attribute: 'Notify Toggle', value: dev.alert_notify_toggle },
      { attribute: 'Cell Strength', value: dev.callsignalstrength },
      { attribute: 'Powered On?', value: dev.isDevicePowerOn },
      { attribute: 'Auto Checkin', value: dev.unit_autocheckin },
      { attribute: 'Reed On?', value: dev.isreedon },
      { attribute: 'Lifetime Trigger Count', value: dev.lifetime_tc },
      { attribute: 'Battery Change TC', value: this.batteryChangeTC },
      { attribute: 'Interval Minutes', value: dev.st_delay },
      { attribute: 'Back-off Count', value: dev.bsm_mda },
      { attribute: 'Back-off Interval', value: dev.bsm_delay },
      { attribute: 'Notification Count', value: dev.st_dtc },
      { attribute: 'Back-off state', value: dev.bsm_dc },
      { attribute: 'Service Tag', value: this.serviceTag },
      { attribute: 'Notes', devue: dev.device_notes }
    ];
    if (model === 'SHP') {
      this.trackingRelated.splice(6, 0, { attribute: 'PIR Sensitivity', value: appSensitivity[dev.pir_adjust] })
    }
  }

  private getServiceTag() {
    return this._httpClientService.getDeviceSerialMaster(this.device.device_serial, null).then(res => {
      this.serviceTag = res[0]?.service_tag;
    });
  }

  public getLocalLogs(params = { cp: this.currentPage, skip: 0 }, sortData = false) {
    //Disable the Next and Previous buttons to prevent users from clicking on the buttons while the data is being loaded.
    this.disableNext = true;
    this.disablePrevious = true;
    this.sortData = sortData;
    this.currentPage = params.cp;
    var skip = params.skip;

    if (skip <= 0) {
      skip = 0;
    }
    // Construct Logging search link now that we have deviceserial
    this.deviceLogSearch += `${this.device.device_serial}'),sort:!('@timestamp',desc))`;

    this._httpClientService.getUnitLogsCount(this.device.device_serial).subscribe(res => {
      this.logCount = res.count;
      this.pageCount = Math.ceil(this.logCount / this.pageDensity);

      if (this.logCount === 0) {
        this.currentPage = -1;
      }
      this.fileName = `device-detail-${this.device.device_serial}-logs`;
      this._httpClientService.getUnitLogs(this.pageDensity, skip, this.device.device_serial).subscribe((res) => {
        res.forEach(ele => {
          ele.volts = ele.command_format.split(":")[3] / 100;
        })
        this.logRelated = res;
        for (let i = 0; i < this.logRelated.length; i++) {
          this.exportData.push({
            'Timestamp': new Date(this.logRelated[i].created).toLocaleString("en-US"),
            'Comm': this.logRelated[i].command_format,
            'Triggers(s)': this.logRelated[i].triggers?this.logRelated[i].triggers : '',
            'Signal': this.logRelated[i].signal?this.logRelated[i].signal:'',
            'Voltage': this.logRelated[i].volts
          });
          let from = new Date(this.logRelated[i].created);
          let to = new Date(from.getTime() + 5000);
          this.logRelated[i].kibanaSearchUrl = `${this.kibanaUrl}/_plugin/kibana/app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:'${from.toISOString()}',mode:absolute,to:'${to.toISOString()}'))&_a=(columns:!(_source),index:'43fa9240-b424-11ec-ab4b-fd26466117cb',interval:auto,query:(language:lucene,query:'fields.clientData.device_serial:${this.device.device_serial}'),sort:!('@timestamp',desc))`;
        }
      }, (err) => {
        console.error(err);
      });

      let data = this._searchService.configDisabledFlags(this.currentPage, this.disableNext, this.disablePrevious, this.pageCount);
      this.disableNext = data[0];
      this.disablePrevious = data[1];
    });
  }

  private getDevice(sortData = false) {
    this.sortData = sortData;
    let serial = (this.deviceId.length === 12 ? this.deviceId : null);

    this._httpClientService.getDevice(this.deviceId, serial).subscribe((res) => {
      this.device = (res[0] || res);
      this.getBatteryChangeTC();
      this.getUserRelated();
      this.getFirmwareRelated();
      this.getNetworkRelated();
      this.getVzwreqs();
      this.getSubscriptions();
      this.getDeviceDetail();
      this.getLocalLogs();
    }, (err) => {
      this._messageService.setMessage(Alert.DANGER, err);
    });
  }

  onResetAllowed() {
    this._httpClientService.resetAllowedFirm(this.device.id).subscribe(res => {
      this._messageService.setMessage(Alert.SUCCESS, "Allowed Firmware reset");
      this.getDevice();
    }, (err) => {
      console.error(err);
      this._messageService.setMessage(Alert.DANGER, err);
    });
  }

  onChangeDeactivateIn(value) {
    this.deactiveIn = value;
  }

  onChangeUnitCheckin(value) {
    // prepend 0's if needed
    switch (value.length) {
      case 1: return this.unit_autocheckin = `00${value}`
      case 2: return this.unit_autocheckin = `0${value}`
      default: return this.unit_autocheckin = value;
    }
  }

  onDeactivateIn() {
    if (this.deactiveIn >= 0 && this.deactiveIn <= 65535) {
      this._httpClientService.deactivateIn(this.device.id, +this.deactiveIn).subscribe(res => {
        this._messageService.setMessage(Alert.SUCCESS, "Deactivatein set to " + this.deactiveIn);
      }, (err) => {
        console.error(err);
        this._messageService.setMessage(Alert.DANGER, err);
      });
    } else {
      this._messageService.setMessage(Alert.DANGER, "Deactivatein must be a number between 0 and 65535")
    }
  }

  onDeactivate(btnTxt) {
    if (btnTxt === "Reactivate") {
      return this.onReactivate(btnTxt);
    }
    this._httpClientService.deactivate(this.device.id).subscribe(res => {
      this._messageService.setMessage(Alert.SUCCESS, `Device ${btnTxt}d`);
      this.activationTxt = "Reactivate";
    }, (e: ErrorEvent) => {
      console.error(e)
      this._messageService.setMessage(Alert.DANGER, e.error.error.message);
    });
  }

  onReactivate(btnTxt) {
    this._httpClientService.reactivate(this.device.id).subscribe(res => {
      this._messageService.setMessage(Alert.SUCCESS, `Device ${btnTxt}d`);
      this.activationTxt = "Deactivate";
    }, (e: ErrorEvent) => {
      console.error(e);
      this._messageService.setMessage(Alert.DANGER, e.error.error.message);
    });
  }

  onUpdateAutoCheckin() {
    if (this.unit_autocheckin > 0 && this.unit_autocheckin <= 999) {
      this._httpClientService.updateAutoCheckin(this.device.id, this.unit_autocheckin).subscribe(res => {
        this._messageService.setMessage(Alert.SUCCESS, "Auto checkin set to " + this.unit_autocheckin);
      }, (err) => {
        console.error(err);
        this._messageService.setMessage(Alert.DANGER, err);
      });
    } else {
      this._messageService.setMessage(Alert.DANGER, "Auto checkin must a number between 1 and 999")
    }
  }

  onSetPageDensity(val) {
    this.pageDensity = val;
    this.currentPage = 0;
    this.getDevice(true);
  }

  private getBatteryChangeTC() {
    this._httpClientService.getBatteryTrackingLatest(this.device.id).subscribe((res) => {
      if (res && res.length != 0) {
        this.batteryChangeTC = (res[0].trans_cnt_af_low + res[0].trans_cnt_bf_low);
      }
    }, (err) => {
      console.error(err);
    });
  }

  exportConnLogs() {
    this._httpClientService.getConnectionLogs(this.device.id).then((res: any) => {
      if (!res?.body?.connections) return;

      let logSets: any = [];
      res.body.connections.map((log: any) => {
        logSets.push([
          {
            value: log.start_time,
            type: 'String',
            align: 'right'
          },
          {
            value: log.stop_time,
            type: 'String',
            align: 'right'
          },
          {
            value: log.bytes_used,
            type: 'String',
            align: 'right'
          },
          {
            value: log.ip,
            type: 'String',
            align: 'right'
          }
        ]);
      });
  
      let span = 8;
      writeExcelFile([
        [
          {
            value: 'Serial Number:',
            fontWeight: 'bold'
          },
          {
            value: this.device.device_serial,
            span
          }
        ],
        [
          {
            value: 'Record Link:',
            fontWeight: 'bold'
          },
          {
            value: window.location,
            span
          }
        ],
        [
          {
            value: 'MSISDN:',
            fontWeight: 'bold'
          },
          {
            value: (res.body.msisdn || 'N/a'),
            span
          }
        ],
        [
          {
            value: 'ICCID:',
            fontWeight: 'bold'
          },
          {
            value: this.device.unit_iccid,
            span
          }
        ],
        [
          {
            value: 'IMEI:',
            fontWeight: 'bold'
          },
          {
            value: this.device.unit_imei,
            span
          }
        ],
        [],
        [],
        [
          {
            value: 'Start Time',
            fontWeight: 'bold',
            align: 'left'
          },
          {
            value: 'Stop Time',
            fontWeight: 'bold',
            align: 'left'
          },
          {
            value: 'Bytes Used',
            fontWeight: 'bold',
            align: 'left'
          },
          {
            value: 'IP',
            fontWeight: 'bold',
            align: 'left'
          }
        ],
        ...logSets
      ], {
        fileName: `${this.device.device_serial}-vzw-connection-logs-${getFileDate()}.xlsx`,
        fontSize: 10,
        columns: [
          { width: 21 },
          { width: 21 },
          { width: 12 },
          { width: 15 }
        ]
      });
    }).catch((e: ErrorEvent) => {
      this._messageService.setMessage(Alert.DANGER, e.error.error.message);
      console.error(e);
    });
  }

  private vzwReset() {
    this.getVzwreqs();
    this.vzwStatus = undefined;
  }

  vzwStatus: string;
  getActivationStatus() {
    this._httpClientService.getActivationStatus(this.device.id).subscribe((res) => {
      this.vzwStatus = res.status;
    }, (e: ErrorEvent) => {
      this._messageService.setMessage(Alert.DANGER, e.error.error.message);
      console.error(e);
    });
  }

  vzwActivate() {
    this._httpClientService.vzwActivate(this.device.id).subscribe((res) => {
      this._messageService.setMessage(Alert.SUCCESS, 'Device successfully activated!');
      this.vzwReset();
    }, (e: ErrorEvent) => {
      this._messageService.setMessage(Alert.DANGER, e.error.error.message);
      console.error(e);
    });
  }

  vzwDeactivate() {
    this._httpClientService.vzwDeactivate(this.device.id).subscribe((res) => {
      this._messageService.setMessage(Alert.SUCCESS, 'Device successfully deactivated!');
      this.vzwReset();
    }, (e: ErrorEvent) => {
      this._messageService.setMessage(Alert.DANGER, e.error.error.message);
      console.error(e);
    });
  }

  vzwDelete() {
    this._httpClientService.vzwDelete(this.device.id).subscribe((res) => {
      this._messageService.setMessage(Alert.SUCCESS, 'Device successfully deleted!');
      this.vzwReset();
    }, (e: ErrorEvent) => {
      this._messageService.setMessage(Alert.DANGER, e.error.error.message);
      console.error(e);
    });
  }
}
