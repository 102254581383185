import { Component, OnInit } from '@angular/core';
import { MessageService } from './message.service';
import { Alert } from './message.alert.enum';

@Component({
  selector: 'app-message',
  template:

    `

  <div *ngIf="message && type" class="alert fade show {{type}}">
    <button type="button" class="close" (click)="dismissAlert()">&times; </button>
    <div *ngIf="message.split('\n').length === 1"><strong>{{ boldType }}</strong> {{ message }}</div>
    <div *ngIf="message.split('\n').length > 1">
      <div *ngFor="let l of message.split('\n'); let i = index" [style.margin-left]="(i > 0 ? '15px' : '0')">{{ l }}</div>
    </div>
  </div>

  `,

  styles: []
})
export class MessageComponent implements OnInit {

  constructor(private _messageService: MessageService) { }

  message: string = null;
  type: any = null;
  boldType: string;

  ngOnInit(): void {
    this._messageService.message.subscribe((msg) => {
      if (msg.dismiss) return this.dismissAlert();

      this.message = msg.message;
      this.type = msg.type;
      this.boldType = msg.boldType;

      switch (this.type) {
        case Alert.SUCCESS: this.boldType = 'Success!'; break;
        case Alert.DANGER: this.boldType = 'Error!'; break;
        case Alert.WARNING: this.boldType = 'Warning!'; break;
      }
    });

    // when switching pages
    this.dismissAlert();
  }

  dismissAlert() {
    this._messageService.setMessage(Alert.DANGER, null);
  }
}
