<div class="apex-content content container-fluid">
  <h1>Manage {{title}}'s Firmware</h1>
  <p *ngIf="errorMessage" class="text-danger ml-3">Error detected: {{ errorMessage }}</p>

  <div class="content-wrapper">
    <div class="content-body">
      <div class="horizontal-scroll">
      <h2>{{title}} Firmware <small>(DB)</small></h2>

      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Model</th>
            <th>Version</th>
            <th>Release Notes</th>
            <th>Created</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let f of fotaFirmFiles">
            <td>{{ f.model }}</td>
            <td>{{ f.version }}</td>
            <td>{{ f.notes }}
              <i class="feather ft-edit-2" (click)="onEdit(f)"></i>
            </td>
            <td>{{ f.created }}</td>
            <td>
              <a (click)="onDelete(f)" class="text-danger" [attr.disable-item]="!auth.isMaster"><i class="feather ft-delete"></i> Delete</a>
            </td>
          </tr>
        </tbody>
      </table>

      <a class="btn btn-outline-secondary" routerLink="../upload">Add New Firmware...</a>
      </div>
    </div>
  </div>
</div>