import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Firmware } from '../../models/firmware.model';
import { FotaService } from '../../fota.service';
import { Device } from '../../models/device.model';
import { environment } from 'environments/environment'
import { FotaFirmModel } from '../../models/fotafirm.model';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'app/shared/components/message/message.service'
import { Alert } from '../../../../shared/components/message/message.alert.enum';

@Component({
  selector: 'app-device-update',
  templateUrl: './device-update.component.html',
  styleUrls: ['./device-update.component.scss']
})
export class DeviceUpdateComponent implements OnInit {

  devices: Device[];
  selectedDevices;
  population: any[];
  pop: any;
  pieChartSingle: any[];
  submitted = false;
  errorId: number;
  chartSize: any[] = [800, 300];
  reloaded: boolean = false;
  title = "";
  note: string = null;
  currentFirmArray = [];
  targetFirmArray = [];

  constructor(
    private _fotaService: FotaService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _fb: FormBuilder,
    private _messageService: MessageService
  ) { }

  manageForm = this._fb.group({
    currentFirmware: ['', [Validators.required]],
    targetFirmware: ['', [Validators.required]],
    selection: [false, [Validators.required]]
  });
  get currentFirmware() { return this.manageForm.get('currentFirmware'); }
  get targetFirmware() { return this.manageForm.get('targetFirmware'); }
  get selection() { return this.manageForm.get('selection'); }

  ngOnInit() {
    this.submitted = false;
    this.selectedDevices = [];
    this.devices = null;
    this.errorId = 0;
    this.getCurrentFirmware();
    this.getDeployedFirmware();
    this.getDevices();
    this.getPopulation();
    this.title = this._fotaService.getTitle();
    this.note = null;
  }

  // Get the devices associated with the firmware.
  getDevicesByFirmware() {
    this._fotaService.getDevicesByFirmware(this.currentFirmware.value).subscribe((data) => {
      this.devices = data;
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
      if (environment.DEBUG) { console.log(error); }
    });
  }

  reload() {
    location.reload();
  }

  onSubmit() {
    if (this.isErrorFree()) {
      if (confirm("Are you sure you want to update the selected device(s) to firmware version: " + this.targetFirmware.value + "?")) {
        let ids = [];
        this.selectedDevices.forEach((ele) => { ids.push(ele.id); })
        const body = {
          vfota_newpn: this.targetFirmware.value,
          vfota_trigger: 1
        };
        this._fotaService.triggerDeviceUpdate(body, ids).subscribe((response) => {
          this.submitted = true;
        }, (err) => {
          this._messageService.setMessage(Alert.DANGER, "Trigger device update failed");
          console.log(err);
        });
      }
    } else {
      this.submitted = false;
    }
  }

  onChangeCurrentFirmware() {
    if (this.currentFirmware.value === 'all') {
      this.note = "Note, when choosing 'All devices running any firmware' you do run the risk of triggering (or re-assigning) the same firmware. So please confirm before proceeding.";
    } else {
      this.note = null;
    }
    this.getDevicesByFirmware();
  }

  onChangeSelectDevice(device) {
    // if device is selected, add to array. if unselected, remove it
    if (this.selection.value) {
      this.selectedDevices.push({id: device.id, serial: device.device_serial});
    } else {
      this.selectedDevices.forEach((ele, index: number) => {
        if (ele.id === device.id) {
          this.selectedDevices.splice(index, 1);

        }
      })
    }
  }

  // Private Section

  private getCurrentFirmware() {
    this._fotaService.getCurrentFirmware().subscribe((data) => {
      this.targetFirmArray = data;
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getDeployedFirmware() {
    this._fotaService.getDeployedFirmware().subscribe((res) => {
      this.currentFirmArray = res.filter(ele => ele.vfota_version && ele.vfota_version != "0");
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getDevices() {
    this._fotaService.getDevices(null, null).subscribe((res) => {
      this.devices = res;
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getPopulation() {
    this._fotaService.getFirmwareDevicePopulation().subscribe((data) => {
      this.population = data.data;
      this.pieChartSingle = this.population;
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private isErrorFree() {
    let returnVal = false;
    if (this.selectedDevices.length === 0) {
      this._messageService.setMessage(Alert.DANGER, "You must select at least one device");
      this.errorId = 1;
    }
    else if (this.currentFirmware.value === this.targetFirmware.value) {
      this._messageService.setMessage(Alert.DANGER, "You must select differing firmware packages");
    }
    else if (this.currentFirmware.value === null || this.targetFirmware.value === null) {
      this._messageService.setMessage(Alert.DANGER, "You must select the firmware package(s)");
    }
    else {
      returnVal = true;
    }
    return returnVal;
  }

}
