import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablesRoutingModule, USER_ROUTING_COMPONENTS} from './tables-routing.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    TablesRoutingModule,
    FormsModule,
    SharedModule
  ],
  declarations: [USER_ROUTING_COMPONENTS],
  providers: []
})
export class TablesModule { }

