import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from 'app/shared/dialog/confirmation-dialog/confirmation-dialog.service';
import { Validators, FormBuilder } from '@angular/forms';
import { HttpClientService } from 'app/http-client.service';
import { MessageService } from 'app/shared/components/message/message.service'
import { SearchService } from 'app/shared/components/search/search.service';
import { Alert } from '../../shared/components/message/message.alert.enum';
import { AuthService } from 'app/shared/auth/auth.service';
import { UnitSubscriptionDialog } from 'app/shared/dialog/unit-subscription/unit-subscription';
import { SorterService } from 'app/services/sorter.service';

import { writeExcelFile } from 'app/classes/excel/excel-writer';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  pId: string = null;
  pSearchTerm: string = null;
  pCurrentPage: number = null;
  pPageDensity: number = null;
  user: any;
  created: string;
  dataLoaded = false;
  submitted = false;
  emailChanged: boolean = false;
  navigationExtras: NavigationExtras;
  selectedDevices: any[];
  devices: any[] = [];
  subscriptions: any[];
  firmwareUpdateLink: string;
  pageCount: number;
  pageDensity: any = 10;
  disableNext: any;
  disablePrevious: any;
  currentPage: number = 0
  deviceCount: number;
  pageDensityValues: number[] = [10, 25, 50];
  exportData = [];
  fileName: string;

  primaryRoles: string[] = ['master', 'admin', 'puser'];
  secondaryRoles: string[] = ['sim', 'busadm', 'busfin'];
  userRoles: { [role: string]: { has: boolean, enabled: boolean } } = {};
  currentRoles: { [role: string]: boolean } = {};

  userRows = [];
  userFields = ["ID", "First Name", "Last Name", "Created"];

  subFields = ["ID", "Device", "Plan", "Status"]
  tableData = [];

  sortedColumns: any = [
    { display: 'Serial', field: 'serial' },
    { display: 'Name', field: 'devicename' }
  ];

  unsortedColumns: any = [
    { display: 'Firmware', field: 'unit_currentfirm' },
    { display: 'Lifetime Trigger Count', field: 'lifetime_tc' },
    { display: 'Last Alert', field: 'lastActive' },
    { display: 'Last Connection', field: 'lastConnection' }
  ]

  columns: any = [
    ...this.sortedColumns,
    ...this.unsortedColumns
  ];

  get rolesChanged() {
    let r = Object.keys(this.currentRoles).find((role: string) => {
      if (this.currentRoles[role] === undefined || !this.userRoles[role]) return false;
      return (this.currentRoles[role] !== this.userRoles[role].has);
    });

    return (r !== undefined);
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _httpClientService: HttpClientService,
    private _router: Router,
    private _confirmationDialogService: ConfirmationDialogService,
    private _fb: FormBuilder,
    private _messageService: MessageService,
    private _searchService: SearchService,
    private _unitSubDialog: UnitSubscriptionDialog,
    public auth: AuthService,
    private sorterService: SorterService
  ) { }

  // Form Setup
  userForm = this._fb.group({
    email: ['', [Validators.required, Validators.email]],
    phone: ['', [Validators.required]],
    emailVerified: [{ value: false, disabled: true }],
    marketingAllowed: [false]
  });
  get email() { return this.userForm.get('email'); }
  get phone() { return this.userForm.get('phone'); }
  get emailVerified() { return this.userForm.get('emailVerified'); }
  get marketingAllowed() { return this.userForm.get('marketingAllowed'); }
  get authRoles() { return this.auth.roles; }

  ngOnInit() {
    this.selectedDevices = [];
    let paramMap = JSON.parse(this._activatedRoute.snapshot.queryParamMap.get('params'));

    this.pId = this._activatedRoute.snapshot.params.id;

    if (paramMap !== null) {
      this.pSearchTerm = paramMap.st;
      this.pCurrentPage = paramMap.cp;
      this.pPageDensity = paramMap.pd;

      // save params for going back to user list
      sessionStorage.setItem("usrParams", JSON.stringify(paramMap));
    }
    this.getUser();

    this.firmwareUpdateLink = "/fota/skyhawk/device/update";

    //setTimeout(() => this._messageService.setMessage(Alert.SUCCESS, 'Subscriptions added successfully!\ntest\ntest2'), 1000);
  }

  onEmailChanged() {
    this.emailChanged = true;
  }

  getRoles() {
    this._httpClientService.getRoles(this.user.id).then((res) => {
      [...this.primaryRoles, ...this.secondaryRoles].map((r: string) => {
        let has = res.includes(r);
        let enabled = false;

        if (this.authRoles.includes('master') || !res.includes('master')) {
          if ((r === 'master' || r === 'sim') && this.authRoles.includes('master')) enabled = true;
          if ((r === 'admin' || r === 'puser') && (this.authRoles.includes('master') || this.authRoles.includes('admin'))) enabled = true;
        }

        this.userRoles[r] = { enabled, has };
        this.currentRoles[r] = has;
      });
    }, (e: ErrorEvent) => {
      this._messageService.setMessage(Alert.DANGER, e.error?.error?.message);
    });
  }

  toggleRole(role: string) {
    this.userRoles[role].has = !this.userRoles[role].has;

    if (this.primaryRoles.includes(role)) {
      this.primaryRoles.map((_role: string) => {
        if (_role !== role) this.userRoles[_role].has = false;
      });
    }
  }

  updateRoles() {
    let _roles: { [role: string]: boolean } = {};
    let changed: string[] = [];

    Object.entries(this.userRoles).map(([k, v]) => {
      if (v.has != this.currentRoles[k]) changed.push(k);
      _roles[k] = v.has;
    });

    this._httpClientService.updateRoles(this.user.id, _roles).then(() => {
      this._messageService.setMessage(Alert.SUCCESS, "User roles updated");
      this.getRoles();

      if (this.auth?.email === this.user?.email && changed.find(r => this.primaryRoles.includes(r))) {
        alert('Logged is users role changed, you will now be logged out of the portal.');
        this.auth.logout();
      }
    }, (err) => {
      console.log(err);
      this._messageService.setMessage(Alert.DANGER, err);
    });
  }

  onSendVerificationEmail() {
    let title = "Resend Verification Email";
    let message = "Are you sure you want to resend the verification email?";
    this._confirmationDialogService.confirm(title, message, 'Yes', 'Cancel', 'warning').then((confirmed) => {
      if (confirmed) {
        this._httpClientService.sendVerificationEmail(this.user.email).subscribe((res) => {
          this._messageService.setMessage(Alert.SUCCESS, "Verification email sent");
        }, (err) => {
          console.log(err);
          this._messageService.setMessage(Alert.DANGER, err)
        });
      }
    });
  }

  onDelete() {
    let title = `Please Confirm Deletion`;
    let message = `Are you sure you want to delete the user with the following email address: ${this.email.value}?`;
    let note = ' Note: This user will not be able to log into their account after deletion.'
    this._confirmationDialogService.confirm(title, message, 'Delete', 'Cancel', 'danger', note).then((confirmed) => {
      if (confirmed === true) {
        this._httpClientService.deleteUser(this.user.id).subscribe((res) => {
          this._messageService.setMessage(Alert.SUCCESS, "User deleted");
          this._router.navigate(['/user/list', { pd: this.pPageDensity, st: this.pSearchTerm, cp: this.pCurrentPage }]);
        }, (err) => {
          console.log(err);
          this._messageService.setMessage(Alert.DANGER, err);
        });
      }
    }).catch((error) => {
      this._messageService.setMessage(Alert.DANGER, "An error was detected...");
      console.log(error);
    });
  }

  onResetPassword() {
    let title = `Please Confirm Password Reset`;
    let message = `Are you sure you want to force a password reset for the  the user with the following email: ${this.user.email}?`;
    let note = ' Note: Please make sure the user has logged out of their account prior to initiating this reset.'
    this._confirmationDialogService.confirm(title, message, 'Reset Password', 'Cancel', 'warning', note).then((confirmed) => {
      if (confirmed === true) {
        this._httpClientService.resetPassword(this.user.email).subscribe((res) => {
          this._messageService.setMessage(Alert.SUCCESS, "This user's password has been reset")
        }, (err) => {
          console.log(err);
          this._messageService.setMessage(Alert.DANGER, err)
        });
      }
    }).catch((error) => {
      console.log(error);
      this._messageService.setMessage(Alert.DANGER, "An unknown error has occured...")
    });
  }

  onUpdate() {
    let title = `Please Confirm Update`;
    let message = `Are you sure you want to update the user with the following email address/id: ${this.user.email}/${this.user.id}?`;
    this._confirmationDialogService.confirm(title, message, 'Update', 'Cancel', 'warning').then((confirmed) => {
      if (confirmed === true) {
        if (this.emailChanged) {
          this.changeEmail();
        }

        if (this.userUpdated()) {
          this.updateUser();
        }
      }
    }).catch((error) => {
      console.log(error);
      this._messageService.setMessage(Alert.DANGER, "An unknown error has occured...")
    });
  }

  private changeEmail() {
    this._httpClientService.changeEmail(this.user.id, this.email.value).subscribe((res) => {
      this._messageService.setMessage(Alert.SUCCESS, "Email changed");
      this.emailVerified.setValue(false);
      this.emailVerified.disable();
    }, (err: string) => {
      this._messageService.setMessage(Alert.DANGER, err);
    });
  }

  private updateUser() {
    const body = { phone: this.phone.value, marketing_allowed: (this.marketingAllowed.value ? 1 : 0) };

    this._httpClientService.updateUser(this.user.id, body).subscribe((res) => {
      this._messageService.setMessage(Alert.SUCCESS, "User has been updated");
      this.submitted = true;
    }, (err: string) => {
      this._messageService.setMessage(Alert.DANGER, err);
    });
  }

  onChangeSelectDevice(device) {
    if (device.checked) {
      this.selectedDevices.push({ device_serial: device.device_serial });
    } else {
      this.selectedDevices.forEach((ele: { id: number }, index: any) => {
        if (ele.id === device.id) {
          this.selectedDevices.splice(index, 1);
        }
      })
    }
  }

  onSelectAll() {
    if (this.devices.every(val => val.checked == true)) {
      this.devices.forEach(val => { val.checked = false });
      this.selectedDevices = [];
    } else {
      this.selectedDevices = [];
      this.devices.forEach(val => {
        val.checked = true
        this.selectedDevices.push({ device_serial: val.device_serial });
      });
    }
  }

  onUpdateDevices() {
    let queryParams: any = {};
    queryParams.devices = JSON.stringify(this.selectedDevices);
    let navigationExtras: NavigationExtras = { queryParams };

    this._router.navigate(['/fota/skyhawk/device/update'], navigationExtras);
  }

  private userUpdated() {
    let u = this.user;
    return (u.phone !== this.phone.value || u.marketing_allowed !== this.marketingAllowed.value);
  }

  private getUser() {
    this._httpClientService.getUser(this.pId).subscribe(data => {
      this.user = data;
      let user = this.user;

      this.created = new Date(user.created).toLocaleString();
      this.fileName = `user-devices-${user.first_name}-${user.last_name}`.toLowerCase();

      this.userRows.push([user.id, user.first_name, user.last_name, this.created]);

      this.email.setValue(user.email);
      this.phone.setValue(user.phone);
      this.emailVerified.setValue(user.emailVerified);
      this.marketingAllowed.setValue(user.marketing_allowed);
      this.dataLoaded = true;

      this.getDevices({ cp: this.currentPage, skip: (this.currentPage * this.pageDensity) });
      this.getSubscriptions();
      this.getRoles();
    }, (err) => {
      this._messageService.setMessage(Alert.DANGER, err)
    });
  }

  private getSubscriptions() {
    this._httpClientService.getUserSubscriptions(this.pId).subscribe((res: any) => {
      this.subscriptions = res;

      this.subscriptions.forEach((ele) => {
        this.tableData.push([ele.subscription_id, ele.device_serial, ele.plan_id, ele.status]
        );
      });
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getDevices(param = { cp: this.currentPage, skip: 0 }) {

    this.currentPage = param.cp;
    let skip = param.skip;

    if (skip <= 0) skip = 0;

    this.getDeviceData(skip)
  }

  private getDeviceData(skip) {
    this._httpClientService.getUserDevices(this.pId).subscribe((res) => {
      this.devices = [];
      this.exportData = [];

      let allDevices = res.devices;
      if (res.hubsensors) allDevices = (allDevices?.concat(res.hubsensors) ?? []);
      if (!allDevices) return;

      for (let i = skip; i < allDevices.length; i++) {
        let dev = allDevices[i]
        dev.checked = false;

        if (dev.sensr_lastActive) dev.lastActive = dev.sensr_lastActive;

        if (dev.lastActive) {
          dev.lastActive = new Date(dev.lastActive).toLocaleString();
        } else {
          dev.lastActive = 'Unknown';
        }

        if (dev.sensr_lastConnection) dev.lastConnection = dev.sensr_lastConnection;

        if (dev.lastConnection) {
          dev.lastConnection = new Date(dev.lastConnection).toLocaleString();
        } else {
          dev.lastConnection = 'Unknown';
        }
        if (this.devices.length < this.pageDensity) {
          this.devices.push({
            'id': (dev.id),
            'serial': (dev.device_serial ?? dev.sensor_serial),
            'device_serial': (dev.device_serial),
            'sensor_serial': (dev.sensor_serial),
            'devicename': (dev.devicename ?? ''),
            'unit_currentfirm': (dev.unit_currentfirm ?? ''),
            'lifetime_tc': dev.lifetime_tc,
            'lastActive': dev.lastActive,
            'lastConnection': dev.lastConnection,
            'checked': dev.checked
          });
          this.exportData.push({
            'Serial': (dev.device_serial ?? dev.sensor_serial),
            'Name': (dev.devicename ?? ''),
            'Firmware': (dev.unit_currentfirm ?? ''),
            'Lifetime Trigger Count': dev.lifetime_tc,
            'Last Active': dev.lastActive
          });
        }
      }

      this.setPaginationData(allDevices.length);
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private setPaginationData(count: number) {
    this.deviceCount = count;
    this.pageCount = Math.ceil(this.deviceCount / this.pageDensity);

    if (this.deviceCount === 0) {
      this.currentPage = -1;
    }

    let data = this._searchService.configDisabledFlags(this.currentPage, this.disableNext, this.disablePrevious, this.pageCount);
    this.disableNext = data[0];
    this.disablePrevious = data[1];
  }

  onSetPageDensity(val) {
    this.pageDensity = val;
    this.currentPage = 0;
    this.getDevices();
  }

  async onSubDev() {
    let res: any = await this._unitSubDialog.show();
    if (!res) return;

    let rtn = await this._httpClientService.createSubscriptions(this.user.id, res).catch((err: any) => {
      let msg = err;

      while (msg.error) msg = msg.error;
      msg = msg.message;

      this._messageService.setMessage(Alert.DANGER, msg);
    });

    if (!rtn) return;

    if (rtn.status === 204) this._messageService.setMessage(Alert.SUCCESS, 'Subscriptions added successfully!');
    else {
      let errored = false;
      var mesg: string;

      if (Object.keys(rtn.body).length === Object.keys(res).length) {
        errored = true;
        mesg = 'Failed to subscribe devices:';
      } else mesg = 'Partial Completion, the following units failed to subscribe:';

      Object.entries(rtn.body).map(([k, v]) => {
        mesg += `\n${k}: ${v}`;
      });

      this._messageService.setMessage((errored ? Alert.DANGER : Alert.WARNING), mesg);
    }

    this.getSubscriptions();
    this.getDevices();
  }

  sort(prop: string) {
    this.devices = this.sorterService.sort(this.devices, prop);
  }

  exportExcel() {
    let dataSets: any = [];

    let columnSets: any = this.columns.map((c: any) => {
      return {
        value: c.display,
        fontWeight: 'bold',
        align: 'left'
      }
    });

    for (let dev of this.devices) {
      let tmp: any = [];

      for (let col of this.columns) {
        var data;
        if (col.display === 'Serial') data = (dev.sensor_serial || dev.device_serial);
        else data = dev[col.field];

        if (typeof (data) !== 'string') data = data.toString();

        tmp.push({
          value: data,
          type: 'String',
          align: 'right'
        });
      }

      dataSets.push(tmp);
    }

    writeExcelFile([
      columnSets,
      ...dataSets
    ], {
      fileName: `${this.email.value} Devices - Page ${(this.currentPage + 1)}.xlsx`,
      fontSize: 10,
      columns: [
        { width: 16 },
        { width: 19 },
        { width: 10 },
        { width: 22 },
        { width: 21 },
        { width: 21 }
      ]
    });
  }
}
