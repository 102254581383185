import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Alert } from '../components/message/message.alert.enum';
import { MessageService } from '../components/message/message.service';

export type TokenData = ({
  bus?: string,
  seg?: string,
  token?: string,
  ttl?: number,
  reset?: boolean,
  email: string,
  id: string,
  exp: number,
  iat: number,
  name: string,
  devs: string[],
  sens: string[],
  roles: string[],
} | null);

export type TokenObject = {
  token: string,
  ttl: number,
  data: TokenData
};

@Injectable()
export class AuthService {
  private refreshInd: NodeJS.Timeout;
  private lb4Url: string = environment.API_BASE_URL;

  appLoaded: boolean = false;

  get currentUser(): (TokenData | null) {
    return JSON.parse(localStorage.getItem('tokenData'));
  }
  set currentUser(tokenData: TokenData) {
    localStorage.setItem('tokenData', JSON.stringify(tokenData));
  }

  get token(): (string | null) {
    return localStorage.getItem('token');
  }
  set token(token: string) {
    localStorage.setItem('token', token);
  }

  get refreshOn(): (number | null) {
    return Number(localStorage.getItem('refreshOn'));
  }
  set refreshOn(refresh: number) {
    localStorage.setItem('refreshOn', refresh.toString());
  }

  get expiresOn(): (number | null) {
    return Number(localStorage.getItem('expiresOn'));
  }
  set expiresOn(expires: number) {
    localStorage.setItem('expiresOn', expires.toString());
  }

  get expired(): boolean {
    return (new Date().getTime() > this.expiresOn);
  }

  get isLoggedIn(): boolean {
    if (!this.token) return false;
    else return true;
  }

  get email(): (string | null) {
    return localStorage.getItem('email');
  }
  set email(_email: string) {
    localStorage.setItem('email', _email);
  }

  get roles(): string[] {
    return this.currentUser.roles;
  }

  get isMaster() {
    return this.roles?.includes('master');
  }

  constructor(
    private _httpClient: HttpClient,
    private _router: Router,
    private _messageService: MessageService
  ) { }

  signupUser(email: string, password: string) {
    // code for signing up the new user (if applicable)
  }

  getAllowed(): Observable<any[]> {
    let url = 'assets/data/allowed.json';
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError.bind(this)));
  }

  signin(user) {
    let body = { email: user.email, password: user.password }
    let url = `${this.lb4Url}/users/login`;
    return this._httpClient.post<any>(url, body, { withCredentials: true }).pipe(map(res => {
      this.setTokenData(res.token, res.ttl);
    }), catchError(this.handleError.bind(this)));
  }

  async refreshToken(isInit?: boolean) {
    let url = `${this.lb4Url}/users/refresh_token`;
    var res;

    try {
      res = await this._httpClient.post<any>(url, null, { withCredentials: true }).toPromise();
      if (res) this.setTokenData(res.token, res.ttl);
    } catch (ex: any) {
      if (!isInit) this.handleError.bind(this);
    }

    return res;
  }

  async logout(redirect?, tokenExpired?: boolean) {
    let url = `${this.lb4Url}/users/logout`;

    await this._httpClient.post(url, null, { withCredentials: true }).toPromise().catch(() => { });
    await this.onLogout(redirect, tokenExpired);
  }

  setRefreshTimeout() {
    if (this.refreshInd) return;

    let time = (this.refreshOn - new Date().getTime());
    this.refreshInd = setTimeout(() => {
      clearTimeout(this.refreshInd);
      this.refreshInd = undefined;

      if (!this.expired) this.refreshToken();
      else if (this.isLoggedIn) this.logout();
    }, time);
  }

  private parseJwt(token: string): TokenData {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  private setTokenData(token: (string | null), ttl: number) {
    if (!token) {
      this._router.navigate(['/login']);
      this._messageService.setMessage(Alert.DANGER, "Permission Denied");
      return;
    }

    const tokenObj: TokenObject = {
      data: this.parseJwt(token),
      token: token,
      ttl: ttl
    };

    this.token = token;
    this.currentUser = tokenObj.data;

    let now: number = new Date().getTime();
    //Set the refresh time to half the JWT ttl
    let ref = (now + (Math.floor(tokenObj.ttl / 2) * 1000));
    let exp = (now + (tokenObj.ttl * 1000));

    this.refreshOn = ref;
    this.expiresOn = exp;

    localStorage.setItem('user_id', tokenObj.data.id);
    this.setRefreshTimeout();
  }

  private async onLogout(redirect?, tokenExpired?: boolean) {
    if (this.refreshInd) {
      clearTimeout(this.refreshInd);
      this.refreshInd = undefined;
    }

    let _email = `${(this.email || '')}`;
    localStorage.clear();
    if (_email) this.email = _email;

    if (redirect) await this._router.navigate(['/login', { redirect, tokenExpired }]);
    else await this._router.navigate(['/login']);
  }

  private handleError(e: HttpErrorResponse) {
    //If the token record does not exist in the DB, logout
    if (e.error?.error?.code === 'ENTITY_NOT_FOUND' && this.isLoggedIn) this.logout();

    if (environment.DEBUG) { console.log('in device.service.handleError ...'); }
    if (e.error instanceof ErrorEvent) {
      console.log('Client side error: ', e.error.message);
    } else {
      console.log('Server side error: ', e.error.message);
    }
    return throwError('There was a problem with the request.');
  }
}
