import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SortByDirective } from "./directives/sortby.directive";
import { TableBasicComponent } from './components/table-basic.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableHeaderComponent } from './components/table-header.component';
import { TablePaginationComponent } from './components/table-pagination.component';
import { SearchComponent } from './components/search/search.component';
import { TableHeaderSortComponent } from './components/table-header-sort/table-header-sort.component';
import { MessageComponent } from './components/message/message.component';
import { DeviceUpdateListComponent } from './components/device-update-list/device-update-list.component';
import { MatSortModule } from '@angular/material/sort';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { TableListComponent } from './components/table-list.component';
import { TableLogsComponent } from './components/table-logs.component';
import { ModeledFormDirective } from 'app/shared/modeled-form/modeled-form.directive';

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        SortByDirective,
        TableBasicComponent,
        TableHeaderComponent,
        TablePaginationComponent,
        SearchComponent,
        NgbModule,
        TableHeaderSortComponent,
        MessageComponent,
        DeviceUpdateListComponent,
        TableListComponent,
        TableLogsComponent,
        ModeledFormDirective
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        FormsModule, 
        ReactiveFormsModule,
        MatSortModule,
        MDBBootstrapModule.forRoot()
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        SortByDirective,
        TableBasicComponent,
        TableHeaderComponent,
        SearchComponent,
        TablePaginationComponent,
        TableHeaderSortComponent,
        MessageComponent, 
        DeviceUpdateListComponent,
        TableListComponent,
        TableLogsComponent,
        ModeledFormDirective
    ]
})
export class SharedModule { }
