import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

@Injectable()
export class ConfirmationDialogService {

  private modalRef: any;
  
  constructor(private modalService: NgbModal) { }
  
  get option() {
    return this.modalRef.componentInstance.option
  }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    modalClass: string = 'danger',
    note: string = '',
    optionText: string = null,
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    this.modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    this.modalRef.componentInstance.title = title;
    this.modalRef.componentInstance.message = message;
    this.modalRef.componentInstance.note = note;
    this.modalRef.componentInstance.optionText = optionText;
    this.modalRef.componentInstance.btnOkText = btnOkText;
    this.modalRef.componentInstance.btnCancelText = btnCancelText;
    this.modalRef.componentInstance.modalClass = modalClass;
    return this.modalRef.result;
  }
}
