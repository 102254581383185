import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { ModeledForm } from './modeled-form';

@Directive({
  selector: '[modeled-form]'
})
export class ModeledFormDirective implements OnInit {
  @Input('modeled-form') form: ModeledForm;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    //Pair the dom element to the modeled-form element 
    if (this.form) this.form.pair(this.el.nativeElement);
  }
}