<h1>Sensor List</h1>
<div class="content-body">
  <div [hidden]="!showLoader" class="loaderCircle"></div>

  <app-table-header [title]="title" [deviceCount]="deviceCount" [currentPage]="currentPage" [pageCount]="pageCount"></app-table-header>

  <app-search (onSearch)="getData($event, true)" (onDisplayPage)="getData($event, true)" [pageCount]="pageCount" 
    [placeholder]="placeholder" [pageDensity]="pageDensity">
  </app-search>

  <app-table-pagination [disableNext]="disableNext" [disablePrevious]="disablePrevious" [pageDensity]="pageDensity"
    (onSetPageDensity)="onSetPageDensity($event)" (onDisplayNext)="getData($event, true)"
    (onDisplayPrevious)="getData($event, true)" [currentPage]="currentPage">
  </app-table-pagination>

  <app-message></app-message>
  <div class="horizontal-scroll">
    <app-table-header-sort [fields]="fields" [(data)]="sensors" [_sortData]="sortData">
      <tbody>
        <tr *ngFor="let s of sensors" [class.table-active]="s.user_id===pId">
          <td *ngIf="s.user_id" class="btn-link" (click)="onShowUserDetail(s.user_id)">{{ s.user_id }}</td>
          <td *ngIf="!s.user_id && s.business_id" class="btn-link" (click)="onShowBusinessDetail(s.business_id)">{{s.business?.name}} (Unassigned Enterprise Sensor)</td>
          <td class="btn-link" (click)="onShowDetails(s.id)">{{ s.sensor_serial }}</td>
          <td class="email-overflow">{{ s.email }}</td>
          <td>{{ s.devicename }}</td>
          <td>{{ s.lifetime_tc }}</td>
        </tr>
      </tbody>
    </app-table-header-sort>
  <div class="horizontal-scroll">
</div>
