import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HTTPStatus } from '../../shared/loader/interceptor';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent implements OnInit{
  ngOnInit(): void {
    this.httpStatus.getHttpStatus().subscribe((status: boolean) => { 
        setTimeout(() => {
          this.HTTPActivity = status; 
        });
      });        
  }
    options = {
        direction: 'ltr'
    };

    API_URL = environment.API_BASE_URL;

    // This adds loader functionality to the full layout views
    HTTPActivity: boolean;
    constructor(private httpStatus: HTTPStatus) { 
        this.httpStatus = httpStatus;
    }
}
