import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateBusinessService, BusinessDetail } from '../../services/create-business.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent {
  detailsEntries: [string, (string | number | boolean)][];
  ownerEntries: [string, (string | number | boolean)][];
  billingUserEntries: [string, (string | number | boolean)][];
  billingInfoEntries: [string, (string | number | boolean)][];
  customPricingEntries: [string, (string | number | boolean)][];

  constructor(
    private _activeModal: NgbActiveModal,
    public createBus: CreateBusinessService
  ) {
    this.detailsEntries = Object.entries(this.createBus.businessDetail);

    let billInfo: any = { ...this.createBus.businessBillingInfo };
    let owner = { ...this.createBus.businessOwner };
    let billUser = { ...this.createBus.businessBillingUser };
    let pricing = {};

    billInfo.paymentMethod = (billInfo.allowDirectDebit ? 'ACH' : 'Credit Card');
    billInfo.paymentTerms = (billInfo.autoCollection ? 'Monthly' : 'Net 30');
    if (!billInfo.netTermDays) delete billInfo.netTermDays;
    delete billInfo.allowDirectDebit;
    delete billInfo.autoCollection;

    if (owner.existingUser) {
      Object.keys(owner).map((e) => {
        if (!['existingUser', 'email', 'userId'].includes(e)) delete owner[e];
      });
    } else {
      delete owner.existingUser;
      delete owner.userId;
    }

    if (billUser.existingUser) {
      Object.keys(billUser).map((e) => {
        if (!['existingUser', 'email', 'userId'].includes(e)) delete billUser[e];
      });
    } else {
      delete billUser.existingUser;
      delete billUser.userId;
    }

    if (!billUser.fullAdminRights) delete billUser.fullAdminRights;

    this.billingInfoEntries = Object.entries(billInfo);
    this.ownerEntries = Object.entries(owner);
    this.billingUserEntries = Object.entries(billUser);

    let cp = Object.values(this.createBus.customPricing).find(e => (e !== '' && e !== '00.00' && e !== '0.00'));
    if (cp) {
      Object.entries(this.createBus.customPricing).map(([k, v]) => {
        pricing[k] = (v === '' ? '00.00' : v);
      });
    }

    this.customPricingEntries = Object.entries(pricing);
  }

  public decline() {
    this._activeModal.close(false);
  }

  public next() {
    this._activeModal.close(true);
  }

  public update(key: string) {
    this._activeModal.close(key);
  }
}
