<h1>Population Chart <small>({{title}} Firmware)</small></h1>

<div class="content-body">
  <p>Total Device Count: <b>{{deviceCount}}</b></p>
  <div *ngIf="true" class="row h-300 justify-content-center align-items-center">

    <!-- Keep: to center: justify-content-center align-items-center -->
    <ngx-charts-pie-chart class="text-center" scheme="vivid" [results]="pieChartSingle" gradient="true" (select)="onSelect($event)"
      labels="true" legend="true" arcWidth="" trimLabels="true" maxLabelLength="50" doughnut="" explodeSlices="" [view]="chartSize">
    </ngx-charts-pie-chart>

  </div>
  <!-- [labelFormatting]="SetPieChartLabelFormat" legend="true" -->
  <i>Note: Some chart segments may not be labeled to prevent overlap.</i>

  <app-message></app-message>
</div>