<div class="container">
  <div class="row justify-content-md-center">
    <div class="col-md-5 card bg-primary">

      <h1 class="my-3 text-center">Skyhawk Support Portal</h1>

      <app-message></app-message>

      <!-- novalidate prevents browser validation -->
      <form [formGroup]="loginForm" (ngSubmit)="onLogin()" novalidate>
        <div class="form-group">
          <input placeholder="Username/Email" email type="email" class="form-control" formControlName="email" [class.is-invalid]="email.invalid && email.touched" required />
          <div id="rem-email" [attr.disabled]="!rememberEmail && (email.invalid || !email.touched)" (click)="rememberClick()">
            <div id="chk-box">
              <div id="chk-inner" [attr.checked]="rememberEmail"></div>
            </div>
            <span>Remember Email</span>
          </div>
          <small class="text-danger" *ngIf="email.invalid && email.touched">Email is required and must be valid</small>
        </div>

        <div class="form-group">
          <div id="pass-holder">
            <input placeholder="Password" [type]="(showPassword ? 'text' : 'password')" class="form-control" formControlName="password" [class.is-invalid]="password.invalid && password.touched" required>
            <i class="fad fa-eye" [ngClass]="{ 'fa-eye-slash': showPassword }" (click)="showPassword = !showPassword"></i>
          </div>
          <small class="text-danger" *ngIf="password.invalid && password.touched"> Password is required.</small>
        </div>

        <div class="form-group">
          <button [class.not-allowed]="loginForm.invalid" [disabled]="loginForm.invalid" class="btn btn-secondary btn-block" type="submit">Login
          </button>
        </div>
      </form>
    </div>
  </div>
</div>