<app-message></app-message>

<div class="row">
  <div class="col">
    <div class="shadow">
      <div class="card-body m-1">
        <div>

          <div class="table-title">
            <p>User</p>
            <button id="userLink" class="btn btn-outline-secondary" type="button" routerLink="{{ userDetailLink }}">User
              Detail
            </button>
          </div>

        </div>
        <app-table-list [columns]="[]" [rows]="userRelated"></app-table-list>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="shadow">
      <div class="card-body m-1">

        <div class="table-title">
          <p>Firmware</p>
          <div class="btn-group">
            <button id="userLink" class="btn btn-outline-secondary" type="button" [routerLink]="[firmwareUpdateLink]"
              [queryParams]="{device_serial: device?.device_serial, firmware: device?.unit_currentfirm}">
              Update
            </button>
            <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="sr-only">Toggle Dropdown</span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div class="dropdown-item cursor-pointer" (click)="onResetAllowed()">
                Reset Allowed Firmware
              </div>
            </div>
          </div>
        </div>


        <app-table-list [columns]="[]" [rows]="firmwareRelated"></app-table-list>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="shadow">
      <div class="card-body m-1">

        <div class="table-title">
          <p>Device Tracking</p>

          <div class="btn-group">
            <button id="userLink" class="btn btn-outline-secondary" type="button"
              (click)="onDeactivate($event.target.value)" value="{{activationTxt}}">
              {{ activationTxt }}
            </button>
            <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="sr-only">Toggle Dropdown</span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div class="dropdown-item cursor-pointer">
                <span (click)="onDeactivateIn()">Deactivate In</span>
                <input type="number" class="input-style" style="margin-left: 7px;"
                  (keyup)="onChangeDeactivateIn($event.target.value)"
                  oninput="this.value=this.value.slice(0,this.maxLength)" maxlength="5" required />
              </div>
              <div class="dropdown-item cursor-pointer">
                <span (click)="onUpdateAutoCheckin()">Auto Checkin</span>
                <input type="text" class="input-style" mask="000" required
                  (keyup)="onChangeUnitCheckin($event.target.value)" />
              </div>
              <div class="dropdown-item cursor-pointer" title="Reset Lifetime Trigger Count" (click)="onResetTC()">
                Reset TC
              </div>
            </div>
          </div>
        </div>

        <app-table-list [columns]="[]" [rows]="trackingRelated"></app-table-list>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="shadow">
      <div class="card-body m-1">
        <p>Network</p>
        <app-table-list [columns]="[]" [rows]="networkRelated"></app-table-list>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="shadow">
      <div class="card-body m-1">
        <p>Subscriptions</p>
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Plan</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let subscription of subscriptions">
              <td> {{ subscription.subscription_id }} </td>
              <td> {{ subscription.plan_id}} </td>
              <td> {{ subscription.status }} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="shadow">
      <div class="card-body m-1">
        <p>
          VZW Reqs
          <span id="vzw-status" *ngIf="vzwStatus"> - Status: {{vzwStatus}}</span>
        </p>
        <div id="vzw-panel">
          <div id="vzw-reqs">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Created</th>
                  <th>Type</th>
                  <th>Response</th>
                  <th>Request ID</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let req of vzreqs">
                  <td> {{ req.created }} </td>
                  <td> {{ req.reqtype }} </td>
                  <td> {{ (req.vzwres || 'none') }} </td>
                  <td> {{ (req.responceid || 'N/a') }} </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div id="vzw-btns">
            <button class="btn btn-outline-primary" type="button" (click)="getActivationStatus()">Status</button>
            <button class="btn btn-outline-secondary" type="button" (click)="exportConnLogs()"
              [disabled]="!vzwStatus || vzwStatus === 'no record'">Export Logs</button>
            <button class="btn btn-outline-secondary" type="button" (click)="vzwActivate()"
              [disabled]="!vzwStatus || (vzwStatus !== 'deactive' && vzwStatus !== 'no record')">Activate</button>
            <button class="btn btn-outline-warning" type="button" (click)="vzwDeactivate()"
              [disabled]="!vzwStatus || vzwStatus !== 'active'">Deactivate</button>
            <button class="btn btn-outline-danger" type="button" (click)="vzwDelete()"
              [disabled]="!vzwStatus || vzwStatus !== 'deactive'">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="shadow">
      <div class="card-body m-1">
        <div class="table-title">
          <p>Logs</p>
          <div>
            <p class="text-warning font-small-3 note-position">Note: Signal and trigger columns may be unknown for old
              data. </p>
            <a class="btn btn-outline-secondary" href="{{ deviceLogSearch }}" target="_blank">Kibana Search</a>
          </div>
        </div>
        <div>
          <app-table-header [deviceCount]="logCount" [currentPage]="currentPage" [pageCount]="pageCount"
            [title]="'Log Count'"></app-table-header>

          <app-table-pagination [disableNext]="disableNext" [disablePrevious]="disablePrevious"
            [pageDensity]="pageDensity" (onSetPageDensity)="onSetPageDensity($event)"
            (onDisplayNext)="getLocalLogs($event, true)" (onDisplayPrevious)="getLocalLogs($event, true)"
            [currentPage]="currentPage">
          </app-table-pagination>
        </div>
        <app-table-logs id="logs" [rows]="logRelated" [sortData]="sortData" [currentPage]="currentPage"></app-table-logs>
      </div>
    </div>
  </div>
</div>