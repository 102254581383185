import { Component, OnInit, HostListener } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from '../auth/auth.service';

declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public menuItems: any[];

  constructor(public auth: AuthService) { }

  btnLogoutFixedBottom: boolean = false;

  ngOnInit() {
    $.getScript('./assets/js/app-sidebar.js');
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.btnLogoutPositionFixed();		
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.btnLogoutPositionFixed();
  }
  
  private btnLogoutPositionFixed() {
    if (window.innerHeight >= 800) {
      this.btnLogoutFixedBottom = true;
    } else {
      this.btnLogoutFixedBottom = false;
    }
  }

  onLogout(){
    this.auth.logout();
  }
}
 