<h1>Enterprise Details</h1>
<div class="content-wrapper">
  <div class="content-body">

    <form [formGroup]="updateForm" (ngSubmit)="onUpdate()">
      <div class="form-group">
        <label>Business Name</label>
        <input formControlName="busName" type="text" class="form-control" required>
        <div *ngIf="busName.invalid && (busName.dirty || busName.touched)" class="alert alert-danger">
          <div *ngIf="busName.errors.required">Business name is required. </div>
          <div *ngIf="busName.errors.minlength">Business name must be at least 2 characters long.</div>
        </div>
      </div>

      <div class="form-group">
        <label>Business Email</label>
        <input formControlName="busEmail" type="text" class="form-control">
        <div *ngIf="busEmail.invalid && (busEmail.dirty || busEmail.touched)" class="alert alert-danger">
          <div *ngIf="busEmail.errors.required">Business Email is required</div>
        </div>
      </div>

      <button id="btn-update" [disabled]="updateForm.invalid" class="btn btn-outline-warning" (click)="onUpdate()"
        type="button">Update Business
      </button>

      <button class="btn btn-outline-danger" style="margin-left: 20px" type="button"
        (click)="onDelete()" [disabled]="!auth.isMaster">Delete Business
      </button>
    </form>

    <app-message></app-message>

    <div class="row">
      <div class="col">
        <div class="shadow">
          <div class="card-body m-1">
            <div>

              <div class="table-title">
                <p>Business Owner</p>
                <button id="userLink" class="btn btn-outline-secondary table-btn-position" type="button"
                  routerLink="{{ busOwnerLink }}">User Detail
                </button>
              </div>

            </div>
            <app-table-list class="table-btn-position" [columns]="[]" [rows]="busOwner"></app-table-list>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="shadow">
          <div class="card-body m-1">

            <div class="table-title">
              <p>Business Details</p>
            </div>

            <app-table-list [columns]="[]" [rows]="businessDetails"></app-table-list>
          </div>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col">
        <div class="shadow">
          <div class="card-body m-1">
            <div>

              <div class="table-title">
                <p>Billing User</p>
                <button id="userLink" class="btn btn-outline-secondary table-btn-position" type="button"
                  routerLink="{{ busBillingLink }}">User Detail
                </button>
              </div>

            </div>
            <app-table-list class="table-btn-position" [columns]="[]" [rows]="billingUser"></app-table-list>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="shadow">
          <div class="card-body m-1">
            <p>Business Billing</p>
            <app-table-list [columns]="[]" [rows]="businessBilling"></app-table-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
