import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SearchService } from './search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styles: []
})
export class SearchComponent implements OnInit {
  
  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDisplayPrevious: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDisplayNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSetPageDensity: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDisplayPage: EventEmitter<any> = new EventEmitter<any>();
  
  @Input() disablePrevious: boolean;
  @Input() disableNext: boolean;
  @Input() pageDensityValues: [];
  @Input() pageDensity: any = 50;
  @Input() pageCount: number;
  @Input() currentPage: number = 0;
  @Input() placeholder: string;
  @Input() showGoToPage = true;

  goToPage: AbstractControl;
  searchTerm: AbstractControl;
  
  constructor(public _searchService: SearchService) { }
  
  ngOnInit() {
    this.goToPage = this._searchService.goToPage;
    this.searchTerm = this._searchService.searchTerm;
  }

  onGoToPage() {
    if (isNaN(this.goToPage.value)) {
      this.goToPage.setValue(1);
    }
    let pageNo = this.goToPage.value - 1; // convert to number and offset by one to match array
    if (this.goToPage.value <= 0) {
      pageNo = 0;
    }
    if (this.goToPage.value > this.pageCount) {
      pageNo = this.pageCount - 1;
    }
    this.goToPage.setValue(pageNo + 1);
    this.emitDisplayPage(pageNo);
  }

  emitDisplayPage(currentPage) { 
    this.onDisplayPage.emit({ cp: currentPage, skip: (currentPage * this.pageDensity), goTo: true });
  }

  emitOnSetPageDensity(value) {
    this.pageDensity = value;
    this.onSetPageDensity.emit(value);
  }

  emitOnSearch() {
    this.goToPage.setValue(1);
    this.onSearch.emit({ cp: 0, skip: 0 });
  }
}
