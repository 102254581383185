import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'qr-img',
  templateUrl: './qr-img.html',
  styleUrls: ['./qr-img.scss']
})
export class QrImg implements OnInit {
  @Input() serial: string;
  @Input() qr: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  public close() {
    this.activeModal.close();
  }
}