import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnterpriseRoutingModule, USER_ROUTING_COMPONENTS} from './enterprise-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { BusinessOwnerComponent } from './create-business-modal/sections/business-owner/business-owner.component';
import { BillingInformationComponent } from './create-business-modal/sections/billing-information/billing-information.component';
import { CustomPricingComponent } from './create-business-modal/sections/custom-pricing/custom-pricing.component';
import { BusinessDetailsComponent } from './create-business-modal/sections/business-details/business-details.component';
import { CreateBusinessService } from './create-business-modal/services/create-business.service';
import { ReviewComponent } from './create-business-modal/sections/review/review.component';

@NgModule({
  imports: [
    CommonModule,
    NgxMaskModule.forRoot(),  
    EnterpriseRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [
    USER_ROUTING_COMPONENTS, 
    BusinessDetailsComponent,
    BusinessOwnerComponent,
    BillingInformationComponent,
    CustomPricingComponent,
    ReviewComponent
  ],
  providers: [CreateBusinessService]
})
export class EnterpriseModule { }

