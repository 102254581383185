<div id="edit-con">
  <div id="title">Update IMEI & ICCID</div>
  <div id="inputs">
    <label>IMEI:</label>
    <input id="imei-input" type="text" [ngStyle]="{ 'border': (imeiValid ? '1px solid black' : '1px solid red')}" [value]="imei" (change)="validate()" (keyup)="validate()" (keydown)="keyDown($event)"/>
    <div id="inval-imei" [hidden]="imeiValid">Value must be '0' or have a length of 15 characters</div>
    <label class="l">ICCID:</label>
    <input id="iccid-input" type="text" [ngStyle]="{ 'border': (iccidValid ? '1px solid black' : '1px solid red')}" [value]="iccid" (change)="validate()" (keyup)="validate()" (keydown)="keyDown($event)"/>
    <div id="inval-iccid"[hidden]="iccidValid">Value must be '0' or have a length of 20 characters</div>
  </div>
  <p class="text-warning">{{ note }}</p>
  <div id="btns">
    <button id="sub-btn" type="button" (click)="submit()" [disabled]="(!updated || !imeiValid || !iccidValid)">Submit</button>
    <div style="width: 1px; background-color: rgb(38, 142, 184);"></div>
    <button type="button" (click)="close()">Cancel</button>
  </div>
</div>