import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() {
  }

  updateNoFirmware(arr) {
    let tempArray = [];
    arr.forEach(ele => {
      if (ele.name === '') {
        tempArray.push({ "name": "No Firmware", "value": ele.value });
      } else if (ele.name && ele.name !== "null") {
        tempArray.push(ele);
      } else {
        tempArray.push({ "name": "Null Firmware", "value": ele.value });
      }
    });
    return tempArray;
  }

  displayPopulationMessage($event) {
    if (typeof $event === 'object'){
      if ($event.value > 1)
        alert('There are ' + $event.value + ' devices currently running firmware: ' + $event.name);
      else
        alert('There is ' + $event.value + ' device currently running firmware: ' + $event.name);
    } else {
      alert(`Click on the pie slice '${$event}' to get the quantity of associated devices.`);
    }
  }

}
