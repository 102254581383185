import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './shared/auth/auth.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    loaded: boolean = false;

    constructor(private router: Router, private auth: AuthService) {
        window.onfocus = () => {
            setTimeout(() => {
                if (document.hasFocus()) {
                    if (this.auth.isLoggedIn && this.auth.expired) {
                        this.auth.logout(null, true);
                    }
                }
            }, 150);
        }

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                (<any>window).ga('set', 'page', event.urlAfterRedirects);
                (<any>window).ga('send', 'pageview');
            }
        });

        this.generateLoader();
    }

    async ngOnInit() {
        if (this.auth.isLoggedIn) {
            let rtn = await this.auth.refreshToken(true).catch(() => { console.log('ref error') });
            if (!rtn) await this.auth.logout();
        }

        this.loaded = true;
    }

    private generateLoader() {
        let loader = document.getElementById('loader-spinner');
        if (!loader) {
            let back = document.createElement('div');
            back.setAttribute('hidden', 'true');
            back.id = 'loader-spinner';

            let style = document.createElement('style');
            style.innerText = `
            #loader-spinner {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              z-index: 1000000;
              background-color: rgba(0, 0, 0, .3);
              display: flex;
              justify-content: center;
              align-items: center;
            }
    
            #loader-spinner div {
              padding: 15px;
              background-color: white;
              border-radius: 5px;
              box-shadow: 0px 2px 8px rgb(63, 63, 63);
            }
    
            #loader-spinner img {
              height: 60px;
              width: 60px;
              animation: loader-ani 2s infinite linear;
            }
    
            @keyframes loader-ani {
              from {
                  transform: rotate(0deg)
              }
    
              to {
                transform: rotate(360deg)
              }
            }
          `;

            let spinBack = document.createElement('div');

            let spinner = document.createElement('img');
            spinner.src = '/assets/img/loaderSpinner2.png';

            back.append(style, spinBack);
            spinBack.append(spinner);
            document.body.append(back);
        }

        (window as any).showLoader = () => {
            let loader = document.getElementById('loader-spinner');
            loader?.removeAttribute('hidden');
        }

        (window as any).hideLoader = () => {
            let loader = document.getElementById('loader-spinner');
            loader?.setAttribute('hidden', 'true');
        }
    }
} 