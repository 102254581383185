import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FotaRoutingModule } from './fota-routing.module';
import { DeviceUpdateComponent } from './device/device-update/device-update.component';
import { FirmwareListComponent } from './firmware/firmware-list/firmware-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FotaService } from './fota.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FirmwareUploadComponent } from './firmware/firmware-upload/firmware-upload.component';
import { FirmwarePopulationComponent } from './firmware/firmware-population/firmware-population.component';
import { DeviceListComponent } from './device/device-list/device-list.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FotaRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    SharedModule
  ],
  declarations: [
    DeviceUpdateComponent,
    FirmwareListComponent,
    FirmwareUploadComponent,
    FirmwarePopulationComponent,
    DeviceListComponent
  ],
  providers: [FotaService]
})
export class FotaModule { }
