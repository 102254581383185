<div *ngIf="devices.length > 0 && targetFirmArray">
  <br>
  <h4>{{ title }}</h4>
  <form [formGroup]="targetForm">

    <label>Target Firmware</label>
  
    <select class="form-control" (change)="emitTargetFirmValue($event.target.value)" formControlName="{{controlName() }}">
      <option value="">Please select</option>
      <option *ngFor="let t of targetFirmArray">{{ t.version }} </option>
    </select>

    <ul class="list-group my-1 overflow">
      <!-- <input type="hidden" [(ngModel)]="selection" /> -->
      <li *ngFor="let device of devices; let i = index" class="list-group-item ml-0 p-1, pl-2, pb-0">
        <label>
          <input type="checkbox" class="mr-1" formControlName="selection" (change)="onChangeSelectDevice($event, device)" />

          <b> {{ device.id }} : {{ device.device_serial }} </b>
          <small><i>(Current/Allowed
            <b>{{ device.unit_currentfirm }}/{{ device.unit_allowedfirm }}</b>)</i>
          </small>
        </label>
      </li>
    </ul>
  </form>
</div>