import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClientService } from 'app/http-client.service';
import { Alert } from 'app/shared/components/message/message.alert.enum';
import { MessageService } from 'app/shared/components/message/message.service';
import { ConfirmationDialogService } from 'app/shared/dialog/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-sensor-add',
  templateUrl: './sensor-add.component.html'
})
export class SensorAddComponent {

  public pId: number = null;
  public device;
  public dataLoaded = false;
  public dataToggle = false;
  isOpen = false;

  constructor(
    private _httpClientService: HttpClientService,
    private _confirmationDialogService: ConfirmationDialogService,
    private _fb: FormBuilder,
    private _messageService: MessageService
  ) { }

  // Form Setup
  updateForm = this._fb.group({
    sensorSerial: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
    serviceTag: [''],
    bondedTo: ['none'],
    status: ['new'],
  });
  // End Form Setup

  get sensorSerial() { return this.updateForm.get('sensorSerial') }

  onSubmit() {
    let serviceTag = this.updateForm.get('serviceTag').value;
    let bondedTo = this.updateForm.get('bondedTo').value;
    let status = this.updateForm.get('status').value;

    let title = `Please Confirm Creation`;
    let message = `Are you sure you want to add this device?`;

    this._confirmationDialogService.confirm(title, message, 'Add', 'Cancel', 'warning').then(async(confirmed) => {
      if (confirmed === true) {
        let upperCaseSensorSerial = this.sensorSerial.value.toUpperCase();
        let body = {
          sensor_serial: upperCaseSensorSerial,
          service_tag: serviceTag,
          bonded_to: bondedTo,
          status: status
        }
        await this._httpClientService.addSeviceSerialMaster(body);
        this._messageService.setMessage(Alert.SUCCESS, "Device added to deviceseralmaster table");
      }}).catch((error) => {
        this._messageService.setMessage(Alert.DANGER, 'An error was detected...');
        console.error(error);
      });
  }
}
