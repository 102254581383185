import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientService } from 'app/http-client.service';
import { CreateBusinessService } from '../../services/create-business.service';
import { ModeledControl, ModeledForm, ValidationTypes } from '../../../../shared/modeled-form/modeled-form';

@Component({
  selector: 'app-business-details',
  templateUrl: './business-details.component.html',
  styleUrls: ['./business-details.component.scss']
})
export class BusinessDetailsComponent {
  segments = [];

  constructor(
    private _activeModal: NgbActiveModal,
    private _httpClientService: HttpClientService,
    public createBus: CreateBusinessService
  ) {
    this.getSegments();
  }

  businessForm = new ModeledForm({
    businessName: new ModeledControl({
      objectRef: { holdingObject: this.createBus.businessDetail, propertyKey: 'name' },
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.minLength, data: 2 }
      ],
      displayErrors: true,
      debounceMs: 500
    }),
    businessType: new ModeledControl({
      objectRef: { holdingObject: this.createBus.businessDetail, propertyKey: 'businessType' },
      initialValue: (this.createBus.businessDetail.businessType || 'Choose...'),
      validators: [{ type: ValidationTypes.required }],
      displayErrors: true
    }),
    email: new ModeledControl({
      objectRef: { holdingObject: this.createBus.businessDetail, propertyKey: 'email' },
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.email },
        {
          type: ValidationTypes.custom, data: (async (value: string) => {
            let rtn: any = this._httpClientService.getBusinessByEmail(value);
            if (rtn?.length) return 'Entered email already in use..!'

            return null;
          }).bind(this)
        }
      ],
      displayErrors: true,
      debounceMs: 500
    }),
    address: new ModeledControl({
      objectRef: { holdingObject: this.createBus.businessDetail, propertyKey: 'address_1' },
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.minLength, data: 2 }
      ],
      displayErrors: true,
      debounceMs: 500
    }),
    address2: new ModeledControl({
      objectRef: { holdingObject: this.createBus.businessDetail, propertyKey: 'address_2' }
    }),
    city: new ModeledControl({
      objectRef: { holdingObject: this.createBus.businessDetail, propertyKey: 'city' },
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.minLength, data: 2 }
      ],
      displayErrors: true,
      debounceMs: 500
    }),
    phone: new ModeledControl({
      objectRef: { holdingObject: this.createBus.businessDetail, propertyKey: 'phone' },
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.minLength, data: 14 },
        { type: ValidationTypes.maxLength, data: 16 }
      ],
      displayErrors: true,
      debounceMs: 500
    }),
    state: new ModeledControl({
      objectRef: { holdingObject: this.createBus.businessDetail, propertyKey: 'state' },
      initialValue: (this.createBus.businessDetail.state || 'Choose...'),
      validators: [
        { type: ValidationTypes.required },
        {
          type: ValidationTypes.custom,
          data: this.checkSelectValue
        }
      ],
      displayErrors: true
    }),
    zip: new ModeledControl({
      objectRef: { holdingObject: this.createBus.businessDetail, propertyKey: 'zip' },
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.minLength, data: 5 },
        { type: ValidationTypes.maxLength, data: 9 }
      ],
      displayErrors: true,
      debounceMs: 500
    })
  });

  //Don't allow the form to validate if the selected option is: 'Chose...' or an empty string
  private checkSelectValue(value: string): (string | null) {
    let check = ['', 'Choose...']
    return (check.includes(value) ? 'Selected value invalid..!' : null);
  }

  //Get the segments from or DB and capitalize the first letter of it's name
  private async getSegments() {
    let res = await this._httpClientService.getSegments();
    if (!res) return;

    res.forEach(ele => {
      let name = ele.name;
      ele.name = name.charAt(0).toUpperCase() + name.slice(1);
    });

    this.segments = res;
  }

  public decline() {
    this._activeModal.close(false);
  }

  public next() {
    this.businessForm.submit();
    this._activeModal.close(true);
  }

  public evalForm() {
    this.businessForm.evaluate();
  }
}
