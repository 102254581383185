import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SensorListComponent } from './sensor-list/sensor-list.component';
import { SensorDetailsComponent } from './sensor-details/sensor-details.component';
import { TableListComponent } from 'app/shared/components/table-list.component';

const routes: Routes = [
  { path: 'list', component: SensorListComponent, data: { title: 'Sensor List' } },
  { path: 'details/:id', component: SensorDetailsComponent, data: { title: 'Sensor Details' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SensorRoutingModule { }

export const SENSOR_ROUTING_COMPONENTS = [
  SensorListComponent,
  SensorDetailsComponent
];

