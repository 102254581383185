import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

    {
        path: '/user/list', title: 'Users', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
        ]
    },
    // {
    //     path: '/business/list', title: 'Businesses', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false,
    //     submenu: [
    //     ],
    // },
    {
        path: '/enterprise-customer/list', title: 'Enterprise Customer', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
        ],
    },
    {
        path: '/device/list', title: 'Devices', icon: 'ft-cpu', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
        ]
    },
    {
        path: '/sensor/list', title: 'Sensors', icon: 'ft-cpu', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
        ],
    },
    {
        path: '#', title: 'FOTA', icon: 'ft-download-cloud', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            {
                path: '#', title: 'Verizon', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
                submenu: [
                    { path: '/fota/verizon/firmware/population', title: 'Population Chart', icon: 'ft-pie-chart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/fota/verizon/firmware/list', title: 'Firmware List', icon: 'ft-crosshair', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/fota/verizon/device/update', title: 'Device Update', icon: 'ft-smartphone', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/fota/verizon/device/list', title: 'Device List', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                ],
            },
            {
                path: '#', title: 'Skyhawk', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
                submenu: [
                    { path: '/fota/skyhawk/firmware/population', title: 'Population Chart', icon: 'ft-pie-chart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/fota/skyhawk/firmware/list', title: 'Firmware List', icon: 'ft-crosshair', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/fota/skyhawk/device/update', title: 'Device Update', icon: 'ft-smartphone', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/fota/skyhawk/device/list', title: 'Device List', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                ],
            },
        ]
    },
    {
        path: '#', title: 'Advanced', icon: 'ft-plus-square', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/advanced/add-device', title: 'Add Device', icon: 'ft-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/advanced/add-sensor', title: 'Add Sensor', icon: 'ft-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/advanced/vzwreqs', title: 'Verizon Requests', icon: 'ft-phone-call', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

        ],
    },   
    {
        path: '/qa-tools/show', title: 'QA Tools', icon: 'fal fa-tools', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/list', title: 'Tables', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    }
];
