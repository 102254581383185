import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'imei-iccid-edit',
  templateUrl: './imei-iccid-edit.html',
  styleUrls: ['./imei-iccid-edit.scss']
})
export class ImeiIccidEdit {
  @Input() imei: string;
  @Input() iccid: string;
  @Input() note: string;

  private num = '1234567890';
  private extAllw = [ 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab' ];
  public updated: boolean = false;
  public imeiValid: boolean = false;
  public iccidValid: boolean = false;
  private imeiElm;
  private iccidElm;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.imeiValid = (this.imei.length === 15 || this.imei === '0');
    this.iccidValid = (this.iccid.length === 20 || this.iccid === '0');
    this.imeiElm = document.getElementById('imei-input');
    this.iccidElm = document.getElementById('iccid-input');
  }

  public validate() {
    this.imeiValid = (this.imeiElm.value.length === 15 || this.imeiElm.value === '0');
    this.iccidValid = (this.iccidElm.value.length === 20 || this.iccidElm.value === '0');
    this.updated = (this.imeiElm.value !== this.imei || this.iccidElm.value != this.iccid);
  }

  public keyDown(event) {
    if (event.ctrlKey == false && this.num.indexOf(event.key) == -1 && this.extAllw.indexOf(event.key) == -1) {
      if (event.key == 'Enter') this.submit();
      return false;
    }
  }

  public submit() {
    let rtn: any = {};

    if (this.imeiElm && this.imeiElm.value !== this.imei) {
      rtn.imei = this.imeiElm.value;
    }

    if (this.iccidElm && this.iccidElm.value !== this.iccid) {
      rtn.iccid = this.iccidElm.value;
    }
    
    this.activeModal.close(rtn);
  }

  public close() {
    this.activeModal.close(false);
  }
}