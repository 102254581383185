<div *ngIf="modalClass == 'danger'" class="modal-header bg-danger" style="border: solid 3px #fff;">
  <h4 class="modal-title text-light">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="modalClass != 'danger'" class="modal-header bg-warning" style="border: solid 3px #fff;">
  <h4 class="modal-title text-light">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div *ngIf="modalClass == 'danger'" class="text-danger" [innerHTML]="message"></div>
  <div *ngIf="modalClass != 'danger'" class="text-warning" [innerHTML]="message"></div>
  <div *ngIf="optionText">
    <br>
    <label class="custom-label" for='option'>{{ optionText }}</label>
    <div class="form-check form-check-inline" (change)="setChecked()">
      <input class="form-check-input" type="checkbox" id="option">
    </div>
  </div>
  <div class="text-secondary mt-1">{{ note }}</div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="decline()">{{ btnCancelText }}</button>
  <div *ngIf="modalClass != 'danger'">
    <button type="button" class="btn btn-outline-warning" (click)="accept()">{{ btnOkText }}</button>
  </div>
  <div *ngIf="modalClass == 'danger'">
    <button type="button" class="btn btn-outline-danger" (click)="accept()">{{ btnOkText }}</button>
  </div>
</div>