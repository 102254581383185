<div class="modal-header bg-warning" style="border: solid 3px #fff;">
  <h4 class="modal-title text-light">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <label class="text-warning" for="message">{{ message }}</label>
  <textarea name="message" type="text" (keyup)="setNotes($event)" value="{{ notes }}"></textarea>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="decline()">{{ btnCancelText }}</button>
  <div>
    <button type="button" class="btn btn-outline-warning" (click)="accept()">{{ btnOkText }}</button>
  </div>
</div>