import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Device } from '../classes/device';
import { environment } from 'environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as QRCode from 'qrcode';

@Injectable({
  providedIn: 'root'
})
export class QaToolsService {
  private user_id = localStorage.getItem('user_id');
  private lb4Url = environment.API_BASE_URL;

  constructor(private _httpClient: HttpClient) { }

  private genRequest(endpoint: String, type, body: any, params: String[], handleError = true) {
    let url = `${this.lb4Url}${endpoint}`;

    if (params) {
      params.forEach((p, i) => {
        url += `${(i > 0 ? '&' : '?')}${p}`;
      });
    }

    if (body && handleError) {
      return this._httpClient[type](url, body).pipe(catchError(this.handleError)).toPromise();
    } else if (body && !handleError) {
      return this._httpClient[type](url, body).toPromise();
    }
    return this._httpClient[type](url).pipe(catchError(this.handleError)).toPromise();
  }

  async getUserDevices() {
    let units = [];

    let filter = `{"where": {"user_id": "${this.user_id}"}, "fields": ["device_serial","unit_imei","unit_iccid","id"]}`;

    let ud = await this.genRequest('/devices', 'get', null, [`filter=${filter}`]).catch(alert);

    if (ud != undefined) {
      for (let u of ud) {
        u.device_id = u.id;
        delete u.id;

        units.unshift(new Device(u));
      }
    }
    for (let u of units) {
      u.qr = await QRCode.toDataURL(u.device_serial, { rendererOpts: { quality: 1 } });
      u.type = (u.isVirtual ? "Virtual" : "Physical");
    }
    return units;
  }

  async getDevices() {
    let units = [];

    let filter = `{"fields": ["device_serial","unit_imei","unit_iccid","id"]}`;

    let ud = await this.genRequest('/devices', 'get', null, [`filter=${filter}`]).catch(alert);

    if (ud != undefined) {
      for (let u of ud) {
        u.device_id = u.id;
        delete u.id;

        units.push(new Device(u));
      }
    }
    for (let u of units) {
      u.qr = await QRCode.toDataURL(u.device_serial, { rendererOpts: { quality: 1 } });
    }
    return units;
  }

  async getHubSensors() {
    let units = [];

    let filter = `{"where": {"user_id": "${this.user_id}"}, "fields": ["sensor_serial","id"]}`;

    let ud = await this.genRequest('/hubsensors', 'get', null, [`filter=${filter}`]).catch(alert);

    if (ud != undefined) {
      for (let u of ud) {
        u.device_id = u.id;
        delete u.id;
        u.device_serial = u.sensor_serial;
        delete u.sensor_serial;
        u.unit_iccid = '';
        u.unit_imei = '';
        units.push(new Device(u));
      }
    }
    for (let u of units) {
      u.qr = await QRCode.toDataURL(u.device_serial, { rendererOpts: { quality: 1 } });
      u.type = (u.isVirtual ? "Virtual" : "Physical");
    }
    return units;
  }

  async addVirtualDevice(model) {
    let devKey = `${model}87`;

    let vr = await this.genRequest('/deviceserialmaster', 'get', null, [
      'filter[order]=device_serial DESC&filter[limit]=1',
      `filter[fields][device_serial]=true`,
      `filter[where][device_serial][like]=${devKey}00%`
    ]).catch(alert);

    let num = 0;
    if (vr.length != 0) {
      num = (Number(vr[0].device_serial.replace(devKey, '')) + 1);
    }

    let nSerial = (devKey + num.toString().padLeft((12 - devKey.length), '0'));
    return this.genRequest('/deviceserialmaster', 'post', {
      device_serial: nSerial,
      unit_imei: "0",
      unit_iccid: "0",
      status: 'ready',
      state: 'active',
      modified: new Date()
    }, null);
  }

  async addVirtualSensor(model) {
    let devKey = `${model}87`;

    let vr = await this.genRequest('/sensorserialmaster', 'get', null, [
      'filter[order]=sensor_serial DESC&filter[limit]=1',
      `filter[fields][sensor_serial]=true`,
      `filter[where][sensor_serial][like]=${devKey}00%`
    ]).catch(alert);

    let num = 0;
    if (vr.length != 0) {
      num = (Number(vr[0].sensor_serial.replace(devKey, '')) + 1);
    }
    let nSerial = (devKey + num.toString().padLeft((11 - devKey.length), '0'));
    return this.genRequest('/sensorserialmaster', 'post', {
      sensor_serial: nSerial,
      modified: new Date()
    }, null);
  }

  async getSensors(userDevices) {
    let units = [];

    let filter = { where: {sensor_serial: { like: '%87%'}}, fields: ['sensor_serial','id']};
    let sr = await this.genRequest('/sensorserialmaster', 'get', null, [
      `filter=${encodeURIComponent(JSON.stringify(filter))}`
    ]).catch(alert);

    for (let s of sr) {
      let r = userDevices.find(e => e.device_serial === s.sensor_serial);
      if (!r) {
        s.device_id = s.id;
        delete s.id;
        s.unit_imei = '',
          s.unit_iccid = '',
          s.device_serial = s.sensor_serial;
        delete s.sensor_serial;
        s.model = s.device_serial.substring(0, 3);
        units.push(new Device(s));
      }
    }

    for (let u of units) {
      u.qr = await QRCode.toDataURL(u.device_serial, { rendererOpts: { quality: 1 } });
    }

    return units;
  }

  async getVirtualDevices(devices) {
    let units = [];

    let filter = {
      where: {device_serial: { like: '%87%'}}, fields: ['device_serial','unit_imei','unit_iccid','id']
    };
    
    let vr = await this.genRequest('/deviceserialmaster', 'get', null, [
      `filter=${encodeURIComponent(JSON.stringify(filter))}`
    ]).catch(alert);

    for (let v of vr) {
      let r = devices.find(e => e.device_serial === v.device_serial);
      if (!r) {
        v.device_id = v.id;
        delete v.id;
        v.model = v.device_serial.substring(0, 3);
        if (v.unit_imei === "0" && v.unit_iccid === "0") {
          units.unshift(new Device(v));
        }
      }
    }

    for (let u of units) {
      u.qr = await QRCode.toDataURL(u.device_serial, { rendererOpts: { quality: 1 } });
    }

    return units;
  }

  deleteDevice(endpoint, param) {
    return this.genRequest(endpoint, 'delete', null, [param], false);
  }

  deleteVirtualDevice(endpoint) {
    return this.genRequest(`${endpoint}`, 'delete', null, []).catch(alert);
  }

  addDevice(endpoint, body) {
    return this.genRequest(`/users/${this.user_id}/${endpoint}`, 'post', body, null, false);
  }

  updateImeiIccid(imei, iccid, serial, dsm) {
    if (dsm.length > 0) {
      return this.genRequest(`/deviceserialmaster/${dsm[0].id}`, 'put', {
        device_serial: serial,
        unit_imei: (imei || dsm[0].unit_imei),
        unit_iccid: (iccid || dsm[0].unit_iccid),
        status: dsm[0].status,
        state: dsm[0].state,
        modified_on: dsm[0].modified_on
      }, null, false);
    }
  }

  getDeviceSerialMaster(serial) {
    return this.genRequest('/deviceserialmaster', 'get', null, [`filter[where][device_serial]=${serial}`]).catch(alert);
  }
  

  private handleError(e: HttpErrorResponse) {
    if (e.error instanceof ErrorEvent) {
      console.log('Client side error: ', e.error);
    } else {
      console.log('Server side error: ', e.error);
    }
    return throwError('There was a problem with the request.');
  }
}
