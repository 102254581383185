import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { QaToolsRoutingModule, QATOOLS_ROUTING_COMPONENTS } from './qa-tools-routing.module';
import { QaToolsService } from './services/qa-tools.service';
import { FormsModule } from '@angular/forms';
import { QrImg } from './modals/qr-img/qr-img';
import { ImeiIccidEdit } from './modals/imei-iccid-edit/imei-iccid-edit';
import { SharedModule } from 'app/shared/shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  imports: [
    NgbModalModule,
    CommonModule,
    QaToolsRoutingModule,
    FormsModule,
    SharedModule,
    BsDropdownModule.forRoot()
  ],
  declarations: [
    QATOOLS_ROUTING_COMPONENTS, 
    QrImg,
    ImeiIccidEdit
  ],
  providers: [QaToolsService],
})
export class QaToolsModule { }
