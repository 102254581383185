<div class="apex-content content container-fluid">
  <h1 *ngIf="!submitted">Manage Devices <small>({{title}} Firmware)</small></h1>

  <div *ngIf="submitted" class="content-wrapper">
    <div class="content-body">
      <div class="card p-2">
        <div class="card-header">
          <h5 class="card-title text-muted">Request Submitted </h5>
        </div>
        <div class="card-body" aria-expanded="true">
          <div class="card-block">

            <p class="card-text">A request has been submitted to upgrade the {{title}} firmware
              to <b>{{ selectedTargetFirmware }}</b> for the following {{ selectedDevices.length }}
              device(s). It will take some time for the units to be updated.
            </p>

            <button class="btn btn-outline-secondary mt-0 px-3" (click)="reload()">
              OK
            </button>

            <h3 class="text-muted text-center">Device List</h3>
            <ul>
              <li *ngFor="let d of selectedDevices" class="list-group-item">{{d.serial}}</li>
            </ul>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
  </div>

  <div *ngIf="!submitted" class="content-wrapper">
    <div class="content-body">
      <h2>Device Management <small>({{title}} Firmware)</small></h2>

      <form [formGroup]='manageForm' (ngSubmit)='onSubmit()'>
        <div class="form-group">
          <label>Deployed Firmware</label>

          <select class="form-control" formControlName="currentFirmware"
          (change)="onChangeCurrentFirmware()" required>
            <option value="">Please select devices by firmware</option>
            <option *ngFor="let c of currentFirmArray">{{ c.vfota_version }} </option>
            <option value="all">All devices running any firmware</option>
          </select>

          <small class="text-danger" *ngIf="currentFirmware.invalid && currentFirmware.touched">
            Please choose a firmware version.
          </small>

          <p class="warning">{{ note }}</p>
        </div>

        <div class="form-group">
          <label>Target Firmware</label>

          <select class="form-control" formControlName="targetFirmware" required>
            <option value="">Please select</option>
            <option *ngFor="let t of targetFirmArray">{{ t.vfota_pn }} </option>
          </select>

          <small class="text-danger" *ngIf="targetFirmware.invalid && targetFirmware.touched">
            Please choose a firmware version.
          </small>
        </div>

        <button type="submit" [class.not-allowed]="manageForm.invalid" [disabled]="manageForm.invalid"
          class="btn btn-warning btn-outline-warning my-1">Update Device Firmware
        </button>

        <app-message></app-message>

        <div *ngIf="devices && currentFirmware.dirty" class="form-group my-2">
          <ul class="list-group my-1">
            <li *ngFor="let device of devices" class="list-group-item ml-0 p-1, pl-2, pb-0">
              <label>
                <input type="checkbox" class="mr-1" formControlName="selection" 
                (change)="onChangeSelectDevice(device)" /> <b> {{device.id}} : {{device.device_serial}} </b>
                <small><i>(Current Firmware: {{device.vfota_version}} )</i></small>
              </label>
            </li>
          </ul>
        </div>

      </form>
    </div>
  </div>
</div>