import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl } from '@angular/forms';
import { SearchService } from 'app/shared/components/search/search.service';
import { HttpClientService } from 'app/http-client.service';
import { MessageService } from 'app/shared/components/message/message.service';
import { Alert } from '../../shared/components/message/message.alert.enum';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss']
})

export class DeviceListComponent implements OnInit {

  public devices: any[];
  public deviceCount: number = 0;
  public pageCount: number = null;
  public pageDensity;
  public currentPage: number;
  public disableNext: boolean;
  public disablePrevious: boolean;
  public showLoader: boolean;
  public pId: string;
  public pSearchTerm: string;
  public pageDensityValues: number[];
  public sortData = false;
  public title = "Total Device Count"
  placeholder = "Search by email/serial/user ID/device name";

  private searchTerm: AbstractControl;
  private goToPage: AbstractControl;
  
  fields = [
    { field: 'user_id', display: 'User ID' },
    { field: 'device_serial', display: 'Serial Number' },
    { field: 'email', display: 'Email' },
    { field: 'devicename', display: 'Device Name' },
    { field: 'unit_currentfirm', display: 'Current Firm' },
    { field: 'lifetime_tc', display: 'Lifetime Trigger Count' },
  ]

  @Input() row: any;

  constructor(
    private _router: Router,
    private _searchService: SearchService,
    private _httpClientService: HttpClientService,
    private _messageService: MessageService
  ) { }

  ngOnInit() {
    this.initVariables();

    this.searchTerm.setValue('');
    this.goToPage.setValue(1);

    // Parse optional parameters
    let params = JSON.parse(sessionStorage.getItem("devParams"));

    if (params) {
      this.pId = params.id;
      if (params.st && params.cp && params.pd) {
        this.pageDensity = +params.pd;
        this.currentPage = +params.cp;
        this.pSearchTerm = params.st;
        return this.getDataWithParams();
      }
    }
    this.getData();
  }

  private getDataWithParams() {
    this.goToPage.setValue(this.currentPage + 1);
    this.searchTerm.setValue(this.pSearchTerm);

    this.getData({ cp: this.currentPage, skip: (this.currentPage * this.pageDensity) });
  }

  // Get the device count and data
  public getData(params = { cp: this.currentPage, skip: 0 }, sortData = false) {

    this.sortData = sortData;
    this.currentPage = params.cp;
    var skip = params.skip;

    if (skip <= 0) {
      skip = 0;
    }
    this.showLoader = true;

    this.getDeviceData(skip);
  }

  private getDeviceData(skip: number) {
    this._httpClientService.getDeviceCount(this.searchTerm.value).then((res) => {
      this.deviceCount = res.count;
      this.pageCount = Math.ceil(this.deviceCount / this.pageDensity);

      if (this.deviceCount === 0) {
        this.currentPage = -1;
        this.goToPage.setValue(0);
      }

      this.getDevices(skip);

      let data = this._searchService.configDisabledFlags(this.currentPage, this.disableNext, this.disablePrevious, this.pageCount);
      this.disableNext = data[0];
      this.disablePrevious = data[1];
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getDevices(skip: number) {
    this._httpClientService.getDevices(this.pageDensity, skip, this.searchTerm.value).then(res => {
      this.devices = res.slice();

      this.showLoader = false;
      if (this.searchTerm.value) {
        this.getServiceTag(); 
      } else {
        if (this.fields[6]) this.fields.pop();
      }
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getServiceTag() {
    this._httpClientService.getServiceTag(this.searchTerm.value).subscribe((res) => {
      this._httpClientService.getDevices(null, 0, null).then(dev => {
        // service tag table header
        if (res.length > 0) {
          this.fields[6] = { field: 'service_tag', display: 'Service Tag' };
        } else {
          if (this.fields[6]) this.fields.pop();
        }
        res.forEach((ele) => {
          // finds service tag devices, append service tags and add to devices array
          let serviceTag = dev.filter(d => d.device_serial.includes(ele.device_serial))[0];
          if (serviceTag) serviceTag.service_tag = ele.service_tag;
          else return;

          this.devices.push(serviceTag);
        }); 
        
        // filter duplicates
        this.devices = [...new Map(this.devices.map(item => [item['device_serial'], item])).values()];

        // after filtering duplicates, remove and unshift items to the top
        res.forEach(ele => {
          let i = this.devices.findIndex(d => d.service_tag === ele.service_tag);
          if (i >= 0) {
            this.devices.unshift(this.devices.splice(i, 1)[0]);
          }
        })
      })
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  onShowEdit(id: string) {
    let navParams: any = { cp: this.currentPage, pd: this.pageDensity, st: this.searchTerm.value };
    this._router.navigate(['./device/edit/', id, navParams]);
  }

  onShowUserDetail(id: string) {
    this._router.navigate(['./user/detail/', id]);
  }
  onShowBusinessDetail(id: string) {
    this._router.navigate(['./enterprise-customer/detail/', id]);
  }
  onSetPageDensity(val: number) {
    this.pageDensity = val;
    this.goToPage.setValue(1);
    this.getData({ cp: 0, skip: 0 }, true);
  }

  private initVariables() {
    this.searchTerm = this._searchService.searchTerm;
    this.goToPage = this._searchService.goToPage;
    this.deviceCount = null;
    this.pageCount = null;
    this.devices = [];
    this.pageDensity = 50;
    this.currentPage = 0;
    this.disableNext = false;
    this.disablePrevious = true;
    this.pageDensityValues = [50, 100, 200];
    this.showLoader = true;
  }
}
