import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DeviceUpdateListService } from 'app/shared/components/device-update-list/device-update-list.service';

@Component({
  selector: 'app-device-update-list',
  templateUrl: './device-update-list.component.html',
  styleUrls: ['./device-update-list.component.scss']
})
export class DeviceUpdateListComponent implements OnChanges {

  @Output() targetFirmware: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeSelect: EventEmitter<any> = new EventEmitter<any>();

  @Input() targetFirmArray: any[];
  @Input() title: string;
  @Input() devices: any[];

  model: string;
  targetForm = this._deviceUpdateService.targetForm;
  currentFirmware = this._deviceUpdateService.currentFirmware;
  selection: AbstractControl = this._deviceUpdateService.selection;
  shbTargetFirm: AbstractControl = this._deviceUpdateService.shbTargetFirm;
  shpTargetFirm: AbstractControl = this._deviceUpdateService.shpTargetFirm;
  shhTargetFirm: AbstractControl = this._deviceUpdateService.shhTargetFirm;
  
  constructor(
    private _deviceUpdateService: DeviceUpdateListService
  ) { }
  
  ngOnChanges() {
    this.model = this.title.substring(0, 3);

    if (this.targetFirmArray) {
      this.targetFirmArray = this._deviceUpdateService.targetFirmArrayRef.concat();
      this.targetFirmArray = this.targetFirmArray.filter((target) => target.model === this.model);
    }

    if (this.currentFirmware.valueChanges) {
      let i = this.targetFirmArray.findIndex(target => target.version === this.currentFirmware.value);
      if (i !== -1) {
        this.targetFirmArray.splice(i, 1);
      }
    }
  }

  controlName() {
    switch (this.model) {
      case 'SHB': return 'shbTargetFirm';
      case 'SHP': return 'shpTargetFirm';
      case 'SHH': return 'shhTargetFirm';
    }
  }

  onChangeSelectDevice(selected, device) {
    this.onChangeSelect.emit({ device: device, model: this.model });
  }

  emitTargetFirmValue(value) {
    this.targetFirmware.emit({ model: this.model, value: value });
  }
}
