<div class="content-body" id="content">
  <div id="tables-filter">
    <div id="page-contols">
      <span>Total Records Count: {{totalRecords}}</span>
      <div id="page-sel">
        <div id="page-back-btns" [attr.disabled]="currentPage <= 1">
          <i class="fas fa-arrow-to-left" (click)="pageBack(true)"></i>
          <i class="fas fa-arrow-alt-left" (click)="pageBack()"></i>
        </div>
        <span>page</span>
        <input [(ngModel)]="currentPage" (keydown)="pageKeyDown($event)" #currentPageFilter>
        <span>of {{pages}}</span>
        <div id="page-forward-btns" [attr.disabled]="currentPage >= pages">
          <i class="fas fa-arrow-alt-right" (click)="pageForward()"></i>
          <i class="fas fa-arrow-to-right" (click)="pageForward(true)"></i>
        </div>
      </div>
    </div>
    <div id="search">
      <div id="filter-by" class="btn-group">
        <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Filter By: {{filterBy}}&nbsp;
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div *ngFor="let col of columns" [hidden]="col === filterBy" class="dropdown-item cursor-pointer" (click)="filterEvent('filter-by', col)">
            {{col}}
          </div>
        </div>
      </div>
      <input type="text" (keydown)="filterKeyDown($event)" (paste)="filterOnPaste()" [(ngModel)]="filter" [placeholder]="'Enter ' + filterBy.toUpperCase() + ' here to filter records'" class="form-control" #filterInput>
      <div id="order-by" class="btn-group">
        <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Order By: {{orderBy}}&nbsp;
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div *ngFor="let col of columns" [hidden]="col === orderBy" class="dropdown-item cursor-pointer" (click)="filterEvent('order-by', col)">
            {{col}}
          </div>
        </div>
      </div>
      <div id="order-dir" class="btn-group">
        <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{orderDir}}&nbsp;
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div *ngFor="let opt of orderOpts" [hidden]="opt === orderDir" class="dropdown-item cursor-pointer" (click)="filterEvent('order-dir', opt)">
            {{opt}}
          </div>
        </div>
      </div>
    </div>
    <div id="page-density">
      <span>Page Density (Rows/Page)</span>
      <div id="density-radios">
        <input type="radio" [checked]="50 === pageDensity" name="_radioButtons_" (click)="filterEvent('density', 50)">
        <span (click)="filterEvent('density', 50)">50</span>
        <input type="radio" [checked]="100 === pageDensity" name="_radioButtons_" (click)="filterEvent('density', 100)">
        <span (click)="filterEvent('density', 100)">100</span>
        <input type="radio" [checked]="200 === pageDensity" name="_radioButtons_" (click)="filterEvent('density', 200)">
        <span (click)="filterEvent('density', 200)">200</span>
      </div>
    </div>
  </div>
  <app-message></app-message>
  <div id="tables-tabs">
    <button *ngFor="let table of tables" [attr.selected]="currentTable === table" (click)="setTable(table)">{{camelToPascal(table)}}</button>
  </div>
  <div id="table-rows">
    <div>
      <table>
        <thead>
          <tr>
            <th *ngFor="let col of columns">{{col}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of rows; let rowInd = index">
            <td *ngFor="let data of row">
              <div>
                <span *ngIf="!data[2]">{{data[1]}}</span>
                <a *ngIf="data[2]" [href]="data[2]">{{data[1]}}</a>
                <button *ngIf="isMaster && (data[0] === 'unit_imei' || data[0] === 'unit_iccid')" (click)="updateField(data[0], rowInd, $event.target, $event.target.previousElementSibling)" #edit>Edit</button>
                <button *ngIf="isMaster" data-type="cancel" hidden>Cancel</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>