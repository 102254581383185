<div *ngIf="dataLoaded" class="apex-content content container-fluid">
  <h1>Device Details : {{ device.device_serial }}</h1>
  <div class="content-wrapper">
    <div class="content-body">

        <form [formGroup]="updateForm" (ngSubmit)="onUpdate()">

          <div class="form-group">
            <label>Device Name</label>
            <input formControlName="deviceName" type="text" class="form-control" required>
            <div *ngIf="deviceName.invalid && (deviceName.dirty || deviceName.touched)" class="alert alert-danger">
              <div *ngIf="deviceName.errors.required"> Device Name is required. </div>
              <div *ngIf="deviceName.errors.minlength"> Device Name must be at least 2 characters long.</div>
            </div>
          </div>

          <div class="form-group">
            <label>Device Description</label>
            <input formControlName="deviceDescription" type="text" class="form-control">
            <div *ngIf="deviceDescription.invalid && (deviceDescription.dirty || deviceDescription.touched)"
              class="alert alert-danger">
              <div *ngIf="deviceDescription.errors.required"> Device Description is required. </div>
            </div>
          </div>

          <button id="btn-swap" class="btn btn-outline-warning" (click)="onSwap()" type="button">Swap Device
          </button>

          <button id="btn-update" [disabled]="updateForm.invalid" class="btn btn-outline-warning"
            (click)="onUpdate()" type="button">Update Device
          </button>

          <button [disabled]="updateForm.touched" class="btn btn-danger btn-outline-danger" style="margin-left: 20px"
            type="button" (click)="onDelete()" [disabled]="!auth.isMaster">Delete Device
          </button>
        </form>

      <div id="detail">
        <app-device-detail class="container" [deviceId]="device.id"></app-device-detail>
      </div>

    </div>
  </div>
</div>
