<!-- Table1 Layout Section (Simple Table) -->
<table *ngIf="rows" class="table table-bordered table-striped">
  
  <thead>
    <tr>
      <th *ngFor="let c of columns" class="">{{ c }}</th>
      <th *ngIf="showEditButton"></th>
    </tr>
  </thead>
  
  <tbody>
    <tr *ngFor="let row of rows" [class.table-active]="row[0] == highlightRow">
      <td *ngFor="let val of row">{{ val }}</td>
      <td *ngIf="showEditButton">
        <a *ngIf="showEditButton" (click)="emitOnShowEdit(row)" class="text-warning text-muted text-small mr-2"><span
            class="feather ft-edit"></span><small><i>{{ editButtonName }}</i></small></a>
      </td>
    </tr>
  </tbody>
</table>

<!-- Table2 Layout Section (Contains Status Badge) -->
<table *ngIf="tableData" class="table table-bordered table-striped">
  
  <thead>
    <tr>
      <th *ngFor="let c of columns" class="">{{ c }}</th>
      <th *ngIf="showEditButton"></th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let row of tableData" [class.table-active]="row[0] == highlightRow">
      <td *ngFor="let val of row">{{ val }}</td>
      <td *ngIf="showEditButton">
        <a *ngIf="showEditButton" (click)="emitOnShowEdit(val)" class="text-warning text-muted text-small mr-2">
          <span class="feather ft-edit"></span>
          <small><i>{{ editButtonName }}</i></small>
        </a>
      </td>
    </tr>
  </tbody>
</table>