<div class="apex-content content container-fluid">
  <div class="content-wrapper">
    <div class="content-body">
      <h2>Add Verizon Firmware Package</h2>
      <app-message></app-message>

      <form [formGroup]="uploadForm" (ngSubmit)="onFileUpload()">
        <div *ngIf="!submitted">
          <label>Firmware file select</label>

          <div class="form-group">
            <input class="btn btn-outline-secondary" type="file" accept=".bin" (change)="onFileSelected($event)" formControlName="file" required />

            <div class="form-group">
              <label>Firmware Version</label>
              <input id="firmware_version" type="text" placeholder="Enter the version reported by the GVF command" class="form-control" formControlName="version" required/>
            </div>

            <div class="form-group">
              <label>Firmware Type</label>

              <select id="firmware_type" class="form-control" formControlName="type" required>
                <option value="">Please select</option>
                <option *ngFor="let i of firmwareTypes">{{ i.name }} </option>
              </select>
            </div>

            <div class="form-group">
              <label>Firmware Req</label>

              <select id="firmware_req" class="form-control" formControlName="req" required>
                <option value="">Please select</option>
                <option *ngFor="let i of firmwareReqs">{{ i.name }}</option>
              </select>

            </div>
          </div>
          <button [disabled]="uploadForm.invalid" id="buttonUpload" [class.not-allowed]="uploadForm.invalid" class="btn btn-outline-secondary">
            Add Firmware
          </button>
        </div>

        <a *ngIf="submitted" class="btn btn-outline-secondary" (click)="onAddAnother()">OK</a>
      </form>

      <a *ngIf="!submitted" id="buttonList" class="btn btn-outline-secondary" routerLink="/fota/verizon/firmware/list">
        Firmware List
      </a>
    </div>
  </div>
</div>