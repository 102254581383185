<h1>Device Listing <small>({{title}} Firmware)</small></h1>
<div class="content-wrapper">
  <div class="content-body">
    <div class="apex-content content container-fluid">

      <app-table-header [deviceCount]="deviceCount" [currentPage]="currentPage" [pageCount]="pageCount">
      </app-table-header>

      <app-table-pagination [disableNext]="disableNext" [disablePrevious]="disablePrevious" [pageDensity]="pageDensity"
        (onSetPageDensity)="onSetPageDensity($event)" (onDisplayNext)="getData($event)"
        (onDisplayPrevious)="getData($event)" [currentPage]="currentPage">
      </app-table-pagination>

      <app-message></app-message>

      <div *ngIf="rows">
        <app-table-basic [showControlPanel]="true" [showDetailButton]="true" (onShowDetail)="onShowDetail($event)"
          [highlightRow]="pId" [columns]="columns" [tableData]="tableData">
        </app-table-basic>
      </div>
    </div>
  </div>
</div>