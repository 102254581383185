<div class="modal-header bg-primary" style="border: solid 3px #fff;">
  <h4 class="modal-title text-light">{{ createBus.title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="decline()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="form-check form-check-inline cus-price" (click)="toggleCustomPricing()">
    <span>Custom Pricing</span>
    <input class="form-check-input" type="checkbox"  [checked]="customPricing">
  </div>

  <app-message></app-message>

  <form *ngIf="customPricing">
    <div class="content-body">
      <div class="row">
        <div class="col">
          <label style="margin-left: 6px;">Hub</label>
          <label for="hub">$</label>
          <input class="input-size" type="text" id="hub" mask="00.00" [showMaskTyped]="true" placeHolderCharacter="0" [modeled-form]="customPricingForm">
          <div class="label-position">Per Month</div>
        </div>
        <div class="col">
          <label>Sensors</label>
          <label for="sensors">$</label>
          <input class="input-size" type="text" id="sensors" mask="00.00" [showMaskTyped]="true" placeHolderCharacter="0" [modeled-form]="customPricingForm">
          <div class="label-position">Per Month</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>Kiwi</label>
          <label for="kiwi">$</label>
          <input class="input-size" type="text" id="kiwi" mask="00.00" [showMaskTyped]="true" placeHolderCharacter="0" [modeled-form]="customPricingForm">
          <div class="label-position">Per Month</div>
        </div>
        <div class="col">
          <label>Kiwiir</label>
          <label for="kiwiir" style="margin-left: 26px">$</label>
          <input class="input-size" type="text" id="kiwiir" mask="00.00" [showMaskTyped]="true" placeHolderCharacter="0" [modeled-form]="customPricingForm">
          <div class="label-position">Per Month</div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-warning" [disabled]="!formValid" (click)="next()">{{createBus.nextText}}</button>
  <button id='cancel' type="button" class="btn btn-outline-warning" (click)="decline()">Cancel</button>
</div>