import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { readGrfPayload } from 'app/services/rf-parse.service';
import { writeExcelFile } from 'app/classes/excel/excel-writer';

@Component({
  selector: 'app-table-logs',
  template: `
  <div style="overflow-x: scroll;">
    <app-table-header-sort [fields]="columns" [(data)]="rows" [_sortData]="sortData">
      <tbody>
        <tr *ngFor="let r of rows; let rowInd = index" style="font-family: monospace;">
          <td style="white-space: nowrap;">{{ ((!r.sensor_rtc && columns[0].field === 'sensor_rtc') ? 'N/a' : ((r.sensor_rtc || r.created) | date:'short')) }}</td>
          <td style="white-space: nowrap;">{{ r.diff || 'N/a' }}</td>
          <td style="color: #1e7ee6; cursor: pointer;" (click)="onKibanaSearch(r.kibanaSearchUrl)">{{ r.triggers }}</td>
          <td *ngIf="!r.hub_serial" [ngStyle]="getColor(r.signal)">{{ (r.signal > 0 ? ('-' + r.signal) : r.signal) }}</td>
          <td *ngIf="r.hub_serial" [ngStyle]="getColor(r.sensor_rssi)">{{ r.sensor_rssi }}</td>
          <td *ngIf="r.hub_serial" [ngStyle]="getColor(r.hub_rssi)">{{ r.hub_rssi }}</td>
          <td *ngIf="r.hub_serial">{{ r.rssi_diff }}</td>
          <td [ngStyle]="getVoltageColor(r.volts)"> {{ (r.volts) | number: '.2' }}</td>
          <td class="btn-link" *ngIf="r.hub_serial" style="cursor: pointer; text-decoration: underline;" (click)="onShowHubDetails(r.hub_serial)">{{ r.hub_serial }}</td>
          <td *ngIf="r.hub_serial && r.sensor_comm_id !== undefined && r.sensor_comm_id !== null">{{ r.sensor_comm_id }}</td>
          <td>
            <div *ngIf="r.command_format && r.command_format !== 'n/a' && comm !== 'sensor'">
              <span *ngFor="let item of r.gds" container="body" placement="top" ngbPopover="{{ item.popover }}">{{ item.segment + (item.last ? '' : ':') }}</span>
            </div>
            <div *ngIf="(r.command_format === 'n/a' || comm === 'sensor') && r.grf?.segments" class="grf-main">
              <div *ngFor="let item of r.grf.segments" class="grf-segments">
                <ng-template #GrfTemplate>
                  <div class="grf-div">
                    <span>{{ item.popover.title }}</span>
                    <li *ngFor="let dt of item.popover.data" [innerHTML]="dt"></li>
                  </div>
                </ng-template>
                <span container="body" [ngbPopover]="GrfTemplate" class="grf-span">{{ item.segment }}</span>
              </div>
            </div>
          </td>
          <td *ngIf="columns[0].field === 'sensor_rtc'" style="white-space: nowrap;">{{ r.created | date:'short' }}</td>
        </tr>
      </tbody>
    </app-table-header-sort>
  </div>
  <button class="btn btn-outline-warning" style="margin-top: 15px" (click)="exportExcel()" [disabled]="!rows?.length">Export to Excel</button>`, // NOTE: Functions in the template is very inefficent and bad practice (line 13), need a better solution.
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgbPopoverConfig],
  styles: [`
    table td { word-break: break-word; }
    table td { min-width: 25px; }
    .grf-main { display: flex; flex-direction: row; }
    .grf-main:hover { color: blue; }
    .grf-segments:not(:first-child)::before { content: ":"; }
    .grf-span:hover { font-weight: bold; color: purple; }
    .grf-div span { font-weight: bold; }
    .grf-div li { margin-left: 15px; }
  `]
})
export class TableLogsComponent {
  @Input() rows;
  @Input() sortData;
  @Input() comm: string;
  @Input() currentPage: number;

  changeInd;
  columns;

  constructor(
    private _router: Router,
    private ref: ChangeDetectorRef,
    config: NgbPopoverConfig
  ) {
    config.triggers = 'hover';
  }

  ngOnChanges() {
    if (this.changeInd) return;

    if (this.comm === 'sensor') {
      this.columns = [
        { field: 'created', display: 'Sever Timestamp' },
        { field: 'diff', display: 'Time Diff' },
        { field: 'triggers', display: 'Trigger(s)' },
        { field: 'sensor_rssi', display: 'Sensor RSSI' },
        { field: 'hub_rssi', display: 'ACK RSSI' },
        { field: 'rssi_diff', display: 'RSSI Diff' },
        { field: 'volts', display: 'Voltage' },
        { field: 'hub_serial', display: 'Hub Serial' },
        { field: 'sensor_comm_id', display: 'Comm ID' },
        { field: 'command_format', display: 'Comm' },
      ];
    } else {
      this.columns = [
        { field: 'created', display: 'Server Timestamp' },
        { field: 'diff', display: 'Time Diff' },
        { field: 'triggers', display: 'Trigger(s)' },
        { field: 'signal', display: 'Signal' },
        { field: 'volts', display: 'Voltage' },
        { field: 'command_format', display: 'Comm' }
      ];
    }

    this.changeInd = setInterval(() => {
      if (!this.rows?.length) return;

      clearInterval(this.changeInd);
      this.rows.map((r: any, i) => {
        if (this.comm === 'sensor') r.grf = this.makeGrf(r);
        else r.gds = this.makeGds(r.command_format);
      });

      this.rows.map((r: any, i) => {
        if (this.rows[(i + 1)]) {
          let t = (r.sensor_rtc || r.created);
          let nt = (this.rows[(i + 1)].sensor_rtc || this.rows[(i + 1)].created)

          let diff = (new Date(t).getTime() - new Date(nt).getTime());
          r.diff = this.makeTimeDiff(diff);
        }
      });

      if (this.comm === 'sensor') this.handleSignals();

      this.ref.detectChanges();
      this.changeInd = undefined;
    });
  }

  makeTimeDiff(milliseconds: number) {
    let intervals = [
      { display: 'd', interval: 86400000 },
      { display: 'h', interval: 3600000 },
      { display: 'm', interval: 60000 },
      { display: 's', interval: 1000 },
    ];

    let tmp = milliseconds;
    let rtn = '';

    intervals.map((i) => {
      if (tmp > i.interval) {
        let d = Math.floor(tmp / i.interval);
        tmp = (tmp - (d * i.interval));

        rtn += `${d}${i.display} `;
      }
    });

    if (tmp > 0 && rtn === '') rtn += `${tmp}ms`;
    return rtn.trimEnd();
  }

  makeGds(command_format: string) {
    let gds = command_format.split(":");
    let rtn = [];

    let arr = [
      'Trigger - Battery - Reset - Format - On - Off',
      'Signal Strength',
      'Delayed Alert Count',
      'Voltage',
      'Extended Data Count',
      'RF Message Count',
      'LOF Retries',
      'Firmware Version'
    ];

    gds.map((e, ind) => {
      rtn.push({
        popover: (arr[ind] || 'unknown'),
        segment: e
      });
    });

    return rtn;
  }

  makeGrf(row: any) {
    let payload = readGrfPayload(row.command_format);

    if (row.command_format === 'n/a') row.command_format = 'N/a';
    if (!payload) return { segments: [{ popover: { title: 'No data to display' }, segment: row.command_format }] };

    let rtn: any = {
      data: payload.data,
      segments: []
    };

    let dpMap = {
      serialNumber: { title: 'Serial Number', data: [payload.data.serialNumber] },
      status: {
        title: 'Type/ Status',
        data: [
          `<b>Event Type:</b> ${payload.data.status.eventType}`,
          `<b>Trigger:</b> ${payload.data.status.trigger}`,
          `<b>Sensor Test:</b> ${payload.data.status.sensorTest}`,
          `<b>Powered On:</b> ${payload.data.status.poweredOn}`,
          `<b>Powered Off:</b> ${payload.data.status.poweredOff}`,
          `<b>Reset:</b> ${payload.data.status.reset}`,
          `<b>Heartbeat:</b> ${payload.data.status.heartbeat}`
        ]
      },
      extendedData: {
        title: 'Extended Data',
        data: [(
          payload.data.extendedData.moduleStatus !== undefined ?
            `<b>Module Status:</b> ${(payload.data.extendedData.moduleStatus === 1 ? 'Present' : 'Not Present')}` :
            `<b>Module Value:</b> ${payload.data.extendedData.moduleValue}`
        )]
      },
      voltage: { title: 'Voltage', data: [payload.data.voltage] },
      sensitivity: { title: 'Sensitivity', data: [payload.data.sensitivity] },
      commID: { title: 'Comm ID', data: [payload.data.commID] },
      firmwareVersion: { title: 'Firmware Version', data: [payload.data.firmwareVersion] },
      hubRSSI: { title: 'ACK RSSI', data: [payload.data.hubRSSI] },
      busyCount: { title: 'Busy Count', data: [payload.data.busyCount] },
      retryCount: { title: 'Retry Count', data: [payload.data.retryCount] },
      triggerBackOff: { title: 'Trigger BackOff', data: [payload.data.triggerBackOff] },
      pause: { title: 'Pause', data: [payload.data.pause] },
      minBackOff: { title: 'Min BackOff', data: [payload.data.minBackOff] },
      heartbeat: { title: 'Heartbeat', data: [payload.data.heartbeat] },
      cpuTemperature: { title: 'CPU Temp.', data: [payload.data.cpuTemperature] },
      timestamp: { title: 'Sensor RTC', data: [payload.data.timestamp] },
      sensorRSSI: { title: 'Sensor RSSI', data: [payload.data.sensorRSSI] }
    }

    Object.entries(dpMap).map(([k, v]) => {
      if (payload.data[k] !== undefined) rtn.segments.push({
        popover: { title: v.title, data: v.data },
        segment: payload.raw[k]
      });
    });


    if (payload.data.timestamp) {
      row.sensor_rtc = payload.data.timestamp;

      if (this.columns[0].field !== 'sensor_rtc') {
        this.columns[0] = { field: 'sensor_rtc', display: 'Sensor RTC' };
        this.columns.push({ field: 'created', display: 'Sever Timestamp' });
      }
    }

    rtn.segments[(rtn.segments.length - 1)].last = true;
    return rtn;
  }

  handleSignals() {
    for (let i = 0; i < this.rows.length; i++) {
      let row = this.rows[i];

      var nextRow;
      if (i !== 0) nextRow = this.rows[(i - 1)];

      row.sensor_rssi = Number(row.signal);
      if (row.sensor_rssi > 0) row.sensor_rssi = (row.sensor_rssi - (row.sensor_rssi * 2));

      if (
        nextRow?.grf?.data?.hubRSSI &&
        nextRow.hub_serial === row.hub_serial
      ) {
        row.hub_rssi = Number(nextRow.grf.data.hubRSSI.replace(' dB', ''));
        row.rssi_diff = (row.sensor_rssi - row.hub_rssi);
      } else {
        row.hub_rssi = 'N/a';
        row.rssi_diff = 'N/a';
      }
    }
  }

  getColor(value) {
    // Should move these colors into a style sheet
    if (value === 'N/a') return {};
    if (value < -93 || value > 93) return { 'background-color': '#ffe6e6' };  // marginal
    if (value < -83 || value > 83) return { 'background-color': '#fff6e6' };  // ok
    if (value < -73 || value > 73) return { 'background-color': '#ffffe6' };  // good
    if (value < 0 || value > 0) return { 'background-color': '#e6ffe6' }; // excellent
    return {};
  }

  getVoltageColor(value) {
    if (value < 2.5) return { 'background-color': '#ffe6e6' };  // marginal
    if (value < 3.5) return { 'background-color': '#fff6e6' };  // ok
    if (value < 4.5) return { 'background-color': '#ffffe6' };  // good
    if (value <= 5.5) return { 'background-color': '#e6ffe6' }; // excellent
    return {};
  }

  onShowHubDetails(serial: string) {
    this._router.navigate(['./device/edit/', serial]);
  }

  onKibanaSearch(url) {
    window.open(url, "_blank");
  }

  exportExcel() {
    let dataSets: any = [];
    let columnSets: any = this.columns.map((c: any) => {
      return {
        value: c.display,
        fontWeight: 'bold',
        align: 'left'
      }
    });

    var serial;
    for (let row of this.rows) {
      if (!serial) serial = row.device_serial;

      let tmp: any = [];
      for (let col of this.columns) {
        var data;

        if (row[col.field]) data = row[col.field];
        else if (row.gds && row.gds[col.field]) data = row.gds[col.field];
        else if (row.grf && row.grf[col.field]) data = row.grf[col.field];
        else data = 'N/a';

        if (col.field === 'created') data = new Date(data).toLocaleString();

        tmp.push({
          value: data,
          type: (typeof (data) === 'string' ? 'String' : 'Number'),
          align: 'right'
        });
      }

      dataSets.push(tmp);
    }

    let colWidths: any = [
      { width: 21 }, // Sensor RTC or Sever Timestamp
      { width: 16 }, // Time Diff
      { width: 16 }  // Triggers
    ];

    if (this.comm === 'sensor') {
      colWidths.push(
        { width: 12 }, // Sensor RSSI
        { width: 14 }, // ACK RSSI
        { width: 10 }, // RSSI Diff
        { width: 8 },  // Voltage
        { width: 14 }, // Hub Serial
        { width: 10 }, // Comm ID
      );

      if (this.columns[0].field === 'sensor_rtc') {
        colWidths.push(
          { width: 66 }, // Comm
          { width: 21 }  // Sever Timestamp
        );
      } else colWidths.push({ width: 28 }); // Comm
    } else {
      colWidths.push(
        { width: 8 },  // Signal
        { width: 8 },  // Voltage
        { width: 34 }, // Comm
      );
    }

    writeExcelFile([
      columnSets,
      ...dataSets
    ], {
      fileName: `${serial} ${(this.comm === 'sensor' ? 'Sensor' : 'Device')} Logs - Page ${(this.currentPage + 1)}.xlsx`,
      fontSize: 10,
      columns: colWidths
    });
  }
}
