<div class="modal-header bg-primary" style="border: solid 3px #fff;">
    <h4 class="modal-title text-light">{{ createBus.title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="decline()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body">
    <label class="custom-label" for='sameAddress'>Same as main address</label>
    <div class="form-check form-check-inline" (click)="setChecked()">
      <input class="form-check-input" type="checkbox" id="sameAddress" [value]=false >
    </div>
  
    <app-message></app-message>
  
    <form>
      <div class="form-row">
        <div class="form-group col-md-6">
            <label for="city">Address<span class="asterisk">*</span></label>
          <input type="text" class="form-control" [modeled-form]="billingForm" id="address" required />
        </div>
        <div class="form-group col-md-6">
          <label for="city">Address 2 (optional)</label>
          <input type="text" class="form-control" [modeled-form]="billingForm" id="address2" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="city">City<span class="asterisk">*</span></label>
          <input type="text" class="form-control" id="city" [modeled-form]="billingForm" required />
        </div>
        <div class="form-group col-md-3">
          <label for="state">State<span class="asterisk">*</span></label>
          <select id="state" class="form-control" [modeled-form]="billingForm">
            <option selected>Choose...</option>
            <option *ngFor="let state of createBus.states">{{ state }}</option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="zip">Zip<span class="asterisk">*</span></label>
          <input type="text" class="form-control" id="zip" [modeled-form]="billingForm" pattern="^\d{5}([-]|\s*)?(\d{4})?$"
            mask="00000||00000-0000" required />
        </div>
        <div class="form-group col-md-3">
          <label for="sage">Sage Id<span class="asterisk">*</span></label>
          <input type="text" class="form-control" id="sage" [modeled-form]="billingForm" required />
        </div>
      </div>
      <div class="content-body">
        <div class="form-row">
          <h4>Payment Method</h4>
          <h4 class="h4-position">Payment Terms</h4>
          <h4 class="h4-position">Tax Exempt</h4>
        </div>
        <div class="form-row">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="paymentMethod" id="paymentMethod" [modeled-form]="billingForm">
            <label class="form-check-label" for="paymentMethod">Credit Card</label>
          </div>
          <div class="form-check form-check-inline" style="margin-left: 150px;">
            <input class="form-check-input" type="radio" name="paymentTerms" id="paymentTerms" [modeled-form]="billingForm">
            <label class="form-check-label" for="paymentTerms">Monthly</label>
          </div>
          <div class="form-check form-check-inline" style="margin-left: 157px;">
            <input class="form-check-input" type="radio" name="taxExempt" id="taxExempt" [modeled-form]="billingForm">
            <label class="form-check-label" for="taxExempt">Yes</label>
          </div>
        </div>
        <div class="form-row">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="paymentMethod" id="ach" [checked]="!getControlValue('paymentMethod')">
            <label class="form-check-label" for="ach">ACH</label>
          </div>
          <div class="form-check form-check-inline" style="margin-left: 219px;">
            <input class="form-check-input" type="radio" name="paymentTerms" id="net30" [checked]="!getControlValue('paymentTerms')">
            <label class="form-check-label" for="net30">Net-30</label>
          </div>
          <div class="form-check form-check-inline" style="margin-left: 172px;">
            <input class="form-check-input" type="radio" name="taxExempt" id="radioNo" [checked]="!getControlValue('taxExempt')">
            <label class="form-check-label" for="radioNo">No</label>
          </div>
        </div>
      </div>
      <br>
    </form>
  </div>
  <div class="modal-footer">
    <button id="next" type="button" class="btn btn-outline-warning" (click)="next()" [disabled]="!billingForm.valid">{{createBus.nextText}}</button>
    <button id="cancel" type="button" class="btn btn-outline-warning" (click)="decline()">Cancel</button>
  </div>