import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-table-header',
  template: `
  <div class="row mb-1 text-muted">
    <div class="col-9">
      <small class="pull-left"><i>{{ title }}: {{ deviceCount }}</i></small>
    </div>
    <div class="col-3">
      <small class="pull-right"><i>Page {{ currentPage + 1 }} of {{pageCount}}</i></small><br />
    </div>
  </div>
  `,
  styles: []
})
export class TableHeaderComponent implements OnInit {

  constructor() { }

  @Input() deviceCount;
  @Input() currentPage;
  @Input() pageCount;
  @Input() title = "Total Device Count";

  ngOnInit() {
  }

}
