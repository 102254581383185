import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from './user';
import { AuthService } from '../shared/auth/auth.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'app/shared/components/message/message.service'
import { Alert } from '../shared/components/message/message.alert.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  rememberEmail: boolean = (this._authService.email !== null);
  showPassword: boolean = false;
  user = new User("", "", null, null, null, null);
  allowed: boolean = false;
  redirect: string;

  constructor(
    private _router: Router, 
    private _authService: AuthService,
    private _fb: FormBuilder,
    private _messageService: MessageService,
    private _activatedRoute: ActivatedRoute
  ) { }

  loginForm = this._fb.group({
    email: [(this._authService.email || ''), [Validators.required, Validators.email]],
    password: ['', [Validators.required]]
  });

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }

  ngOnInit() { 
    this.showPassword = false;
    this._activatedRoute.params.subscribe((params: any) => {
      if (params.redirect) this.redirect = params.redirect;
      if (params.tokenExpired === 'true') setTimeout(() => {
        this._messageService.setMessage(Alert.DANGER, "Session expired...");
      }, 150);

      if (this._authService.isLoggedIn && !this._authService.expired) {
        this._authService.setRefreshTimeout();
        this.leaveLogin();
      }
    });
  }

  rememberClick() {
    if (this.rememberEmail) {
      this.rememberEmail = false;
      localStorage.removeItem('email');
    } else this.rememberEmail = true;
  }

  onLogin() {
    if (this.rememberEmail) this._authService.email = this.email.value;

    this.user.email = this.email.value;
    this.user.password = this.password.value;

    this._authService.signin(this.user).subscribe(res => {
      if (!this._authService.roles.includes('master') && !this._authService.roles.includes('admin')) {
        this._router.navigate(['/login']);
        this._messageService.setMessage(Alert.DANGER, "Permission Denied");
        return;
      }

      this.leaveLogin();
    }, (error) => {
      console.log(error);
      this._messageService.setMessage(Alert.DANGER, "Unable to connect to API");
    });
  }

  private leaveLogin() {
    if (this.redirect && this.redirect !== '[object Object]') {
      this._router.navigate([this.redirect]);
    } else {
      this._router.navigate(['device/list']);
    }
  }
}
