<div class="modal-header bg-primary" style="border: solid 3px #fff;">
  <h4 class="modal-title text-light">{{ createBus.title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="decline()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<app-message></app-message>

<div class="modal-body">
  <label class="custom-label" for="existingUser">Existing User</label>
  <div class="form-check form-check-inline">
    <input class="form-check-input" type="checkbox" id="existingUser" [modeled-form]="userForm"/>
  </div>
  <div *ngIf="createBus.title === 'Create Business - Billing User'" style="display: inline">
    <label class="custom-label" for='fullAdminRights'>Full Admin Rights</label>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="checkbox" id="fullAdminRights" [modeled-form]="userForm" />
    </div>
  </div>
  <div *ngIf="existingUser === true">
    <div class="form-group shadow-z-1">
      <div class="search">
        <input type="text" class="form-control" id="email" autocomplete="off" required
          placeholder="Search by email" [modeled-form]="userForm"/>
        <span aria-hidden="true" class="icon-x" (click)="reset()">&times;</span>
      </div>
      <div *ngIf="email" class="auto-suggest">
        <ul>
          <li *ngFor="let user of users" (click)="email = user.email">{{ user.email }}</li>
        </ul>
      </div>
    </div>
    <span *ngIf="!emailFound && users.length === 0 && email.length > 0 && userForm.controls.email.valid" class="error">
      Must select from displayed options
    </span>
  </div>
  <form *ngIf="existingUser === false">
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="city">First Name<span class="asterisk">*</span></label>
        <input type="text" class="form-control" [modeled-form]="userForm" id="firstName" required />
      </div>
      <div class="form-group col-md-6">
        <label for="city">Last Name<span class="asterisk">*</span></label>
        <input type="text" class="form-control" [modeled-form]="userForm" id="lastName" required />
      </div>
    </div>
    <div class="form-group">
      <label for="city">Email<span class="asterisk">*</span></label>
      <input type="email" class="form-control" id="email" [modeled-form]="userForm" required />
    </div>
    <div class="form-group">
      <label for="city">Confirm Email<span class="asterisk">*</span></label>
      <input type="email" class="form-control" id="confirmEmail" [modeled-form]="userForm" required />
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="city">Password<span class="asterisk">*</span></label>
        <input [type]="showPass ? 'text' : 'password'" id="password" class="form-control" data-lpignore="true" [modeled-form]="userForm" required />
        <span aria-hidden="true" [class]="showPass ? 'far fa-eye icon-pass' : 'far fa-eye-slash icon-pass'" (click)="passwordShow()"></span>
      </div>
      <div class="form-group col-md-6">
        <label for="city">Confirm Password<span class="asterisk">*</span></label>
        <input [type]="showConfirmPass ? 'text' : 'password'" class="form-control" data-lpignore="true" id="confirmPassword" [modeled-form]="userForm" required />
        <span aria-hidden="true" [class]="showConfirmPass ? 'far fa-eye icon-pass' : 'far fa-eye-slash icon-pass'" (click)="confirmPasswordShow()"></span>
      </div>
    </div>
    <div class="form-group">
      <label for="city">Phone<span class="asterisk">*</span></label>
      <input type="text" class="form-control" mask="(000) 000-0000||0 (000) 000-0000" [modeled-form]="userForm" id="phone" required />
    </div>
  </form>
</div>

<div class="modal-footer">
  <button id='next' type="button" class="btn btn-outline-warning" (click)="next()" [disabled]="(!existingUser && !userForm.valid) || (existingUser && (!userForm.controls.email.valid || !emailFound))">{{createBus.nextText}}</button>
  <button id='cancel' type="button" class="btn btn-outline-warning" (click)="decline()">Cancel</button>
</div>