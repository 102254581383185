import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private _router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isLoggedIn) {
      return true;
    } else {
      let rtn = await this.authService.refreshToken();
      if (rtn && this.isLoggedIn) return true;

      if (this.authService.appLoaded) this.authService.logout({ queryParams: { redirect: state.url } }, true);
      else this.authService.logout();

      this.authService.appLoaded = true;
      return false;
    }
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn;
  }
}
