type CustomValidator = (value: (string | number | boolean), control: ModeledControl) => (string | null);
type FormValidator = (values: { [property: string]: (string | number | boolean) }, controls: { [controlKey: string]: ModeledControl }) => { [controlKey: string]: string };
type ValidatorObject = { type: ValidationTypes, data?: (string | number | CustomValidator | string[]) };
type ControlOptions = {
    objectRef?: { propertyKey: string, holdingObject: Object },
    initialValue?: (string | number | boolean),
    validators?: ValidatorObject[],
    displayErrors?: boolean,
    debounceMs?: number,
    onEvaluate?: (value: (string | number | boolean), valid: boolean) => void;
};

const emailRegEx = `[A-Za-z0-9._%+-]{2,}@[a-zA-Z-_.]{2,}[.]{1}[a-zA-Z]{2,}`;

const validators = {
    customValidator(fn: CustomValidator, value: any, control: ModeledControl) {
        return fn(value, control);
    },
    required(control: ModeledControl) {
        let val = control.value;
        if (!val && val !== false) return 'Required..!';

        return null;
    },
    minLength(control: ModeledControl, len: number) {
        let val = (control.value as string);
        if (val.length < len) return `Value must contain ${len} or more characters..!`;

        return null;
    },
    maxLength(control: ModeledControl, len: number) {
        let val = (control.value as string);
        if (val.length > len) return `Value must contain ${len} or less characters..!`;

        return null;
    },
    min(control: ModeledControl, len: number) {
        let val = (control.value as number);
        if (val < len) return `Value must be greater then, or equal, to: ${len}..!`;

        return null;
    },
    max(control: ModeledControl, len: number) {
        let val = (control.value as number);
        if (val > len) return `Value must be less then, or equal, to: ${len}..!`;

        return null;
    },
    pattern(control: ModeledControl, pattern: string) {
        let val = (control.value as string);
        if (!val.match(pattern)) return `Value doesn't match pattern: ${pattern}..!`;

        return null;
    },
    match(control: ModeledControl, controls: string[]) {
        let missmatch = [];
        controls.map((e) => {
            let controls = control.form.controls;
            if (!controls[e].touched) return;

            if (control.value !== controls[e].value) {
                missmatch.push(e);
                if (!controls[e].errorTypes.includes(ValidationTypes.match)) controls[e].evaluate();
            } else {
                if (controls[e].errorTypes.includes(ValidationTypes.match)) controls[e].evaluate();
            }
        });

        if (missmatch.length) return `Value doesn't match: ${missmatch.join(', ')}..!`;
    },
    email(control: ModeledControl) {
        if (validators.pattern(control, emailRegEx)) return `Value must be in the following format: xx@xx.xx`;
        return null;
    }
}

export enum ValidationTypes {
    custom = 0,
    required = 1,
    minLength = 2,
    maxLength = 3,
    min = 4,
    max = 5,
    pattern = 6,
    match = 7,
    email = 8
}

export class ModeledControl {
    private _options: ControlOptions;
    private _element: any;
    private _elmNodeType: string = '';
    private _elmType: string = '';
    private _debounceWait: number = 0;
    private _debounceIndex: any = -1;
    private _settingElement: boolean = false;

    evalCallback: Function;
    touched: boolean = false;
    valid: boolean = false;
    errors: string[] = [];
    errorTypes: ValidationTypes[] = [];
    key: string = '';
    form: ModeledForm;

    get value() {
        return (this._elmNodeType === 'input' && (this._elmType === 'radio' || this._elmType === 'checkbox') ? this._element?.checked : this.element?.value);
    }
    set value(val: (string | number | boolean)) {
        if (!this._element) return;

        if (this._elmNodeType === 'input' && (this._elmType === 'radio' || this._elmType === 'checkbox')) this._element.checked = val;
        else this._element.value = val;

        if (!this._settingElement) this.evaluate();
    }

    get element() {
        return this._element;
    }
    set element(elm: any) {
        this._settingElement = true;

        this._element = elm;
        this._elmNodeType = elm.nodeName.toLowerCase();
        this._elmType = (elm.type || '');

        if (this._options.initialValue || this._options.objectRef) {
            let val = (this._options.initialValue || this._options.objectRef);
            if (typeof (val) === 'object') val = val.holdingObject[val.propertyKey];

            setTimeout(() => {
                this._settingElement = true;
                (this.value as any) = val;
                this._settingElement = false;
            }, 50);
        }

        if (this._elmNodeType === 'select' || this._elmType === 'radio' || this._elmType === 'checkbox') {
            elm.onchange = (() => {
                this.touched = true;
                this.evaluate();
            }).bind(this);
        } else {
            elm.onkeyup = (() => {
                this.touched = true;

                if (this._options.debounceMs) {
                    if (this._debounceIndex > -1) return this._debounceWait = this._options.debounceMs;
                    else {
                        this._debounceWait = this._options.debounceMs;
                        this._debounceIndex = setInterval(() => {
                            if (this._debounceWait === 0) {
                                clearInterval(this._debounceIndex);
                                this._debounceIndex = -1;
                                this.evaluate();
                            } else {
                                this._debounceWait -= 50;
                            }
                        }, 50);
                    }
                } else this.evaluate();
            }).bind(this);
        }

        this._settingElement = false;
    }

    constructor(options: ControlOptions) {
        this._options = options;
        if (!this._options.validators?.length) this.valid = true;
    }

    //Validate the modeled-control value with the supplied validators
    async evaluate(override?: boolean, error?: string, errorType?: ValidationTypes): Promise<boolean> {
        this.errors = [];
        this.errorTypes = [];
        this.valid = false;

        if (override) {
            if (error) {
                this.errors.push(error);
                this.errorTypes.push(errorType);
                this.valid = false
            } else this.valid = true;
        } else {
            if (this._options.validators) {
                this._options.validators.map(async (v: ValidatorObject) => {
                    var err;

                    if (v.type === ValidationTypes.custom) {
                        err = await validators.customValidator((v.data as CustomValidator), this.value, this)
                    } else err = validators[ValidationTypes[v.type]](this, v.data);

                    if (err) {
                        this.errors.push(err);
                        this.errorTypes.push(v.type);
                    }
                });
            } else if (!this.evalCallback) this.valid = true;

            if (!this._options.displayErrors) {
                if (!this.errors.length) this.valid = true;

                if (this._options.onEvaluate) this._options.onEvaluate(this.value, this.valid);
                if (this.evalCallback) this.evalCallback();

                return this.valid;
            }
        }

        if (this._options.displayErrors) {
            let tmp = this._element.nextElementSibling;
            if (!this.errors.length) {
                this.valid = true;

                if (tmp && tmp.hasAttribute('data-key')) {
                    this._element.style.border = tmp.getAttribute('data-border');
                    tmp.remove();
                }
            } else if (!tmp || !tmp.hasAttribute('data-key')) {
                let errText = document.createElement('span');
                errText.innerHTML = this.errors.join('<br>');
                errText.style.color = 'red';
                errText.setAttribute('data-key', this.key);
                errText.setAttribute('data-border', this._element.style.border);

                this._element.insertAdjacentElement('afterend', errText);
                this._element.style.border = '2px solid red';
            }
        }

        if (!override) {
            if (this._options.onEvaluate) this._options.onEvaluate(this.value, this.valid);
            if (this.evalCallback) this.evalCallback();
        }

        return this.valid;
    }

    //Apply the modeled-control value to the object reference, if exists
    submit() {
        if (this._options.objectRef) this._options.objectRef.holdingObject[this._options.objectRef.propertyKey] = this.value;
    }
}

export class ModeledForm {
    private validators: FormValidator[];
    controls: { [key: string]: ModeledControl } = {};

    get valid() {
        let invalid = Object.values(this.controls).find(e => e.valid === false);
        return (!invalid);
    }

    get values(): { [property: string]: (string | number | boolean) } {
        let tmp: any = {};
        Object.entries(this.controls).map(([k, v]) => tmp[k] = v.value);

        return tmp;
    }

    set values(obj: { [property: string]: (string | number | boolean) }) {
        Object.entries(obj).map(([k, v]) => this.controls[k].value = v);
    }

    constructor(_controls: { [controlKey: string]: ModeledControl }, validators?: FormValidator[]) {
        if (validators) this.validators = validators;

        Object.entries(_controls).map(([k, v]) => {
            this.controls[k] = v;
            this.controls[k].key = k;

            this.controls[k].form = this;
        });
    }

    pair(element: (HTMLElement | HTMLInputElement)) {
        let controlKey = element.id;
        this.controls[controlKey].element = element;

        if (this.validators) {
            this.controls[controlKey].valid = false;
            this.controls[controlKey].evalCallback = () => {
                this.validators.map((f) => {
                    let rtn: { [controlKey: string]: string } = f(this.values, this.controls);
                    Object.entries(rtn).map(([k, v]) => {
                        this.controls[k].evaluate(true, v, ValidationTypes.custom);
                    });
                });
            }
        }
    }

    evaluate() {
        Object.values(this.controls).map(async (e) => await e.evaluate());
        return this.valid;
    }

    submit() {
        Object.values(this.controls).map(async (e) => e.submit());
    }
}
