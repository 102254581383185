<div class="apex-content content container-fluid">
  <h1>Search Verizon Request Table vzwreqs</h1>

  <div class="content-body">
    <div class="row">
      <div class="col-6">
        <form [formGroup]='searchForm' (ngSubmit)='getVzwreqs()'>
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <button type="submit" class="btn btn-outline-secondary">Search</button>
            </div>
            <input type="text" minlength="2" placeholder="Enter complete device serial" class="form-control"
              formControlName="searchTerm">
          </div>
          <p *ngIf="searchForm.invalid && searchForm.touched" class="text-danger">Please enter at least 2 characters.
          </p>
        </form>
      </div>
    </div>

    <div class="col">
      <div class="card">
        <div class="card-body m-1">
          <h5 class="card-title text-muted">vzreqs</h5>
          <table class="table table-bordered table-striped">
            <tr>
              <th>Created</th>
              <th>Type</th>
              <th>Response</th>
            </tr>
            <tr *ngFor="let req of vzreqs">
              <td> {{ req.created }} </td>
              <td> {{ req.reqtype }} </td>
              <td> {{ req.vzwres }} </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>