<div class="apex-content content container-fluid">
  <div class="content-wrapper">
    <div class="content-body">
      <h2>Add {{title}} Firmware Package</h2>

      <form [formGroup]="uploadForm" (ngSubmit)="onFileUpload()">
        <div *ngIf="!submitted">
          <label>Firmware file select</label>

          <div class="form-group">
            <input formControlName="file" class="btn btn-outline-secondary" type="file" accept=".s19"
              (change)="onFileSelected($event)" name="uploadFile" />
            <br>

            <label for="model">Model: </label><br>
            <input type="text" formControlName="model" oninput="value = value.toUpperCase()" maxlength="3" required />

            <p *ngIf="model.invalid && model.touched" class='danger'>
              Input should have capital letters with length of 3
            </p>

          </div>

          <div class="form-group">
            <label for="version">Version: </label><br>
            <input type="text" formControlName="version" oninput="value = value.toUpperCase()" maxlength="5" minlength="2" required/>
            <p *ngIf="version.invalid && version.touched" class='danger'>Input should have capital letters and numbers with length of at least 2</p>
          </div>

          <button [disabled]="uploadForm.invalid" class="btn btn-outline-secondary">
            Add Firmware
          </button>

        </div>
      </form>
      
      <a *ngIf="!submitted" class="btn btn-outline-secondary" routerLink="/fota/skyhawk/firmware/list">
        Firmware List
      </a>
      <app-message></app-message>
      <a *ngIf="submitted" class="btn btn-outline-secondary" (click)="onAddAnother()">OK</a>

    </div>
  </div>