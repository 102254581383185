import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  jsonUrl = 'assets/data/';
  lb4Url = environment.API_BASE_URL;

  readonly requestOptions: Object = {
    responseType: 'text'
  }

  constructor(private _httpClient: HttpClient) { }

  /** vzwreqs */

  getVzreqs(device_serial): Observable<any> {
    let url = `${this.lb4Url}/vzwreqs?filter[where][device_serial]=${device_serial}`;
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError));
  }


  /** battery tracking */

  getBatteryTrackingLatest(id): Observable<any> {
    let url = `${this.lb4Url}/devices/${id}/battery-trackings?` +
      `filter[where][and][1][reset_on]=0&filter[order]=id DESC&filter[limit]=1`;
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError));
  }


  /** user */

  deleteUser(id) {
    let url = `${this.lb4Url}/users/${id}`;
    return this._httpClient.delete<any>(url).pipe(catchError(this.handleError));
  }

  updateUser(id, body) {
    let url = `${this.lb4Url}/users/${id}`;
    return this._httpClient.patch<any>(url, body).pipe(catchError(this.handleError));
  }

  changeEmail(id, email) {
    let url = `${this.lb4Url}/users/${id}/update-email?email=${email}`;
    return this._httpClient.patch<any>(url, null).pipe(catchError(this.handleError));
  }

  updateRoles(userId: string, roles: { [role: string]: boolean }) {
    let url = `${this.lb4Url}/users/${userId}/update-roles`;
    return this._httpClient.post<any>(url, roles).pipe(catchError(this.handleError)).toPromise();
  }

  sendVerificationEmail(email) {
    let url = `${this.lb4Url}/users/resend-verification?email=${encodeURIComponent(email)}`;
    return this._httpClient.post<any>(url, null).pipe(catchError(this.handleError));
  }

  getRoles(userId) {
    let url = `${this.lb4Url}/users/${userId}/get-roles`;
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError)).toPromise();
  }

  getUsers(limit, skip, searchTerm?): Observable<any[]> {
    let url = `${this.lb4Url}/users?`;
    let filter: any = {};

    if (searchTerm) {
      // Search by email or phone
        filter = {
          where: {
            or: [
              { email: { like: `%${searchTerm}%` } },
              { phone: { like: `%${searchTerm}%` } },
              { first_name: { like: `%${searchTerm}%` } },
              { last_name: { like: `%${searchTerm}%` } }
            ]
          }
        };
    } else if (limit) {
      filter.limit = limit;
      filter.skip = skip;
    }
    filter.order = "id DESC";
    
    return this._httpClient.get<any[]>(url+`filter=${encodeURIComponent(JSON.stringify(filter))}`).pipe(catchError(this.handleError));
  }

  searchBusinesses(limit: number, skip: number, searchTerm: string) {
    let filter: any = {
      fields: ["id", "name", "city", "state"],
      where: {
        or: [
          { email: { like: `%${searchTerm}%` } },
          { name: { like: `%${searchTerm}%` } }
        ]
      }
    };
    if (limit) filter.limit = limit
    if (skip) filter.skip = skip

    let url = `${this.lb4Url}/businesses?filter=${encodeURIComponent(JSON.stringify(filter))}`;

    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError));
  }
  getBusinessUsers(limit: number, skip: number, searchTerm: string) {
    let filter: any = {
      where: {
        business_id: { neq: null },
        and: [{
          or: [
            { email: { like: `%${searchTerm}%` } },
            { first_name: { like: `%${searchTerm}%` } }
          ]
        }]
      }
    };
    if (limit) filter.limit = limit
    if (skip) filter.skip = skip

    let url = `${this.lb4Url}/users?filter=${encodeURIComponent(JSON.stringify(filter))}`;
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError));
  }

  getUsersByEmail(searchTerm): Promise<any[]> {
    let url = `${this.lb4Url}/users?filter=${encodeURIComponent(JSON.stringify({
      where: {
        business_id: null,
        email: {
          like: `%${searchTerm}%`
        }
      }
    }))}`;

    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError)).toPromise();
  }

  // No /user/count method, so call /users and use response.length instead
  getUserCount(searchTerm): Observable<any> {
    let url = `${this.lb4Url}/users?`;
    let filter: any = {};
    if (searchTerm) {
      // Search by email or phone
      filter = {
        where: {
          or: [
            { email: { like: `%${searchTerm}%` } },
            { phone: { like: `%${searchTerm}%` } },
            { first_name: { like: `%${searchTerm}%` } },
            { last_name: { like: `%${searchTerm}%` } }
          ]
        }
      };
    }

    filter.order = "id DESC";

    return this._httpClient.get<any[]>(url+`filter=${encodeURIComponent(JSON.stringify(filter))}`).pipe(catchError(this.handleError));
  }

  getUser(id: string): Observable<any[]> {
    let url = `${this.lb4Url}/users/${id}`;
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError));
  }

  resetPassword(email) {
    let url = `${this.lb4Url}/users/reset_password`;
    return this._httpClient.post<any>(url, { email: email }, this.requestOptions);
  }


  /** subscriptions */

  getUserSubscriptions(user_id) {
    let url = `${this.lb4Url}/subscriptions?filter[limit]=10&filter[where][user_id]=${user_id}`;
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError));
  }

  getDeviceSubscriptions(device_serial) {
    let url = `${this.lb4Url}/subscriptions?filter[limit]=10&filter[where][device_serial]=${device_serial}`;
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError));
  }

  swapSubscriptionDevice(id: string, newSerial: string, requiresTesting: boolean) {
    let params = `new_serial=${newSerial}&requires_testing=${requiresTesting}`
    let url = `${this.lb4Url}/devices/${id}/swap-device?${params}`;
    return this._httpClient.post<any>(url, null);
  }


  /** devices */

  getDevices(limit: any, skip: any, searchTerm: any): Promise<any> {

    let filter: any = { skip: skip, order: "id DESC" }
    if (limit) filter.limit = limit;

    if (searchTerm) {
      searchTerm = searchTerm.split(" ").join("_");
      filter.where = {
        or: [
          { "email": { "like": "%" + searchTerm + "%" } },
          { "device_serial": { "like": "%" + searchTerm + "%" } },
          { "devicename": { "like": "%" + searchTerm + "%" } },
          { "user_id": { "like": "%" + searchTerm + "%" } }
        ]
      }
    }

    let busRelation = {
      relation: 'business',
      scope: {
        fields: {
          name: true,
          id: true
        }
      }
    };

    if (!filter.include) filter.include = [busRelation];
    let encodedFilter = encodeURIComponent(JSON.stringify(filter));
    let url = `${this.lb4Url}/devices?filter=${encodedFilter}`
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError)).toPromise();
  }

  getDevice(id: string, serial?: string): Observable<any> {
    let url = `${this.lb4Url}/devices/${id}`;

    if (serial) {
      let encodedFilter = encodeURIComponent(JSON.stringify({
        where: { device_serial: serial }
      }));

      url = `${this.lb4Url}/devices?filter=${encodedFilter}`;
    }

    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError));
  }

  getUserDevices(id): Observable<any> {
    let filter = {
      fields: { id: true },
      include: [{ relation: "devices" }, { relation: "hubsensors" }]
    }
    let url = `${this.lb4Url}/users/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`;
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError));
  }

  getDeviceCount(searchTerm): Promise<any> {
    let url = '';
    var query: any;

    if (searchTerm) {
      // The square bracket notation (for count) does not support multiple query conditions.
      query = {
        "or": [
          { "email": { "like": "%" + searchTerm + "%" } },
          { "device_serial": { "like": "%" + searchTerm + "%" } },
          { "devicename": { "like": "%" + searchTerm + "%" } },
          { "user_id": { "like": "%" + searchTerm + "%" } }
        ]
      };
      let qe = encodeURI(JSON.stringify(query));
      url = `${this.lb4Url}/devices/count?where=${qe}`;
    } else {
      url = `${this.lb4Url}/devices/count`;
    }
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError)).toPromise();
  }

  getDeviceSerial(serial): Promise<any> {
    let url = `${this.lb4Url}/devices?filter[where][device_serial]=${serial}`;
    return this._httpClient.get<{ data: any[] }>(url).toPromise();
  }

  updateDevice(id, name, desc) {
    let body = { devicename: name, devicedesc: desc };
    let url = `${this.lb4Url}/devices/${id}`;
    return this._httpClient.patch<any>(url, body).pipe(catchError(this.handleError));
  }

  deviceForceUpdate(query, st_force) {
    let body = { st_force: st_force };
    let url = `${this.lb4Url}/devices?${query}`;
    return this._httpClient.patch<any>(url, body).pipe(catchError(this.handleError));
  }

  getParamDevices(query) {
    let url = `${this.lb4Url}/devices?${query}`;
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError));
  }

  deleteDevice(id, rem_dsm) {
    let url = `${this.lb4Url}/devices/${id}?rem_dsm=${rem_dsm}`;
    return this._httpClient.delete<any>(url).pipe(catchError(this.handleError));
  }

  getUnitLogs(limit, skip, serial): Observable<any> {
    let url = `${this.lb4Url}/unit-command?filter[limit]=${limit}&filter[skip]=${skip}` +
      `&filter[where][device_serial]=${serial}&filter[order]=id DESC`;
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError));
  }

  getUnitLogsCount(serial): Observable<any> {
    var query: any;
    // The square bracket notation (for count) does not support multiple query conditions.
    query = { "or": [{ "device_serial": { "like": "%" + serial + "%" } }] }
    let qe = encodeURI(JSON.stringify(query));
    let url = `${this.lb4Url}/unit-command/count?where=${qe}`;
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError));
  }

  resetTC(id) {
    let url = `${this.lb4Url}/devices`;
    let body = { lifetime_tc: 0, id: id };
    return this._httpClient.patch<any>(url, body).pipe(catchError(this.handleError));
  }

  resetAllowedFirm(id) {
    let url = `${this.lb4Url}/devices`;
    let body = { unit_allowedfirm: '', id: id };
    return this._httpClient.patch<any>(url, body).pipe(catchError(this.handleError));
  }

  deactivate(id) {
    let url = `${this.lb4Url}/devices/${id}`;
    let body = { deactivate: 1, deactivate_changed: 1 }
    return this._httpClient.patch<any>(url, body);
  }

  reactivate(id) {
    let url = `${this.lb4Url}/devices/${id}`;
    let body = { deactivate: 0, deactivate_changed: 1 }
    return this._httpClient.patch<any>(url, body);
  }

  deactivateIn(id, value) {
    let url = `${this.lb4Url}/devices/${id}`;
    let body = { deactivatein: value, deactivatein_changed: 1 };
    return this._httpClient.patch<any>(url, body).pipe(catchError(this.handleError));
  }

  updateAutoCheckin(id, value) {
    let url = `${this.lb4Url}/devices/${id}`;
    let body = { unit_autocheckin: value, autochk_changed: 1 };
    return this._httpClient.patch<any>(url, body).pipe(catchError(this.handleError));
  }

  getActivationStatus(id: string): Observable<any> {
    let url = `${this.lb4Url}/devices/${id}/vzwStatus`;
    return this._httpClient.get<any>(url);
  }

  /** enterprise customer */

  login(user): Observable<any[]> {
    let body = { email: user.email, password: user.password }
    let url = `${this.lb4Url}/users/login`
    return this._httpClient.post<any>(url, body).pipe(catchError(this.handleError));
  }

  createBusiness(body): Promise<any[]> {
    let url = `${this.lb4Url}/businesses`;
    return this._httpClient.post<any>(url, body).toPromise();
  }

  getBusiness(id): Observable<any[]> {
    let url = `${this.lb4Url}/businesses/${id}`;
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError));
  }

  getBusinesses(): Promise<any[]> {
    const filter = {
      fields: { id: true, name: true, city: true, state: true },
      include: [{
        relation: "businessBillings",
        scope: {
          where: {
            or: [
              { bb_owner_id: { neq: null } },
              { bb_billing_admin_id: { neq: null } }
            ]
          }
        }
      }]
    };

    let url = `${this.lb4Url}/businesses?filter=${encodeURIComponent(JSON.stringify(filter))}`;
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError)).toPromise();
  }

  signupUser(user: any): Promise<any[]> {
    let url = `${this.lb4Url}/signup`;
    return this._httpClient.post<any>(url, user, this.requestOptions).toPromise();
  }

  addBusinessOwner(busId, userId) {
    let url = `${this.lb4Url}/businesses/${busId}/update-owner`;
    return this._httpClient.post<any>(url, { userId: userId }, this.requestOptions).toPromise();
  }

  addBillingUser(busId, userId) {
    let url = `${this.lb4Url}/businesses/${busId}/add_user`;
    return this._httpClient.post<any>(url, { userId: userId }, this.requestOptions).toPromise();
  }

  addBusinessBilling(busId, body) {
    let url = `${this.lb4Url}/businesses/${busId}/business-billings/create`;
    return this._httpClient.post<any>(url, body, this.requestOptions).toPromise();
  }

  updateBusinessBilling(busId, body) {
    let url = `${this.lb4Url}/businesses/${busId}/business-billings`;
    return this._httpClient.patch<any>(url, body).toPromise();
  }

  getBusinessBilling(busId) {
    let url = `${this.lb4Url}/businesses/${busId}/business-billings`;
    return this._httpClient.get<any>(url);
  }

  getBusinessUsersById(busId) {
    let url = `${this.lb4Url}/businesses/${busId}/users`;
    return this._httpClient.get<any>(url);
  }

  updateBusiness(busId, body) {
    let url = `${this.lb4Url}/businesses/${busId}`;
    return this._httpClient.patch<any>(url, body);
  }

  deleteBusiness(busId) {
    let url = `${this.lb4Url}/businesses/${busId}`;
    return this._httpClient.delete<any>(url);
  }

  getSegments() {
    let url = `${this.lb4Url}/segments`;
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError)).toPromise();
  }

  getBusinessByEmail(email) {
    let filter = { "fields": { "email": true }, "where": { "email": email } };
    let url = `${this.lb4Url}/businesses?filter=${JSON.stringify(filter)}`;
    return this._httpClient.get<any>(url).toPromise();
  }

  getBusinessEmails() {
    let url = `${this.lb4Url}/businesses?filter[fields][email]=true`;
    return this._httpClient.get<any>(url).toPromise();
  }

  /** sensors */

  getHubSensors(searchTerm: any, limit: any, skip: any): Promise<any> {
    let filter: any = { skip: skip, order: "id DESC" }
    if (limit) filter.limit = limit;

    if (searchTerm) {
      searchTerm = searchTerm.split(" ").join("_");
      filter.where = {
        or: [
          { "email": { "like": "%" + searchTerm + "%" } },
          { "sensor_serial": { "like": "%" + searchTerm + "%" } },
          { "devicename": { "like": "%" + searchTerm + "%" } },
          { "user_id": { "like": "%" + searchTerm + "%" } }
        ]
      }
    }
    let busRelation = {
      relation: 'business',
      scope: {
        fields: {
          name: true,
          id: true
        }
      }
    };

    filter.include = [busRelation];
    let encodedFilter = encodeURIComponent(JSON.stringify(filter));
    let url = `${this.lb4Url}/hubsensors?filter=${encodedFilter}`

    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError)).toPromise();
  }

  getHubSensorsCount(searchTerm): Observable<any[]> {
    let url = '';
    var query: any;

    if (searchTerm) {
      // The square bracket notation (for count) does not support multiple query conditions.
      query = {
        "or": [
          { "email": { "like": "%" + searchTerm + "%" } },
          { "sensor_serial": { "like": "%" + searchTerm + "%" } },
          { "devicename": { "like": "%" + searchTerm + "%" } },
          { "user_id": { "like": "%" + searchTerm + "%" } }
        ]
      };
      let qe = encodeURI(JSON.stringify(query));
      url = `${this.lb4Url}/hubsensors/count?where=${qe}`;
    } else {
      url = `${this.lb4Url}/hubsensors/count`;
    }
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError));
  }

  getSensorSubscriptions(id: string): Observable<any> {
    let url = `${this.lb4Url}/hubsensors/${id}/subscription`;
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError));
  }

  updateSensor(id, name, desc) {
    let body = { devicename: name, devicedesc: desc };
    let url = `${this.lb4Url}/hubsensors/${id}`;
    return this._httpClient.patch<any>(url, body).pipe(catchError(this.handleError)).toPromise();
  }

  getSensor(id: string, serial?: string): Observable<any> {
    let url = `${this.lb4Url}/hubsensors/${id}`;

    if (serial) {
      let encodedFilter = encodeURIComponent(JSON.stringify({
        where: { sensor_serial: serial }
      }));

      url = `${this.lb4Url}/hubsensors?filter=${encodedFilter}`;
    }

    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError));
  }

  deleteSensor(id) {
    let url = `${this.lb4Url}/hubsensors/${id}`;
    return this._httpClient.delete<any>(url).pipe(catchError(this.handleError)).toPromise();
  }

  resetSensorTC(id) {
    let url = `${this.lb4Url}/hubsensors/${id}`;
    let body = { lifetime_tc: 0 };
    return this._httpClient.patch<any>(url, body).pipe(catchError(this.handleError));
  }

  getSensorHistoryCount(searchTerm: string): Promise<any[]> {
    let url = '';
    var query: any;

    if (searchTerm) {
      // The square bracket notation (for count) does not support multiple query conditions.
      query = {
        "or": [
          { "user_id": { "like": "%" + searchTerm + "%" } },
          { "device_id": { "like": "%" + searchTerm + "%" } },
          { "body": { "like": "%" + searchTerm + "%" } }
        ]
      };
      let qe = encodeURI(JSON.stringify(query));
      url = `${this.lb4Url}/sensor-histories/count?where=${qe}`;
    } else {
      url = `${this.lb4Url}/sensor-histories/count`;
    }
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError)).toPromise();
  }

  getSensorHistory(searchTerm, limit, skip): Promise<any[]> {
    let url = `${this.lb4Url}/sensor-histories?filter[limit]=${limit}&filter[skip]=${skip}` +
      `&filter[where][or][0][user_id][like]=%${searchTerm}%` +
      `&filter[where][or][1][device_id][like]=%${searchTerm}%` +
      `&filter[where][or][2][body][like]=%${searchTerm}%` +
      `&filter[order]=id DESC`;
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError)).toPromise();
  }


  /** device serial master */

  getServiceTag(serviceTag) {
    let url = `${this.lb4Url}/deviceserialmaster?filter[where][service_tag][like]=%${serviceTag}%`;
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError));
  }

  addDeviceSerialMaster(serial, imei, iccid, status, state) {
    let body = {
      device_serial: serial,
      unit_imei: imei,
      unit_iccid: iccid,
      status: status,
      state: state,
    }
    let url = `${this.lb4Url}/deviceserialmaster`;
    return this._httpClient.post<any>(url, body).pipe(catchError(this.handleError));
  }

  getDeviceSerialMaster(searchTerm: string, limit: number, skip = 0): Promise<any[]> {
    let url = `${this.lb4Url}/deviceserialmaster?`;
    if (searchTerm && limit) {
      url += `filter[limit]=${limit}&filter[skip]=${skip}` +
        `&filter[where][device_serial][like]=%${searchTerm}%` +
        `&filter[order]=id DESC`
    } else if (searchTerm) {
      url += `filter[where][device_serial][like]=%${searchTerm}%` +
        `&filter[order]=id DESC`
    } else if (limit) {
      url += `filter[limit]=${limit}&filter[skip]=${skip}` +
        `&filter[order]=id DESC`
    }
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError)).toPromise();
  }

  getDeviceSerialMasterCount(searchTerm: string): Promise<any[]> {
    let url = `${this.lb4Url}/deviceserialmaster/count`;
    var query: any;

    if (searchTerm) {
      // The square bracket notation (for count) does not support multiple query conditions.
      query = {
        "or": [{ "device_serial": { "like": "%" + searchTerm + "%" } }]
      };
      let qe = encodeURI(JSON.stringify(query));
      url += `?where=${qe}`;
    }
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError)).toPromise();
  }

  getAvailableDevices(prefix?: string): Promise<string[]> {
    let url = `${this.lb4Url}/deviceserialmaster/available`;
    if (prefix) url += `?prefix=${prefix.toUpperCase()}`;

    return this._httpClient.get<string[]>(url).pipe(catchError(this.handleError)).toPromise();
  }

  /** Sensor Serial Master */

  getSensorSerialMaster(searchTerm: string, limit: number, skip = 0): Promise<any[]> {
    let url = `${this.lb4Url}/sensorserialmaster`;
    if (searchTerm && limit) {
      url += `?filter[limit]=${limit}&filter[skip]=${skip}` +
        `&filter[where][sensor_serial][like]=%${searchTerm}%` +
        `&filter[order]=id DESC`
    } else if (searchTerm) {
      url += `?filter[where][sensor_serial][like]=%${searchTerm}%` +
        `&filter[order]=id DESC`
    } else if (limit) {
      url += `?filter[limit]=${limit}&filter[skip]=${skip}&filter[order]=id DESC`
    }
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError)).toPromise();
  }

  getSensorSerialMasterCount(searchTerm: string): Promise<any[]> {
    let url = `${this.lb4Url}/sensorserialmaster/count`;
    var query: any;

    if (searchTerm) {
      // The square bracket notation (for count) does not support multiple query conditions.
      query = {
        "or": [{ "sensor_serial": { "like": "%" + searchTerm + "%" } }]
      };
      let qe = encodeURI(JSON.stringify(query));
      url += `?where=${qe}`;
    }
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError)).toPromise();
  }

  getAvailableSensors(prefix?: string): Promise<string[]> {
    let url = `${this.lb4Url}/sensorserialmaster/available`;
    if (prefix) url += `?prefix=${prefix.toUpperCase()}`;

    return this._httpClient.get<string[]>(url).pipe(catchError(this.handleError)).toPromise();
  }

  addSeviceSerialMaster(body) {
    let url = `${this.lb4Url}/sensorserialmaster`;
    return this._httpClient.post<any>(url, body).pipe(catchError(this.handleError)).toPromise();
  }

  swapSensor(id: string, new_serial: string) {
    let params = `new_serial=${new_serial}`
    let url = `${this.lb4Url}/hubsensors/${id}/swap-sensor?${params}`;
    return this._httpClient.post<any>(url, null);
  }
  /** Unit Command */

  getUnitCommand(searchTerm: string, limit: number, skip = 0): Promise<any[]> {
    let url = `${this.lb4Url}/unit-command`;
    if (searchTerm && limit) {
      url += `?filter[limit]=${limit}&filter[skip]=${skip}` +
        `&filter[where][or][0][device_serial][like]=%${searchTerm}%` +
        `&filter[where][or][1][hub_serial][like]=%${searchTerm}%` +
        `&filter[order]=id DESC`
    } else if (searchTerm) {
      url += `&filter[where][or][0][device_serial][like]=%${searchTerm}%` +
        `&filter[where][or][0][hub_serial][like]=%${searchTerm}%` +
        `&filter[order]=id DESC`
    } else if (limit) {
      url += `?filter[limit]=${limit}&filter[skip]=${skip}&filter[order]=id DESC`
    }
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError)).toPromise();
  }

  getUnitCommandCount(searchTerm: string): Promise<any[]> {
    let url = `${this.lb4Url}/unit-command/count`;
    var query: any;

    if (searchTerm) {
      // The square bracket notation (for count) does not support multiple query conditions.
      query = {
        "or": [
          { "device_serial": { "like": "%" + searchTerm + "%" } },
          { "hub_serial": { "like": "%" + searchTerm + "%" } }
        ]
      };
      let qe = encodeURI(JSON.stringify(query));
      url += `?where=${qe}`;
    }
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError)).toPromise();
  }

  getPlans(serial: string): Promise<any[]> {
    let url = `${this.lb4Url}/subscriptions/listplans?device_serial=${serial}`;
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError)).toPromise();
  }

  createSubscriptions(user_id: string, subs: any) {
    let url = `${this.lb4Url}/users/${user_id}/subscribe-devices`;
    return this._httpClient.post<any[]>(url, subs, { observe: 'response' }).toPromise();
  }

  getConnectionLogs(device_id: string, start_date?: string, stop_date?: string) {
    let url = `${this.lb4Url}/devices/${device_id}/connection-logs`;

    if (start_date) url += `?startDate=${start_date}`;
    if (stop_date) url += `${(start_date ? '&' : '?')}stopDate=${stop_date}`;

    return this._httpClient.get(url, { observe: 'response' }).toPromise();
  }

  vzwActivate(id: string): Observable<any> {
    let url = `${this.lb4Url}/devices/${id}/activate`;
    return this._httpClient.post<any>(url, null);
  }

  vzwDeactivate(id: string): Observable<any> {
    let url = `${this.lb4Url}/devices/${id}/deactivate`;
    return this._httpClient.post<any>(url, null);
  }

  vzwDelete(id: string): Observable<any> {
    let url = `${this.lb4Url}/devices/${id}/vzw-delete`;
    return this._httpClient.post<any>(url, null);
  }

  private handleError(e: HttpErrorResponse) {
    if (e.error instanceof ErrorEvent) {
      console.log('Client side error: ', e.error);
    } else {
      console.log('Server side error: ', e.error);
    }
    return throwError(e.error.error.message);
  }
}
