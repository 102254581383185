<div class="modal-header bg-primary" style="border: solid 3px #fff;">
    <h4 class="modal-title text-light">{{ createBus.title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="decline()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body">
    <app-message></app-message>
  
    <form>
      <div class="form-group">
        <label>Business Name<span class="asterisk">*</span></label>
        <input type="text" id="businessName" class="form-control" [modeled-form]="businessForm" required />
      </div>
      <div class="form-group">
        <label for="businessType">Business Type<span class="asterisk">*</span></label>
        <select id="businessType" class="form-control" [modeled-form]="businessForm">
          <option selected>Choose...</option>
          <option *ngFor="let s of segments">{{ s.name }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="email">Business Email<span class="asterisk">*</span></label>
        <input type="email" class="form-control" id="email" [modeled-form]="businessForm" id="email" required />
      </div>
      <div class="form-group">
        <label for="address">Address<span class="asterisk">*</span></label>
        <input type="text" class="form-control" id="address" [modeled-form]="businessForm" required />
      </div>
      <div class="form-group">
        <label for="address2">Address 2 (optional)</label>
        <input type="text" class="form-control" id="address2" [modeled-form]="businessForm" />
      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="city">City<span class="asterisk">*</span></label>
          <input type="text" class="form-control" id="city" [modeled-form]="businessForm" required />
        </div>
        <div class="form-group col-md-3">
          <label for="state">State<span class="asterisk">*</span></label>
          <select id="state" class="form-control" [modeled-form]="businessForm">
            <option selected>Choose...</option>
            <option *ngFor="let state of createBus.states">{{ state }}</option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="zip">Zip<span class="asterisk">*</span></label>
          <input type="text" class="form-control" id="zip" [modeled-form]="businessForm" pattern="^\d{5}([-]|\s*)?(\d{4})?$"
            mask="00000||00000-0000" required />
        </div>
        <div class="form-group col-md-3">
          <label for="phone">Phone<span class="asterisk">*</span></label>
          <input type="text" id="phone" mask='(000) 000-0000||0 (000) 000-0000'
          [modeled-form]="businessForm" class="form-control" required />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button id="next" type="button" class="btn btn-outline-warning" [disabled]="!businessForm.valid" (click)="next()">{{createBus.nextText}}</button>
    <button id="cancel" type="button" class="btn btn-outline-warning" (click)="decline()">Cancel</button>
  </div>