<div class="apex-content content container-fluid">
  <h1>Add Testing Device</h1>
  <div class="alert alert-danger" role="alert">
    <h3>This functionality should <b>only be used on development/testing</b> servers.</h3>
  </div>
  
  <app-message></app-message>

  <div class="content-wrapper">
    <div class="content-body">
      <form [formGroup]="updateForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label>Device Serial</label>
          <input formControlName="deviceSerial" type="text" style="text-transform: uppercase;" class="form-control" required>
        </div>
        <div class="form-group">
          <label>imei</label>
          <input formControlName="imei" type="text" class="form-control" required>
        </div>
        <div class="form-group">
          <label>iccid</label>
          <input formControlName="iccid" type="text" class="form-control" required>
        </div>
        <div class="form-group">
          <label>status</label>
          <input formControlName="status" type="text" class="form-control" required>
        </div>
        <div class="form-group">
          <label>state</label>
          <input formControlName="state" type="text" class="form-control" required>
        </div>

        <button class="btn btn-outline-warning" (click)="onSubmit()" [disabled]="updateForm.invalid" type="button">Add
          Device
        </button>

      </form>
    </div>
  </div>
</div>