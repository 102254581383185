import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { TokenInterceptor } from './shared/auth/auth-token.interceptor';
import { SorterService } from './services/sorter.service';

// Loader Interceptor
import { HTTPListener, HTTPStatus } from './shared/loader/interceptor';
const RxJS_Services = [HTTPListener, HTTPStatus];

import * as $ from 'jquery';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ConfirmationDialogComponent } from './shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './shared/dialog/confirmation-dialog/confirmation-dialog.service';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';

function jwtOptionsFactory(authService: AuthService): any {
    return {
      tokenGetter: () => {
        return authService.token;
      },
    };
  }

@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        ConfirmationDialogComponent
    ],
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        NgxChartsModule,
        AppRoutingModule, // This had to be positioned after ngx to get hash tag to work.
        NgbModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
              provide: JWT_OPTIONS,
              useFactory: jwtOptionsFactory,
              deps: [AuthService],
            },
          }),
        SharedModule
    ],
    providers: [
        AuthService,
        SorterService,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        RxJS_Services,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HTTPListener,
          multi: true
        },
        ConfirmationDialogService
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
