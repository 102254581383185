import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../shared/dialog/confirmation-dialog/confirmation-dialog.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Animations } from 'app/shared/animations/animations';
import { HttpClientService } from 'app/http-client.service';
import { MessageService } from 'app/shared/components/message/message.service';
import { Alert } from '../../shared/components/message/message.alert.enum';
import { UnitSwapDialog } from 'app/shared/dialog/unit-swap-selector/unit-swap-selector';
import { AuthService } from 'app/shared/auth/auth.service';

@Component({
  selector: 'app-device-edit',
  templateUrl: './device-edit.component.html',
  styleUrls: ['./device-edit.component.scss'],
  animations: [Animations.openClose]
})

export class DeviceEditComponent implements OnInit {

  pId: string = null;
  pSerial: string = null;
  pSearchTerm: string = null;
  pCurrentPage: number = null;
  pPageDensity: number = null;
  device;
  dataLoaded = false;
  dataToggle = false;
  isOpen = false;
  dsm = false;
  
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _confirmationDialogService: ConfirmationDialogService,
    private _fb: FormBuilder,
    private _httpClientService: HttpClientService,
    private _messageService: MessageService,
    private _swapDialog: UnitSwapDialog,
    public auth: AuthService
  ) { }

  // Form Setup
  updateForm = this._fb.group({
    deviceName: ['', [Validators.required, Validators.minLength(2)]],
    deviceDescription: ['', Validators.required]
  });
  get deviceName() { return this.updateForm.get('deviceName'); }
  get deviceDescription() { return this.updateForm.get('deviceDescription'); }
  // End Form Setup

  ngOnInit() {
    let params = this._activatedRoute.snapshot.params;

    if (params.id.length === 12) this.pSerial = params.id;
    else this.pId = params.id;

    this.pSearchTerm = params.st;
    this.pCurrentPage = +params.cp;
    this.pPageDensity = +params.pd;

    // save params for going back to device list
    if (!this.pSerial) sessionStorage.setItem("devParams", JSON.stringify(params));
    
    this.getDevice();
  }

  onUpdate() {
    let name = this.updateForm.get('deviceName').value;
    let desc = this.updateForm.get('deviceDescription').value;
    let id = this.pId;
    let title = `Please Confirm Update`;
    let message = `Are you sure you want to update the device with the following id: ${this.pId}?`;
    this._confirmationDialogService.confirm(title, message, 'Update', 'Cancel', 'warning')
      .then((confirmed) => {
        if (confirmed === true) {
          this._httpClientService.updateDevice(id, name, desc).subscribe((res) => {
            this._messageService.setMessage(Alert.SUCCESS, "Device updated");
            this.dataToggle = !this.dataToggle; // this is used for force an update on the child component
            
            this.getDevice(); // refresh device variable
          }, (err) => {
            console.log(err);
            this._messageService.setMessage(Alert.DANGER, err);
          });
        }
      })
      .catch((error) => {
        this._messageService.setMessage(Alert.DANGER, 'An error was detected...');
        console.log(error);
      }
    );
  }

  onDelete() {
    let title = `Please Confirm Deletion`;
    let message = `Are you sure you want to delete the device with the following Id: ${this.pId}?`;
    let optionTxt = "Delete deviceSerialMaster records"
    this._confirmationDialogService.confirm(title, message, 'Delete', 'Cancel', 'danger', null, optionTxt)
      .then((confirmed) => {
        if (confirmed === true) {
          this.dsm = this._confirmationDialogService.option;
          this._httpClientService.deleteDevice(this.device.id, this.dsm).subscribe((res) => {
            this._messageService.setMessage(Alert.SUCCESS, "Device deleted");
            
            alert('You will be redirected back to the device list.');
            this._router.navigate(['/device/list']);
          }, (err) => {
            console.log(err);
            this._messageService.setMessage(Alert.DANGER, err);
          });
        }
      })
      .catch((error: ErrorEvent) => {
        console.log(error);
      }
    );
  }

  private getDevice() {
    this._httpClientService.getDevice(this.pId, this.pSerial).subscribe((res) => {
      this.device = (res[0] || res);
      this.dataLoaded = true;
      this.loadFormFields();
    }, (err) => {
      this._messageService.setMessage(Alert.DANGER, err);
    });
  }

  private loadFormFields() {
    this.updateForm.patchValue({
      deviceName: this.device.devicename,
      deviceDescription: this.device.devicedesc
    });
  }

  async onSwap() {
    let rtn: any = await this._swapDialog.show(this.device.device_serial.substring(0, 3));
    if (!rtn?.new_serial) return this._messageService.setMessage(Alert.DANGER, 'Device Swap Canceled');;
    
    let confirm = await this._confirmationDialogService.confirm('Device Swap', `Are you sure you want to swap: ${this.device.device_serial} for: ${rtn.new_serial}?`, 'Yes').catch((error) => {
      this._messageService.setMessage(Alert.DANGER, 'Device Swap failed');
      console.log(error);
    });

    if (!confirm) return;

    this._httpClientService.swapSubscriptionDevice(this.device.id, rtn.new_serial, rtn.req_testing).subscribe((res) => {
      this._messageService.setMessage(Alert.SUCCESS, `Device: ${this.device.device_serial} has been swapped for: ${rtn.new_serial}`);
    }, (e: ErrorEvent) => {
      this._messageService.setMessage(Alert.DANGER, e.error.error.message);
    });
  }
}
