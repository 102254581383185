import { Component, OnInit } from '@angular/core';
import { ConfirmationDialogService } from '../../shared/dialog/confirmation-dialog/confirmation-dialog.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Animations } from 'app/shared/animations/animations';
import { HttpClientService } from 'app/http-client.service';
import { MessageService } from 'app/shared/components/message/message.service'
import { Alert } from '../../shared/components/message/message.alert.enum';

@Component({
  selector: 'app-device-add',
  templateUrl: './device-add.component.html',
  animations: [Animations.openClose]
})

export class DeviceAddComponent implements OnInit {

  public pId: number = null;
  public device;
  public dataLoaded = false;
  public dataToggle = false;
  isOpen = false;

  constructor(
    private _httpClientService: HttpClientService,
    private _confirmationDialogService: ConfirmationDialogService,
    private _fb: FormBuilder,
    private _messageService: MessageService
  ) { }

  // Form Setup
  updateForm = this._fb.group({
    deviceSerial: ['', Validators.required],
    imei: ['', Validators.required],
    iccid: ['', Validators.required],
    status: ['ready', Validators.required],
    state: ['deactivated', Validators.required],
  });
  // End Form Setup

  ngOnInit() { }

  onSubmit() {
    let serial = this.updateForm.get('deviceSerial').value;
    let imei = this.updateForm.get('imei').value;
    let iccid = this.updateForm.get('iccid').value;
    let status = this.updateForm.get('status').value;
    let state = this.updateForm.get('state').value;

    let title = `Please Confirm Creation`;
    let message = `Are you sure you want to add this device?`;

    this._confirmationDialogService.confirm(title, message, 'Add', 'Cancel', 'warning').then((confirmed) => {
      if (confirmed === true) {
        let upperCaseSerial = serial.toUpperCase();
        this._httpClientService.addDeviceSerialMaster(upperCaseSerial, imei, iccid, status, state).subscribe(res => {
          this._messageService.setMessage(Alert.SUCCESS, "Device added to deviceseralmaster table");
        }, (err) => {
          console.error(err);
          this._messageService.setMessage(Alert.DANGER, err);
        });
      }}).catch((error) => {
        this._messageService.setMessage(Alert.DANGER, 'An error was detected...');
        console.error(error);
      });
  }
}
