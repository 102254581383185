import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FotaService } from '../../fota.service';
import { environment } from 'environments/environment'
import { AbstractControl, FormGroup } from '@angular/forms';
import { MessageService } from 'app/shared/components/message/message.service'
import { HttpClientService } from 'app/http-client.service';
import { ConfirmationDialogService } from 'app/shared/dialog/confirmation-dialog/confirmation-dialog.service';
import { DeviceUpdateListService } from 'app/shared/components/device-update-list/device-update-list.service';
import { SearchService } from 'app/shared/components/search/search.service';
import { Alert } from '../../../../shared/components/message/message.alert.enum';

@Component({
  selector: 'app-device-update',
  templateUrl: './device-update.component.html',
  styleUrls: ['./device-update.component.scss']
})
export class DeviceUpdateComponent implements OnInit {

  private param_device_serial: string = null;
  private param_firmware: string = null;
  private forceUpdate = false;

  selectedDevices: any[];
  submitted = false;
  currentFirmArray = [];
  targetFirm: any[];
  param_devices = [];
  model: string;
  targetFirmArray: any = []; 
  selection: AbstractControl;
  targetForm: FormGroup;
  searchForm: FormGroup;
  manageForm: FormGroup;
  currentFirmware: AbstractControl ;
  searchTerm: AbstractControl;
  placeholder = 'Filter by serial';

  // device lists
  shb: any[];
  shp: any[];
  shh: any[];
  
  shbSelected = [];
  shpSelected = [];
  shhSelected = [];

  constructor(
    private _fotaService: FotaService,
    private _httpClientService: HttpClientService,
    private _route: ActivatedRoute,
    private _messageService: MessageService,
    private _confirmationDialogService: ConfirmationDialogService,
    private _deviceUpdateService: DeviceUpdateListService,
    private _searchService: SearchService
  ) { }

  ngOnInit() {
    this.targetForm = this._deviceUpdateService.targetForm;
    this.manageForm = this._deviceUpdateService.manageForm;
    this.searchForm = this._searchService.searchForm;
    this.selection = this._deviceUpdateService.targetForm.controls.selection;
    this.currentFirmware = this.manageForm.controls.currentFirmware;
    this.searchTerm = this.searchForm.controls.searchTerm;
    this.submitted = false;
    this.selectedDevices = [];
    this.searchTerm.setValue('');
    this.getTargetFirmware();
    this.getDeployedFirmwareFiles();

    // Get firmware and serial in the URL
    this._route.queryParamMap.subscribe((paramMap: any) => {
      if (paramMap.params.devices) {
        this.param_devices = JSON.parse(paramMap.params.devices);
      }
      this.param_device_serial = paramMap.params.device_serial;
      this.param_firmware = paramMap.params.firmware;
      console.log(this.param_firmware);
      if (this.param_firmware) { 
        this.currentFirmware.setValue(this.param_firmware);
        this.onChangeCurrentFirmware();
      }
      if (this.param_device_serial) {
        this.param_devices = [];
        this.param_devices.push({ device_serial: this.param_device_serial });
      }
      this.getParamDevices()
    });
  }

  private getParamDevices() {
    if (this.param_devices.length > 0) {
      let query = '';
      this.param_devices.forEach((ele, i) => {
        query += `&filter[where][or][${i}][device_serial]=${ele.device_serial}`;
      });
      this._httpClientService.getParamDevices(query).subscribe(res => {
        this.param_devices = res;
        this.onChangeCurrentFirmware(); // update device list
      });
    }
  }

  // Get the devices associated with the firmware.
  getDevicesByFirmware() {
    if (this.param_devices.length > 0) {
      let res = this.param_devices.filter(((dev) => dev.device_serial.includes(this.searchTerm.value.toUpperCase())));
      this.setDeviceData(res);
    } else {
      this._fotaService.getDevicesByFirmware(this.currentFirmware.value, this.searchTerm.value).subscribe((data) => {
        this.setDeviceData(data);
      }, (error) => {
        this._messageService.setMessage(Alert.DANGER, error);
      });
    }
  }

  reload() {
    location.reload();
  }

  onSubmit() {
    if (this.isErrorFree()) {
      let message = "Are you sure you want to update the selected device(s) to the selected firmware versions?";
      if (confirm(message)) {  
        this.forcedUpdate();
        var selectedDevices;
        
        for (let ele of this.targetFirmArray) {
          switch(ele.model) {
            case 'SHB': selectedDevices = this.shbSelected; break;
            case 'SHP': selectedDevices = this.shpSelected; break;
            case 'SHH': selectedDevices = this.shhSelected; break;
          }
          this.triggerDeviceUpdate(ele.value, selectedDevices);
        }
      }
    } else {
      this.submitted = false;
    }
  }

  private triggerDeviceUpdate(firmwareValue, selectedDevices) {
    this._fotaService.triggerDeviceUpdate(firmwareValue, selectedDevices).subscribe((res) => {
      this.submitted = true;
    }, (err) => {
      this._messageService.setMessage(Alert.DANGER, "Trigger device update failed");
      console.log(err);
    });
  }

  onChangeCurrentFirmware() {
    if (this.param_device_serial) {
      this.searchTerm.setValue(this.param_device_serial);
    }
    if (this.currentFirmware.value === 'user') {
      this.selection.setValue(true);
      this.setDeviceData(this.param_devices);
      this.selectedDevices = this.param_devices.concat();
      this.selectAllDevices();
    } else if (this.currentFirmware.value === 'all') {
      this.getAllDevices();
      this.selection.setValue(false);
      this.clearSelectedDevices();
    } else {
      this.selection.setValue(false);
      this.clearSelectedDevices();
      this.getDevicesByFirmware();
    }
  }

  selectDevice(obj, arr) {
    let dev = obj.device;

    // if device is selected, add to array. if unselected, remove it
    if (this.selection.value) {
      arr.push({ id: dev.id, device_serial: dev.device_serial });
    } else {
      arr.forEach((ele: { id: any; }, i: any) => {
        if (ele.id === dev.id) {
          arr.splice(i, 1);
        }
      });
    }

    this.setSelectedDevices(obj, arr);
  }

  setForceUpdate() {
    let title = 'Force Update';
    let message = "Are you sure you want to force update?";
    let ele = (<HTMLInputElement>document.getElementById('force-update-checkbox') as HTMLInputElement);
    ele.checked = false
    if (this.forceUpdate === false) {
      this._confirmationDialogService.confirm(title, message, 'Yes', 'Cancel').then((confirmed) => {
        if (confirmed) {
          this._confirmationDialogService.confirm(title, `Are you sure that you're sure?`, 'Yes', 'Cancel').then((confirmed) => {
            if (confirmed) {
              this.forceUpdate = true;
              ele.checked = true;
            } else {
              this.forceUpdate = false;
            }
          });
        } else {
          this.forceUpdate = false;
        }
      });
    } else {
      this.forceUpdate = false;
    }
  }

  onSetTargetFirm(obj) {
    if (obj.value !== "") {
      this.targetFirmArray.push(obj);
    } else {
      this.removeCurrentObj(obj);
    }

    // filter out duplicates
    this.targetFirmArray = [...new Map(this.targetFirmArray.map(item => [item['model'], item])).values()];
  }
  
  invalid() { 
    let values = [];
    if (this.selectedDevices.length === 0) {
      return true;
    }
    if (this.shbSelected.length > 0) {
      values.push(this.targetForm.controls.shbTargetFirm.invalid);
    } 
    if (this.shpSelected.length > 0) {
      values.push(this.targetForm.controls.shpTargetFirm.invalid);
    }
    if (this.shhSelected.length > 0) {
      values.push(this.targetForm.controls.shhTargetFirm.invalid);
    }
    return (values.includes(true))
  }
  
  /** Private Section */

  private selectAllDevices() {
    this.shbSelected = this.shb.concat();
    this.shpSelected = this.shp.concat();
    this.shhSelected = this.shh.concat();
  }
  
  private removeCurrentObj(obj: any) {
    this.targetFirmArray.forEach((ele, i) => {
      if (ele.model === obj.model) {
        this.targetFirmArray.splice(i, 1);
      }
    })
  } 

  private setSelectedDevices(obj: any, arr: any[]) {
    switch (obj.model) {
      case 'SHB': this.shbSelected = arr; break;
      case 'SHP': this.shpSelected = arr; break;
      case 'SHH': this.shhSelected = arr; break;
    }
    this.selectedDevices = this.shbSelected.concat(this.shpSelected.concat(this.shhSelected.concat()));
  }

  private clearSelectedDevices() {
    this.shbSelected = [];
    this.shpSelected = [];
    this.shhSelected = [];
    this.selectedDevices = [];
  }

  private setDeviceData(arr) {
    this.shb = this.filter('SHB', arr);
    this.shp = this.filter('SHP', arr);
    this.shh = this.filter('SHH', arr);
  }

  private filter(model: any, arr) {
    let filteredDevices = arr.filter(dev => dev.device_serial.includes(model));

    filteredDevices.sort((a, b) => {
      if (a.device_serial > b.device_serial) {
        return 1;
      } else if (a.device_serial < b.device_serial) {
        return -1;
      } else {
        return 0;
      }
    });
    return filteredDevices;
  }

  private forcedUpdate() {
    if (this.forceUpdate === true) {
      let query = '';
      for (let i = 0; i < this.selectedDevices.length; i++) {
        query += `&[where][or][${i}][device_serial]=${this.selectedDevices[i].device_serial}`;
      }
      this._httpClientService.deviceForceUpdate(query, 1).subscribe(res => {
      }, (err) => {
        this._messageService.setMessage(Alert.DANGER, "Force request failed");
        console.log(err);
      });
    }
  }

  private getTargetFirmware() {
    this._fotaService.getTargetFirmware().subscribe((data) => {
  
      this.targetFirm = data;
      this._deviceUpdateService.targetFirmArrayRef = this.targetFirm.concat();
      
      if (this.param_devices !== null) {
        if (this.param_devices.length > 0) {
          this.currentFirmware.setValue("user")
          this.onChangeCurrentFirmware();
        }
      }
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getDeployedFirmwareFiles() {
    this._fotaService.getDeployedFirmware().subscribe((data) => {
      this.currentFirmArray = data;
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getAllDevices() {
    this._fotaService.getDevices().subscribe((res) => {
      this.setDeviceData(res);

      if (this.param_firmware && this.param_firmware !== '') {
        this.currentFirmware.setValue(this.param_firmware);
        this.searchTerm.setValue(this.param_device_serial);
        this.getDevicesByFirmware();
      }
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
      if (environment.DEBUG) { console.log(error); }
    });
  }

  private isErrorFree() {
    if (environment.DEBUG) { console.log('in device.component.isErrorFree ...'); }
    let returnVal = false;

    if (this.selectedDevices.length === 0) {
      this._messageService.setMessage(Alert.DANGER, "You must select at least one device");
    }
    else if (this.currentFirmware.value === this.targetFirmArray.value) {
      this._messageService.setMessage(Alert.DANGER, "You must select differing firmware packages");
    }
    else if (this.currentFirmware.value === null || this.targetFirmArray.value === null) {
      this._messageService.setMessage(Alert.DANGER, "You must select the firmware package(s)");
    }
    else {
      returnVal = true;
    }
    return returnVal;
  }
}
