import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'app/shared/components/message/message.service';
import { Alert } from 'app/shared/components/message/message.alert.enum';
import { ModeledForm, ModeledControl } from 'app/shared/modeled-form/modeled-form';
import { CreateBusinessService } from '../../services/create-business.service';

@Component({
  selector: 'app-custom-pricing',
  templateUrl: './custom-pricing.component.html',
  styleUrls: ['./custom-pricing.component.scss']
})
export class CustomPricingComponent {
  private checkForm: boolean = false;

  showPass: boolean = false;
  showConfirmPass: boolean = false;
  customPricing: boolean = true;

  get formValid(): boolean {
    if (!this.customPricing || !this.checkForm) return true;
    return this.customPricingForm?.valid;
  }

  constructor(
    private _activeModal: NgbActiveModal,
    private _messageService: MessageService,
    public createBus: CreateBusinessService
  ) { }

  customPricingForm = new ModeledForm({
    hub: new ModeledControl({
      objectRef: { propertyKey: 'hub', holdingObject: this.createBus.customPricing },
      initialValue: (this.createBus.customPricing.hub || '00.00'),
      debounceMs: 500
    }),
    sensors: new ModeledControl({
      objectRef: { propertyKey: 'sensors', holdingObject: this.createBus.customPricing },
      initialValue: (this.createBus.customPricing.sensors || '00.00'),
      debounceMs: 500
    }),
    kiwiir: new ModeledControl({
      objectRef: { propertyKey: 'kiwiir', holdingObject: this.createBus.customPricing },
      initialValue: (this.createBus.customPricing.kiwiir || '00.00'),
      debounceMs: 500
    }),
    kiwi: new ModeledControl({
      objectRef: { propertyKey: 'kiwi', holdingObject: this.createBus.customPricing },
      initialValue: (this.createBus.customPricing.kiwi || '00.00'),
      debounceMs: 500
    })
  }, [
    (() => {
      let valid = Object.values(this.customPricingForm.controls).find(e => (e.value !== '0.00' && e.value !== '00.00'));

      if (valid) this._messageService.dismissMessage();
      else this._messageService.setMessage(Alert.DANGER, "At least one price must be greater than 0", undefined, 'create-bus');

      return {
        hub: (valid ? null : 'failed'),
        sensors: (valid ? null : 'failed'),
        kiwiir: (valid ? null : 'failed'),
        kiwi: (valid ? null : 'failed')
      };
    }).bind(this)
  ]);

  public toggleCustomPricing() {
    if (!this.customPricing && !this.checkForm) {
      setTimeout(() => {
        this.checkForm = true;
      }, 50);
    }
 
    this.customPricing = !this.customPricing;
    this._messageService.dismissMessage();
  }

  public decline() {
    this._activeModal.close(false);
  }

  public next() {
    this.customPricingForm.submit();
    this._activeModal.close(true);
  }

  public evalForm() {
    this.customPricingForm.evaluate();
  }
}
