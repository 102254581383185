import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientService } from 'app/http-client.service';
import { MessageService } from 'app/shared/components/message/message.service';
import { Alert } from 'app/shared/components/message/message.alert.enum';
import { CreateBusinessService } from '../../services/create-business.service';
import { ModeledForm, ModeledControl, ValidationTypes } from 'app/shared/modeled-form/modeled-form';

@Component({
  selector: 'app-account-owner',
  templateUrl: './business-owner.component.html',
  styleUrls: ['./business-owner.component.scss']
})
export class BusinessOwnerComponent {
  users: any[] = [];
  showPass = false;
  showConfirmPass = false;

  get setBusOwner() {
    return (this.createBus?.title?.indexOf('Owner') !== -1);
  }

  //Get the user object being assigned (businessOwner | businessBillingUser)
  get userObject() {
    return (this.setBusOwner ? this.createBus.businessOwner : this.createBus.businessBillingUser);
  }

  get existingUser(): boolean {
    return  (this.userForm.controls.existingUser.value as boolean);
  }
  
  get email() {
    return (this.userForm.controls.email.value as string);
  }
  set email(val: string) {
    this.userForm.controls.email.value = val;
  }
  emailFound = false;
  constructor(
    private _activeModal: NgbActiveModal,
    private _messageService: MessageService,
    private _httpClientService: HttpClientService,
    public createBus: CreateBusinessService
  ) { }

  userForm = new ModeledForm({
    firstName: new ModeledControl({
      objectRef: { propertyKey: 'first_name', holdingObject: this.userObject },
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.minLength, data: 2 }
      ],
      displayErrors: true,
      debounceMs: 500
    }),
    lastName: new ModeledControl({
      objectRef: { propertyKey: 'last_name', holdingObject: this.userObject },
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.minLength, data: 2 }
      ],
      displayErrors: true,
      debounceMs: 500
    }),
    email: new ModeledControl({
      objectRef: { propertyKey: 'email', holdingObject: this.userObject },
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.email },
        { type: ValidationTypes.match, data: ['confirmEmail'] }
      ],
      displayErrors: true,
      debounceMs: 500,
      onEvaluate: ((value: string, valid: boolean) => {
        if (!this.existingUser) return;
        this.emailFound = false;

        //If existingUser, query the DB for user emails containing supplied email value
        this._httpClientService.getUsersByEmail(value).then((res) => {
          let exact = res.find((user: any, ind: number) => {
            if (user.email === value) {
              this.emailFound = true;
              res.splice(ind, 1);
              return true;
            }
          });

          //If the entered email isn't valid or an exact exact match,
          //  or, there are still results from the query.
          //  display the return emails for the user to select from 
          if (!valid || !exact || res.length) this.users = res;
          else this.users = [];

          if (exact) this.userObject.userId = exact.id;
        }, (error) => {
          this._messageService.setMessage(Alert.DANGER, error);
        });
      }).bind(this)
    }),
    confirmEmail: new ModeledControl({
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.email },
        { type: ValidationTypes.match, data: ['email'] }
      ],
      displayErrors: true,
      debounceMs: 500
    }),
    password: new ModeledControl({
      objectRef: { propertyKey: 'password', holdingObject: this.userObject },
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.match, data: ['confirmPassword'] }
      ],
      displayErrors: true,
      debounceMs: 500
    }),
    confirmPassword: new ModeledControl({
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.match, data: ['password'] }
      ],
      displayErrors: true,
      debounceMs: 500
    }),
    phone: new ModeledControl({
      objectRef: { propertyKey: 'phone', holdingObject: this.userObject },
      validators: [
        { type: ValidationTypes.required },
        { type: ValidationTypes.minLength, data: 14 },
        { type: ValidationTypes.maxLength, data: 16 }
      ],
      displayErrors: true,
      debounceMs: 500
    }),
    existingUser: new ModeledControl({
      objectRef: { propertyKey: 'existingUser', holdingObject: this.userObject }
    }),
    fullAdminRights: new ModeledControl({
      objectRef: { propertyKey: 'fullAdminRights', holdingObject: this.userObject }
    })
  });

  reset() {
    this.userForm.controls.email.value = '';
    this.users = [];
  }

  passwordShow() {
    this.showPass = !this.showPass;
  }

  confirmPasswordShow() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  decline() {
    this._activeModal.close(false);
  }

  async next() {
    this.userForm.submit();

    //If the existingUser was not checked off but the email exists in our DB
    //  Set it as an existing user and set the user_id
    let users: any = await this._httpClientService.getUsersByEmail(this.userObject.email);
    if (users.length) {
      this.userObject.existingUser = true;
      this.userObject.userId = users[0].id;
    }
    
    this._activeModal.close(true);
  }

  public evalForm() {
    this.userForm.evaluate();
  }
}
