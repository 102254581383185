import { Injectable } from '@angular/core';
import { HttpClientService } from 'app/http-client.service';
import { AvailableSerials } from 'app/services/available-serials.service';

@Injectable({
    providedIn: 'root'
})
export class UnitSubscriptionDialog {
    private _handler: { resolve: Function, reject: Function };
    private _main: HTMLElement;
    private _serialSelTable: HTMLElement;
    private _selected: {
        [serial: string]: {
            rowElement: HTMLElement,
            termStart?: any,
            subscription?: any
        }
    } = {};
    private _filter: HTMLInputElement;
    private _rows: HTMLElement[] = [];
    private _selectedTable: HTMLElement;
    private _submit: HTMLElement;
    private _mainMouseDown: boolean = false;

    private debounceInd;
    private debounceValue;

    constructor(
        private available: AvailableSerials,
        private http: HttpClientService
    ) { }

    private async addDataRow(data: any[], columns?: boolean) {
        let row = document.createElement('tr');
        row.style.whiteSpace = 'nowrap';
        row.style.borderBottom = '1px solid #d3d7da';

        let ind = 0;
        for (let dp of data) {
            let col = document.createElement(columns ? 'th' : 'td');
            if (ind !== (data.length - 1)) col.style.borderRight = '1px solid #d3d7da';
            col.style.paddingLeft = '5px';
            col.style.paddingRight = '5px';

            if (columns) col.innerText = dp;
            else {
                switch (dp) {
                    case 'sub':
                        let sel = document.createElement('select');
                        sel.style.width = 'min-content';
                        sel.style.minWidth = '100%';

                        let tmp = await this.http.getPlans(data[0]);
                        if (tmp) tmp.map((p: any, idx: number) => {
                            let opt: any = document.createElement('option');
                            if (idx === 0) opt.selected = true;
                            opt.value = p.id;
                            opt.innerText = p.name;

                            sel.append(opt);
                        });

                        this._selected[data[0]].subscription = sel;
                        col.append(sel);
                        break;
                    case 'start':
                        let num = document.createElement('input');
                        num.style.width = '100%';
                        num.style.padding = 'none';
                        num.type = 'number';
                        num.value = '5';

                        this._selected[data[0]].termStart = num;
                        col.append(num);
                        break;
                    case 'del':
                        let i = document.createElement('i');
                        i.className = 'feather ft-delete';
                        i.style.color = 'red';

                        i.onclick = (() => {
                            Object.entries(this._selected).map(([k, v]) => {
                                if (k !== data[0]) return;

                                delete this._selected[k];
                                row.remove();

                                if (Object.keys(this._selected).length === 0) this._submit.setAttribute('disabled', 'true');
                            });

                            this.filterSerials(true);
                        }).bind(this);

                        col.append(i);
                        break;
                    default:
                        col.innerText = dp;
                        break;
                }
            }

            row.append(col);
            ind++;
        }

        this._selectedTable.children[(columns ? 0 : 1)].append(row);
    }

    private addRow(text: string) {
        let row = document.createElement('span');
        row.style.width = '100%';
        row.style.fontSize = 'large';
        row.style.padding = '15px';
        row.style.borderBottom = '1px solid #d3d7da';
        row.style.cursor = 'pointer';
        row.style.whiteSpace = 'nowrap';
        row.className = 'swap-row';
        row.innerText = text;

        if (text !== 'No useable serials found') {
            row.onclick = ((ev: any) => {
                this._rows.map((e: HTMLElement) => e.removeAttribute('selected'));
                this._selected[ev.target.innerText] = { rowElement: ev.target };

                this._submit.removeAttribute('disabled');
                this.addDataRow([ev.target.innerText, 'sub', 'start', 'del']);

                this.filterSerials(true);
            }).bind(this);
        } else {
            row.style.pointerEvents = 'none';
        }

        this._rows.push(row);
        this._serialSelTable.append(row);
    }

    private filterSerials(impediment?: boolean) {
        const doFiltering = (async () => {
            this._rows.map((r: HTMLElement) => r.remove());
            this._rows = [];

            await this.available.searchAll(this._filter.value.toUpperCase());
            this.available.serials.filter((s: any) => !this._selected[s]).map((serial: string) => this.addRow(serial));

            if (!this._rows.length) this.addRow('No useable serials found');
        }).bind(this);

        if (impediment === true) setTimeout(async () => await doFiltering(), 150);
        else {
            if (this.debounceInd) return;

            this.debounceInd = setInterval(async () => {
                if (this.debounceValue !== this._filter.value) {
                    this.debounceValue = this._filter.value;
                    return;
                }

                await doFiltering();

                clearInterval(this.debounceInd);
                this.debounceValue = undefined;
                this.debounceInd = undefined;
            }, 500);
        }
    }

    private async generateDialog() {
        this._main = document.createElement('div');
        this._main.id = 'swap-sensor-main';
        this._main.style.position = 'absolute';
        this._main.style.top = '0';
        this._main.style.left = '0';
        this._main.style.height = '100%';
        this._main.style.width = '100%';
        this._main.style.backgroundColor = 'rgba(73, 73, 73, 0.6)';
        this._main.style.display = 'flex';
        this._main.style.flexDirection = 'column';
        this._main.style.alignItems = 'center';
        this._main.style.zIndex = '10000';
        this._main.style.cursor = 'pointer';
        this._main.style.paddingTop = '10%';
        this._main.onmousedown = ((ev: any) => {
            let elm: any = document.elementFromPoint(ev.pageX, ev.pageY);
            if (elm?.id && elm.id === 'swap-sensor-main') this._mainMouseDown = true;
            else this._mainMouseDown = false;
        }).bind(this);
        this._main.onmouseup = ((ev: any) => {
            let elm: any = document.elementFromPoint(ev.pageX, ev.pageY);
            if (elm?.id && elm.id === 'swap-sensor-main' && this._mainMouseDown) {
                this._selected = {};
                this.finish();
            }
        }).bind(this);

        let panel = document.createElement('div');
        panel.style.height = 'min-content';
        panel.style.width = 'min-content';
        panel.style.maxHeight = '80%';
        panel.style.maxWidth = '80%';
        panel.style.backgroundColor = 'white';
        panel.style.borderRadius = '3px';
        panel.style.padding = '3px';
        panel.style.display = 'flex';
        panel.style.flexDirection = 'column';
        panel.style.cursor = 'default';

        let title = document.createElement('span');
        title.innerText = 'Subscribe New Device(s)';
        title.style.color = 'white';
        title.style.backgroundColor = '#0073AB';
        title.style.fontSize = 'x-large';
        title.style.padding = '20px';
        title.style.height = 'min-content';
        title.style.whiteSpace = 'nowrap';

        let body = document.createElement('div');
        body.style.display = 'flex';
        body.style.flexDirection = 'row';
        body.style.margin = '10px';
        body.style.overflow = 'hidden';

        let serialSelector = document.createElement('div');
        serialSelector.style.display = 'flex';
        serialSelector.style.flexDirection = 'column';
        serialSelector.style.marginRight = '10px';

        let serialList = document.createElement('div');
        serialList.style.display = 'flex';
        serialList.style.flexDirection = 'column';
        serialList.style.border = '1px solid #d3d7da';
        serialList.style.overflow = 'auto';

        this._serialSelTable = document.createElement('div');
        this._serialSelTable.style.display = 'flex';
        this._serialSelTable.style.flexDirection = 'column';

        let selectedHolder = document.createElement('div');
        selectedHolder.style.overflow = 'auto';
        selectedHolder.style.border = '1px solid #d3d7da';
        selectedHolder.style.paddingBottom = '5px';

        this._selectedTable = document.createElement('table');

        let tableHeader = document.createElement('thead');
        let tableBody = document.createElement('tbody');

        let inputs = document.createElement('div');
        inputs.style.margin = '10px';

        this._submit = document.createElement('button');
        this._submit.className = 'swap-btn';
        this._submit.innerText = 'Submit';
        this._submit.setAttribute('disabled', 'true');
        this._submit.onclick = (() => {
            this.finish();
        }).bind(this);

        let cancel = document.createElement('button');
        cancel.className = 'swap-btn';
        cancel.innerText = 'Cancel';
        cancel.onclick = (() => {
            this._selected = {};
            this.finish();
        }).bind(this);

        serialSelector.append(serialList);
        serialList.append(this._serialSelTable);

        selectedHolder.append(this._selectedTable);
        this._selectedTable.append(tableHeader, tableBody);
        this.addDataRow(['Device Serial', 'Subscription', 'Term Start', ''], true);

        body.append(serialSelector, selectedHolder);
        inputs.append(this._submit, cancel);
        panel.append(title, body, inputs);

        await this.available.searchAll();
        let units: any = this.available.serials;

        if (units?.length) {
            this._filter = document.createElement('input');
            this._filter.placeholder = 'Enter Serial to Filter';
            this._filter.style.border = 'none';
            this._filter.style.outline = 'none';
            this._filter.style.border = '1px solid #d3d7da';
            this._filter.style.marginBottom = '10px';
            this._filter.onkeyup = this.filterSerials.bind(this);
            this._filter.onpaste = this.filterSerials.bind(this);
            this._filter.oncut = this.filterSerials.bind(this);
            this._filter.onkeyup = ((e: any) => {
                if (e.target.value === '') this._filter.style.textTransform = '';
                else this._filter.style.textTransform = 'uppercase';

                this.filterSerials.bind(this)();
            }).bind(this);

            serialList.insertAdjacentElement('beforebegin', this._filter);
            units.map((serial: string) => this.addRow(serial));
        } else {
            this.addRow('No useable serials found');
        }

        this._main.append(panel);
        document.body.append(this._main);
        document.body.setAttribute('swap-open', 'true');
    }

    private finish() {
        var rtn: any;
        Object.entries(this._selected).map(([k, v]) => {
            if (!rtn) rtn = {};

            rtn[k] = {
                subscription: v.subscription.value,
                termStart: Number(v.termStart.value)
            }
        });

        this._handler.resolve(rtn);
        document.body.removeAttribute('swap-open');

        clearInterval(this.debounceInd);
        this.debounceValue = undefined;
        this.debounceInd = undefined;

        this.available.reset();
        this._main.remove();
        this._selected = {};
        this._main = undefined;
        this._serialSelTable = undefined;
        this._handler = undefined;
        this._filter = undefined;
        this._submit = undefined;
        this._mainMouseDown = false;
        this._rows = [];
    }

    show(): Promise<({ [device_serial: string]: { subscription: string, termStart: number } } | undefined)> {
        return new Promise(async (res, rej) => {
            await this.generateDialog();

            this._handler = {
                resolve: res,
                reject: rej
            };
        });
    }
}