export class Device {
  public device_id: Number = 0;
  public device_serial: String = '';
  public unit_imei: String = '0';
  public unit_iccid: String = '0';
  public model: String = ''
  public get isVirtual() {
    return (
      (this.device_serial.indexOf(`${this.model}87`) != -1 ||
      this.device_serial.indexOf(`${this.model}6787`) != -1) && 
      (this.unit_iccid === '0' || this.unit_imei === '0' || this.unit_iccid === '')
    );
  }

  constructor(devObj) {
    for (let o in devObj) {
      this[o] = devObj[o];
    }
  }
}
