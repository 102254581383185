import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Device } from './models/device.model';
import { FirmwareType } from './models/firmware-type.model';
import { FirmwareReq } from './models/firmware-req.model';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FotaService {
  jsonUrl = 'assets/data/';
  lb4Url = environment.API_BASE_URL;

  constructor(private _httpClient: HttpClient) { }

  triggerDeviceUpdate(body, ids): Observable<any> {
    let w = { "id": { "inq": ids } };
    let url = `${this.lb4Url}/devices?where=${JSON.stringify(w)}`;
    return this._httpClient.patch(url, body).pipe(catchError(this.handleError));;
  }

  getDevicesByFirmware(f): Observable<any> {
    let w = {};
    if (f != 'all') {
      w = { "vfota_version": { "regexp": f } } 
    } else {
      w = { "vfota_version": { "regexp": "." } }
    }
    let url = `${this.lb4Url}/devices?where=${JSON.stringify(w)}`;
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError));
  }

  getFirmwareDevicePopulation(): Observable<any> {
    let filter = {"fields":{"device_serial": true, "vfota_version": true}}

    let url = `${this.lb4Url}/devices?filter=${JSON.stringify(filter)}`;
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError));;
  }

  getCurrentFirmware(): Observable<any[]> {
    const url = `${this.lb4Url}/vfota-firm`;
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError));
  }

  getDeployedFirmware(): Observable<any> {
    let filter = {"fields":{"vfota_version":true}}
    let url = `${this.lb4Url}/devices?filter=${JSON.stringify(filter)}`;
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError));
  }

  uploadFirmwareFile(file: string, version: string, type: string, required: string): Observable<any> {
    let url = `${this.lb4Url}/vfota-firm/add-firmware`;
    
    return this._httpClient.post<any>(url, {
      firmware: file,
      version,
      type,
      required
    }).pipe(catchError(this.handleError));
  }

  deleteFirmwareFile(id): Observable<any[]> {
    let url = `${this.lb4Url}/vfota-firm/${id}/delete-firmware`;
    return this._httpClient.delete<any[]>(url).pipe(catchError(this.handleError));;
  }


  getDevices(limit, skip): Observable<Device[]> {
    let url = `${this.lb4Url}/devices`
    if (limit) url += `?filter[limit]=${limit}&filter[skip]=${skip}`
    return this._httpClient.get<Device[]>(url).pipe(catchError(this.handleError));;
  }

  getDeviceCount(): Observable<any> {
    let url = `${this.lb4Url}/devices/count`;
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError));
  }

  getFirmwareTypes(): Observable<FirmwareType[]> {
    let url = this.jsonUrl + 'firmware.type.json';
    return this._httpClient.get<FirmwareType[]>(url).pipe(catchError(this.handleError));
  }

  getFirmwareReqs(): Observable<FirmwareReq[]> {
    let url = this.jsonUrl + 'firmware.req.json';
    return this._httpClient.get<FirmwareReq[]>(url).pipe(catchError(this.handleError));
  }

  private handleError(e: HttpErrorResponse) {
    if (e.error instanceof ErrorEvent) {
      console.log('Client side error: ', e.error.message);
    } else {
      console.log('Server side error: ', e.error.message);
    }

    let err = e.error;
    while (err.error) err = err.error;

    return throwError(err?.message || 'There was a problem with the request.');
  }

  getTitle() {
    return "Verizon";
  }
}
