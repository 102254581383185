<div class="row" *ngIf="showGoToPage === true">
  <div class="col-9">
    <ng-container *ngTemplateOutlet="searchForm"></ng-container>
  </div>

  <div class="col-3">
    <form [formGroup]="_searchService.goToForm" (ngSubmit)="onGoToPage()">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <button type="submit" class="btn btn-outline-secondary" id="#goTo">Go To</button>
        </div>
        <input type="text" formControlName='goToPage' class="form-control" placeholder="Page Number" />
      </div>
    </form>
  </div>
</div>

<div *ngIf="showGoToPage === false">
  <ng-container *ngTemplateOutlet="searchForm"></ng-container>
</div>

<ng-template #searchForm>
  <form [formGroup]="_searchService.searchForm" (ngSubmit)="emitOnSearch()">
    <div class="input-group input-group-sm">
      <div class="input-group-prepend">
        <button type="submit" class="btn btn-outline-secondary">Search</button>
      </div>
      <input type="text" placeholder="{{ placeholder }}" class="form-control"
        formControlName="searchTerm">
    </div>
  </form>
</ng-template>
