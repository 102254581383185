import { Component, OnInit } from '@angular/core';
import { Firmware } from '../../models/firmware.model';
import { FirmwareReq } from '../../models/firmware-req.model';
import { FirmwareType } from '../../models/firmware-type.model';
import { FotaService } from '../../fota.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'app/shared/components/message/message.service'
import { Alert } from '../../../../shared/components/message/message.alert.enum';

@Component({
  selector: 'app-firmware-upload',
  templateUrl: './firmware-upload.component.html',
  styleUrls: ['./firmware-upload.component.scss']
})
export class FirmwareUploadComponent implements OnInit {
  selectedFirmware = new Firmware(1, "", "", "", new Date(), "");
  fileToUpload = null;
  firmwareTypes: FirmwareType[];
  firmwareReqs: FirmwareReq[];
  submitted = false;

  constructor(
    private _fotaService: FotaService,
    private _fb: FormBuilder,
    private _messageService: MessageService
  ) { }

  uploadForm = this._fb.group({
    version: ['', [Validators.required]],
    type: ['', [Validators.required]],
    req: ['', [Validators.required]],
    file: ['', [Validators.required]]
  });

  get version() { return this.uploadForm.get('version'); }
  get type() { return this.uploadForm.get('type'); }
  get req() { return this.uploadForm.get('req'); }

  ngOnInit() {
    this.getFirmwareRequirements();
    this.getFirmwareTypes();
    this.submitted = false;
    this.fileToUpload = null;
  }

  onFileSelected(event) {
    this.fileToUpload = event.target.files[0];
    this.selectedFirmware.name = this.fileToUpload.name;
  }

  onAddAnother() {
    this._messageService.dismissMessage();
    this.uploadForm.setValue({
      version: '',
      type: '',
      req: '',
      file: '',
    });

    this.submitted = !this.submitted;
  }

  // Upload firmware file to the cloud.
  onFileUpload() {
    let reader = new FileReader();
    reader.onload = () => {
      this._fotaService.uploadFirmwareFile(reader.result.toString(), this.version.value, this.type.value, this.req.value).subscribe((res) => {
        if (res?.error) {
          this._messageService.setMessage(Alert.DANGER, res.error.message);
        } else {
          this._messageService.setMessage(Alert.SUCCESS, "Uploaded file: " + this.selectedFirmware.name);
        }

        this.submitted = !this.submitted;
      }, (err) => {
        this._messageService.setMessage(Alert.DANGER, err);
        console.log('Error: firmware.component.onFileUpload', err);
      });
    }
    
    reader.readAsText(this.fileToUpload);
  }

  private getFirmwareTypes() {
    this._fotaService.getFirmwareTypes().subscribe((data) => {
      this.firmwareTypes = data;
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getFirmwareRequirements() {
    this._fotaService.getFirmwareReqs().subscribe((data) => {
      this.firmwareReqs = data;
    },(error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }
}
