import { Component, HostBinding } from '@angular/core';
import { version } from '../../../../package.json'

@Component({
    // moduleId: module.id,
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
    currentDate: Date = new Date();
    public ver: string = version;
}
