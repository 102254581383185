import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Alert } from './message.alert.enum';

type MessageObject = {
  message?: string;
  boldType?: string;
  dismiss?: boolean;
  type?: Alert;
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private initVal: MessageObject = {};
  private messageSource = new BehaviorSubject(this.initVal);

  message = this.messageSource.asObservable();
  ownerKey: string;
  
  constructor() { }

  setMessage(type: Alert, msg: string, boldType?: string, ownerKey?: string) {
    if (ownerKey) this.ownerKey = ownerKey;
    else this.ownerKey = undefined;
    
    this.messageSource.next({ message: msg, type: type, boldType: boldType });
  }

  dismissMessage() {
    this.messageSource.next({ dismiss: true });
  }
}
