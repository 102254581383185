import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClientService } from 'app/http-client.service';
import { Alert } from 'app/shared/components/message/message.alert.enum';
import { MessageService } from 'app/shared/components/message/message.service';
import { SearchService } from 'app/shared/components/search/search.service';

@Component({
  selector: 'app-sensor-list',
  templateUrl: './sensor-list.component.html',
  styleUrls: ['./sensor-list.component.scss']
})
export class SensorListComponent implements OnInit {

  public sensors = [];
  public deviceCount: number = 0;
  public pageCount: number = null;
  public pageDensity;
  public currentPage: number;
  public disableNext: boolean;
  public disablePrevious: boolean;
  public showLoader: boolean;
  public pId: string;
  public pSearchTerm: string;
  public pageDensityValues: number[];
  public sortData = false;
  public title = "Total Device Count"

  placeholder = "Search by email/serial/user ID/device name";

  private searchTerm: AbstractControl;
  private goToPage: AbstractControl;
  
  fields = [
    { field: 'user_id', display: 'User ID' },
    { field: 'sensor_serial', display: 'Serial' },
    { field: 'email', display: 'Email' },
    { field: 'devicename', display: 'Device Name' },
    { field: 'lifetime_tc', display: 'Lifetime Trigger Count' },
  ]

  constructor(
    private _router: Router,
    private _searchService: SearchService,
    private _httpClientService: HttpClientService,
    private _messageService: MessageService
  ) { }

  ngOnInit() {
    this.initVariables();

    this.searchTerm.setValue('');
    this.goToPage.setValue(1);

    // Parse optional parameters
    let params = JSON.parse(sessionStorage.getItem("sensorParams"));

    if (params) {
      this.pId = params.id;
      if (params.st && params.cp && params.pd) {
        this.pageDensity = +params.pd;
        this.currentPage = +params.cp;
        this.pSearchTerm = params.st;
        return this.getDataWithParams();
      }
    }
    this.getData();
  }

  private getDataWithParams() {
    this.goToPage.setValue(this.currentPage + 1);
    this.searchTerm.setValue(this.pSearchTerm);

    this.getData({ cp: this.currentPage, skip: (this.currentPage * this.pageDensity) });
  }

  public getData(params = { cp: this.currentPage, skip: 0 }, sortData = false) {

    this.sortData = sortData;
    this.currentPage = params.cp;
    var skip = params.skip;

    if (skip <= 0) {
      skip = 0;
    }
    this.showLoader = true;

    this.getSensorData(skip);
  }

  private getSensorData(skip: number) {
    this._httpClientService.getHubSensorsCount(this.searchTerm.value).subscribe((res: any) => {
      this.deviceCount = res.count;
      this.pageCount = Math.ceil(this.deviceCount / this.pageDensity);

      if (this.deviceCount === 0) {
        this.currentPage = -1;
        this.goToPage.setValue(0);
      }

      this.getSensors(skip);

      let data = this._searchService.configDisabledFlags(this.currentPage, this.disableNext, this.disablePrevious, this.pageCount);
      this.disableNext = data[0];
      this.disablePrevious = data[1];
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getSensors(skip: number) {
    this._httpClientService.getHubSensors(this.searchTerm.value, this.pageDensity, skip).then((res) => {
      this.sensors = res.slice();
      this.showLoader = false;
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  onShowUserDetail(id: string) {
    this._router.navigate(['./user/detail/', id]);
  }
  onShowBusinessDetail(id: string) {
    this._router.navigate(['./enterprise-customer/detail/', id]);
  }

  onShowDetails(id: any) {
    let navParams = { cp: this.currentPage, pd: this.pageDensity, st: this.searchTerm.value };
    this._router.navigate(['./sensor/details/', id, navParams]);
  }

  onSetPageDensity(val) {
    this.pageDensity = val;
    this.goToPage.setValue(1);
    this.getData({ cp: 0, skip: 0 }, true);
  }

  private initVariables() {
    this.searchTerm = this._searchService.searchTerm;
    this.goToPage = this._searchService.goToPage;
    this.deviceCount = null;
    this.pageCount = null;
    this.pageDensity = 50;
    this.currentPage = 0;
    this.disableNext = false;
    this.disablePrevious = true;
    this.pageDensityValues = [50, 100, 200];
    this.showLoader = true;
  }
}
