// Configruation expected to be used in develop environment on AWS ECS

export const environment = {
  production: false,
  API_BASE_URL: "https://rc-api.sky-hawk.io/api/v3",

  // For device log viewing
  KIBANA_BASE_URL: "https://vpc-skyhawk-t3k64b4m7jgpdhr4dy53qj6fuu.us-east-1.es.amazonaws.com",
  KIBANA_INDEX: "'4ae89460-bdd9-11e9-8a00-c1ce5aeb4894'",

  DEBUG: false,
};
