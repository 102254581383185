<div *ngIf="dataLoaded" class="content-body">
  <h1>User Detail : {{ user.email }}</h1>

  <app-message></app-message>

  <div class="row">
    <div class="col">
      <form [formGroup]='userForm' class="container-fluid">

        <div class="form-group">
          <div>
            <label for="email">Email address</label>
            <input type="email" formControlName="email" class="form-control" (change)="onEmailChanged()" required>
            <small *ngIf="email.invalid" class="text-danger">Valid email required</small>
          </div>
        </div>

        <div class="form-group">
          <label for="phone">Phone</label>
          <input type="text" required pattern="^(\d{11}||\d{10})$" formControlName="phone" mask="(000) 000-0000||0 (000) 000-0000" class="form-control fa-phone">
          <small *ngIf="phone.invalid" class="text-danger">10 or 11 digit phone number is required.</small>
        </div>

        <div class="form-group">
          <label>Options</label>
          <div class="form-control p-lg-0 col-lg">
            <input type="checkbox" formControlName="emailVerified" class="input-checkbox">
            <label for='emailVerified' class='label'>Email Verified</label>
            <input type="checkbox" formControlName="marketingAllowed" class="input-checkbox">
            <label for="marketingAllowed" class='label'>Marketing Allowed</label>
          </div>
        </div>

        <div id="role-btn-panel">
          <div id="roles">
            <div id="roles-lbl">
              <label>ROLES</label>
              <button class="btn btn-outline-warning mx-1" [disabled]="!rolesChanged" (click)="updateRoles()">
                <i class="fas fa-save"></i>Save Roles
              </button>
            </div>
            <div id="role-table">
              <div class="roles-outer">
                <label>PRIMARY</label>
                <div class="roles-inner">
                  <div *ngFor="let role of primaryRoles" [attr.has-role]="userRoles[role]?.has" [attr.disabled]="!userRoles[role]?.enabled" (click)="toggleRole(role)">
                    <span>{{role}}</span>
                    <i class="fas fa-check-circle"></i>
                  </div>
                </div>
              </div>
              <div class="roles-outer">
                <label>SECONDARY</label>
                <div class="roles-inner">
                  <div *ngFor="let role of secondaryRoles" [attr.has-role]="userRoles[role]?.has" [attr.disabled]="!userRoles[role]?.enabled" (click)="toggleRole(role)">
                    <span>{{role}}</span>
                    <i class="fas fa-check-circle"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="buttons">
            <button [class.not-allowed]="userForm.invalid || !userForm.dirty || submitted" [disabled]="userForm.invalid || !userForm.dirty || submitted" type="button" class="btn btn-outline-warning mx-1" id="buttonUpdate" (click)="onUpdate()">
              Update User
            </button>
            <button type="button" class="btn btn-outline-warning mx-1" [disabled]="emailVerified.value === true" (click)="onSendVerificationEmail()">
              Send Verification Email
            </button>
            <button [class.not-allowed]="userForm.dirty" type="button" class="btn btn-outline-warning mx-1" (click)="onResetPassword()">
              Reset User Password
            </button>
            <button [class.not-allowed]="userForm.dirty" type="button" class="btn btn-outline-danger mx-1" (click)="onDelete()" [disabled]="!auth.isMaster">
              Delete User
            </button>
          </div>
        </div>

      </form>
    </div>

    <div class="col">
      <div class="container-fluid">
        <div class="shadow">
          <div class="card-body m-1">

            <!-- user table -->
            <h5 class="mat-card-title">User</h5>
            <app-table-basic [rows]="userRows" [columns]="userFields"></app-table-basic>

            <!-- subscription table -->
            <div id="sub-title">
              <h5 class="mat-card-title">Subscriptions</h5>
              <button [class.not-allowed]="userForm.dirty" type="button" class="btn btn-outline-warning mx-1" (click)="onSubDev()" [disabled]="!auth.isMaster">Subscribe Device(s)</button>
            </div>
            <div class="overflow">
              <app-table-basic [columns]="subFields" [tableData]="tableData"></app-table-basic>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <form>
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="shadow">
            <div class="card-body m-1">

              <app-table-header [deviceCount]="deviceCount" [currentPage]="currentPage" [pageCount]="pageCount">
              </app-table-header>

              <app-table-pagination [disableNext]="disableNext" [disablePrevious]="disablePrevious" [pageDensity]="pageDensity" [pageDensityValues]="pageDensityValues" (onSetPageDensity)="onSetPageDensity($event)" (onDisplayNext)="getDevices($event)" (onDisplayPrevious)="getDevices($event)" [currentPage]="currentPage">
              </app-table-pagination>

              <h5 class="mat-card-title">Devices</h5>
              <div class="horizontal-scroll">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>
                      <input type="checkbox" class="mr-1" (change)="onSelectAll()" />
                    </th>
                    <th *ngFor="let c of sortedColumns" [cmSortBy]="c.field" (sorted)="sort($event)">{{ c.display }}</th>
                    <th *ngFor="let c of unsortedColumns">{{ c.display }}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let device of devices">
                    <td>
                      <input *ngIf="!device.sensor_serial" type="checkbox" class="mr-1" name="checkbox" [(ngModel)]="device.checked" (change)="onChangeSelectDevice(device)" />
                    </td>
                    <td *ngIf="device.sensor_serial; else deviceSerialTemplate">
                      <a href="#/sensor/details/{{ device.id }}">{{ device.sensor_serial }}</a>
                    </td>
                    <ng-template #deviceSerialTemplate>
                      <td><a href="#/device/edit/{{ device.id }}">{{ device.device_serial }}</a></td>
                    </ng-template>
                    <td> {{ device.devicename }} </td>
                    <td> {{ device.unit_currentfirm }}</td>
                    <td> {{ device.lifetime_tc }}</td>
                    <td> {{ device.lastActive }}</td>
                    <td> {{ device.lastConnection }}</td>
                  </tr>
                </tbody>
              </table>
              </div>  
              <button class="btn btn-outline-warning mx-1" style="margin-top: 15px" (click)="onUpdateDevices()" [disabled]="this.selectedDevices?.length === 0">
                Update Device(s)
              </button>
              <button class="btn btn-outline-warning" style="margin-top: 15px" (click)="exportExcel()" [disabled]="!devices?.length">Export to Excel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>