import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientService } from 'app/http-client.service';

export interface BusinessDetail {
  name: string;
  businessType: string;
  email: string;
  address_1: string;
  address_2: string;
  city: string;
  phone: string;
  state: string;
  zip: string;
};

export interface BusinessUser {
  first_name?: string;
  last_name?: string;
  email: string;
  password?: string;
  phone?: string;
  userId?: string;
  existingUser?: boolean;
  fullAdminRights: boolean;
};

export interface BusinessBillingInfo {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  sageId: string;
  taxExempt: boolean;
  allowDirectDebit: boolean;
  autoCollection: boolean;
  netTermDays: number;
};

export interface CustomPricing {
  hub: string;
  sensors: string;
  kiwi: string;
  kiwiir: string;
};

@Injectable({
  providedIn: 'root'
})
export class CreateBusinessService {
  private modalRef: NgbModalRef;

  states = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
  inUseEmails: string[] = [];
  userEmails: string[] = [];
  title: string = '';
  nextText: string = '';

  businessDetail: BusinessDetail = {
    name: '',
    businessType: '',
    email: '',
    address_1: '',
    address_2: '',
    city: '',
    phone: '',
    state: '',
    zip: ''
  };

  businessOwner: BusinessUser = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone: '',
    userId: '',
    existingUser: false,
    fullAdminRights: false
  };

  businessBillingUser: BusinessUser = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone: '',
    userId: '',
    existingUser: false,
    fullAdminRights: false
  };

  businessBillingInfo: BusinessBillingInfo = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    sageId: '',
    taxExempt: false,
    allowDirectDebit: false,
    autoCollection: false,
    netTermDays: 0
  };

  customPricing: CustomPricing = {
    hub: '',
    sensors: '',
    kiwi: '',
    kiwiir: ''
  };

  constructor(
    private _modalService: NgbModal,
    private http: HttpClientService
  ) {  }

  public async open(
    component: any,
    title: string,
    edit: boolean = false,
    dialogSize: 'sm' | 'lg' = 'lg'
  ): Promise<boolean> {
    this.title = title;
    
    this.modalRef = this._modalService.open(component, { size: dialogSize, backdrop: 'static' });
    if (this.modalRef.componentInstance.reset) this.modalRef.componentInstance.reset();

    //Change the "complete" button text based on the form
    //  Next -   Blank form, moves to next form when clicked
    //  Done -   Edit form, move back to review when clicked
    //  Submit - Review form, close modal and attempt to create the records
    this.nextText = (edit ? 'Done' : 'Next');
    if (this.title.indexOf('Review') !== -1) this.nextText = 'Submit';

    //List of emails already being used by businesses
    this.inUseEmails = (await this.http.getBusinessEmails()).map((e: any) => e.email);

    //If the form is being edited, run an initial eval to ensure the 
    //  user cannot submit with bad/ missing values
    if (edit) {
      setTimeout(() => {
        this.modalRef.componentInstance.evalForm();
      }, 100)
    }

    return this.modalRef.result;
  }

  reset() {
    this.businessDetail = {
      name: '',
      businessType: '',
      email: '',
      address_1: '',
      address_2: '',
      city: '',
      phone: '',
      state: '',
      zip: ''
    };

    this.businessOwner = {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      phone: '',
      userId: '',
      existingUser: false,
      fullAdminRights: false
    };

    this.businessBillingUser = {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      phone: '',
      userId: '',
      existingUser: false,
      fullAdminRights: false
    };

    this.businessBillingInfo = {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      sageId: '',
      taxExempt: false,
      allowDirectDebit: false,
      autoCollection: true,
      netTermDays: 0
    };

    this.customPricing = {
      hub: '',
      sensors: '',
      kiwi: '',
      kiwiir: ''
    };
  }
}
