import { Component, OnInit, Input } from '@angular/core';
import { FotaService } from '../../fota.service';
import { HelperService } from '../../../service/helper.service';
import { HttpClientService } from 'app/http-client.service';
import { MessageService } from 'app/shared/components/message/message.service'
import { Alert } from '../../../../shared/components/message/message.alert.enum';


@Component({
  selector: 'app-firmware-population',
  templateUrl: './firmware-population.component.html',
  styleUrls: ['./firmware-population.component.scss']
})

export class FirmwarePopulationComponent implements OnInit {
  @Input() reloaded: boolean;
  selectedTargetFirmware;
  population: any[] = [];
  fotafirm: any[];
  pop: any;
  pieChartSingle: any[];
  chartSize: any[] = [1000, 600];
  public show: boolean = true;
  public buttonName: any = 'Hide Population';
  title = null;
  deviceCount = 0;

  constructor(
    private _fotaService: FotaService,
    private _helperService: HelperService,
    private _httpClientService: HttpClientService,
    private _messageService: MessageService
  ) { }

  ngOnInit() {
    let cw = window.innerWidth * .5;
    let ch = cw / 2;
    this.chartSize = [cw, ch];

    if (this.reloaded) {
      this.onToggle();
    }
    this.getFirmareDevicePopulation();
    this.title = this._fotaService.getTitle();
    this.getDeviceCount();
  }

  onSelect($event) {
    this._helperService.displayPopulationMessage($event);
  }

  onToggle() {
    this.show = !this.show;
    if (this.show)
      this.buttonName = "Hide Population";
    else
      this.buttonName = "Show Population";
  }

  private getFirmareDevicePopulation() {
    this._fotaService.getFirmwareDevicePopulation().subscribe((data) => {
      let count = []

      // count firmware
      data.forEach(ele => {
        count[ele.vfota_version] = (count[ele.vfota_version] || 0) + 1;
      });

      for (const [key, value] of Object.entries(count)) {
        this.population.push({ name: key, value: value });
      }
      
      this.pieChartSingle = this._helperService.updateNoFirmware(this.population);
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getDeviceCount() {
    this._httpClientService.getDeviceCount(null).then((res) => {
      this.deviceCount = res.count;
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }
}
