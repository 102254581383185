import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientService } from 'app/http-client.service';
import { AuthService } from 'app/shared/auth/auth.service';
import { Alert } from 'app/shared/components/message/message.alert.enum';
import { MessageService } from 'app/shared/components/message/message.service';
import { ConfirmationDialogService } from 'app/shared/dialog/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-enterprise-detail',
  templateUrl: './enterprise-detail.component.html',
  styleUrls: ['./enterprise-detail.component.scss']
})
export class EnterpriseDetailComponent implements OnInit {

  business: any;
  busId: string;
  busOwner = [];
  billingUser = [];
  businessDetails = [];
  activationTxt: string;
  businessBilling: { attribute: string; value: any; }[];
  busOwnerLink: string = null;
  busBillingLink: string = null;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _httpClientService: HttpClientService,
    private _messageService: MessageService,
    private _confirmationDialogService: ConfirmationDialogService,
    private _fb: FormBuilder,
    public auth: AuthService
  ) { }

  updateForm = this._fb.group({
    busName: ['', [Validators.required, Validators.minLength(2)]],
    busEmail: ['', [Validators.required, Validators.email]]
  });
  get busName() { return this.updateForm.get('busName'); }
  get busEmail() { return this.updateForm.get('busEmail'); }

  ngOnInit() {
    this.busId = this._activatedRoute.snapshot.paramMap.get('id');
    let params = this._activatedRoute.snapshot.params;

    if (params) sessionStorage.setItem("ecParams", JSON.stringify(params));

    if (this.busId) {
      this.getData();
    } else {
      this._messageService.setMessage(Alert.DANGER, "Business not found");
    }
  }

  private getData() {
    this._httpClientService.getBusiness(this.busId).subscribe(business => {
      this.business = business;

      this.busName.setValue(this.business.name);
      this.busEmail.setValue(this.business.email);

      this.getUser();
      this.getBusinessDetail();
      this.getBusinessBilling();

      console.log(this.business);
    }, (err: ErrorEvent) => {
      console.error(err);
      this._messageService.setMessage(Alert.DANGER, "Failed to get business");
    });
  }

  private getUser() {
    let userId = this.business.account_owner_id;
    this._httpClientService.getUser(userId).subscribe((res: any) => {
      this.busOwner = [
        { attribute: 'First Name', value: res.first_name },
        { attribute: 'Last Name', value: res.last_name },
        { attribute: 'User ID', value: res.id },
        { attribute: 'Email', value: res.email },
        { attribute: 'Phone', value: this.formatPhone(res.phone) },
        { attribute: 'Created', value: new Date(res.created).toLocaleString() },
        { attribute: 'Modified', value: new Date(res.modified).toLocaleString() }
      ];
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
    this.busOwnerLink = "/user/detail/" + userId;
  }

  private getBusinessDetail() {
    let bus = this.business;

    this.businessDetails = [
      { attribute: 'ID', value: bus.id },
      { attribute: 'Address 1', value: bus.address_1 },
      { attribute: 'Address 2', value: bus.address_2 },
      { attribute: 'City', value: bus.city },
      { attribute: 'State', value: bus.state },
      { attribute: 'Phone', value: this.formatPhone(bus.phone) },
      { attribute: 'Zip', value: bus.zip },
      { attribute: 'Segment ID', value: bus.segment_id },
      { attribute: 'Created', value: new Date(bus.created).toLocaleString() },
      { attribute: 'Modified', value: new Date(bus.modified).toLocaleString() },
    ];
  }

  private getBusinessBilling() {
    console.log(this.business.id)
    this._httpClientService.getBusinessBilling(this.business.id).subscribe((res: any) => {
      var billing = res[0];
      this.getBillingUser(billing.bb_billing_admin_id);

      this.businessBilling = [
        { attribute: 'Payment Method', value: billing.bb_payment_method },
        { attribute: 'Payment Terms', value: billing.bb_payment_terms },
        { attribute: 'Chargebee ID', value: billing.bb_cb_id },
        { attribute: 'Sage ID', value: billing.bb_sage_id },
        { attribute: 'Tax Exempt', value: billing.bb_tax_exempt },
        { attribute: 'Custom Pricing', value: (billing.bb_custom_pricing || false) },
        { attribute: 'Kiwi Pricing', value: billing.bb_custom_kiwi_price },
        { attribute: 'Kiwiir Pricing', value: billing.bb_custom_kiwiir_price },
        { attribute: 'Hub Pricing', value: billing.bb_custom_hub_price },
        { attribute: 'Sensor Pricing', value: billing.bb_custom_sensor_price }
      ]

      this.busBillingLink = "/user/detail/" + billing.bb_owner_id;
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getBillingUser(billingAdminId: string) {
    this._httpClientService.getUser(billingAdminId).subscribe((res: any) => {
      this.billingUser = [
        { attribute: 'First Name', value: res.first_name },
        { attribute: 'Last Name', value: res.last_name },
        { attribute: 'User ID', value: res.id },
        { attribute: 'Email', value: res.email },
        { attribute: 'Phone', value: this.formatPhone(res.phone) },
        { attribute: 'Created', value: new Date(res.created).toLocaleString() },
        { attribute: 'Modified', value: new Date(res.modified).toLocaleString() }
      ];
    })
  }

  onUpdate() {
    let body = {
      name: this.busName.value,
      email: this.busEmail.value
    }
    this._httpClientService.updateBusiness(this.business.id, body).subscribe(res => {
      this._messageService.setMessage(Alert.SUCCESS, "Business updated");
    }, (e: ErrorEvent) => {
      console.error(e)
      this._messageService.setMessage(Alert.DANGER, e.error.error.message)
    });
  }

  async onDelete() {
    let confirm = await this._confirmationDialogService.confirm('Delete Business', `You are about to delete the business: ${this.business.name} and it's related records, do you wish to continue?`, 'Yes', 'Cancel', 'warning');

    if (confirm) confirm = await this._confirmationDialogService.confirm('Delete Business - Confirmation', 'This cannot be undone, are you sure?', 'Yes', 'Cancel', 'warning');
    else return this._messageService.setMessage(Alert.DANGER, 'User canceled business deletion');

    if (confirm) {
      this._httpClientService.deleteBusiness(this.business.id).subscribe(res => {
        alert('You will be redirected back to the enterprise customer list.');
        this._router.navigate(['./enterprise-customer/list', { busDeleted: this.busEmail }])
      }, (e: ErrorEvent) => {
        console.error(e)
        this._messageService.setMessage(Alert.DANGER, e.error?.error?.message)
      });
    } else {
      this._messageService.setMessage(Alert.DANGER, 'User canceled business deletion');
    }
  }
  formatPhone(number) {
    if (number == null || number.length < 10) {
      return "Invalid phone number";
    }
    if (number.length === 10)
      return `(${number.substr(0, 3)}) ${number.substr(3, 3)}-${number.substr(6, 4)}`;

    if (number.length === 11)
      return `${number.substr(0, 1)} (${number.substr(1, 3)}) ${number.substr(4, 3)}-${number.substr(7, 4)}`;
  }
}
