import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-table-basic',
  templateUrl: './table-basic.component.html',
  styles: [`
    table td { word-break: break-word; }
    table td { min-width: 25px; }
  `]
})
export class TableBasicComponent implements OnInit {

  constructor(private _fb: FormBuilder) { }

  // This group is used for a basic table
  @Input() columns;
  @Input() rows;
  @Input() highlightRow;
  @Input() showEditButton = false;
  @Input() editButtonName = 'Edit';
  @Input() showDetailButton = false;
  @Input() showTrackingDetailButton = false;
  @Input() tableData;

  @Output() onShowDetail: EventEmitter<any> = new EventEmitter<any>();
  @Output() onShowEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onShowTrackingDetail: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    this.showDetailButton = false;
    this.showTrackingDetailButton = false;
  }

  emitOnShowEdit(row) {
    this.onShowEdit.emit(row);
  }

  emitOnShowDetail(row) {
    this.onShowDetail.emit(row);
  }
  
  emitOnShowTrackingDetail(row) {
    this.onShowTrackingDetail.emit(row);
  }

  onGetStatus(row) {
    alert(row.statusMessage);
  }
}
