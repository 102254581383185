import { Component, OnInit } from '@angular/core';
import { FotaService } from '../../fota.service';
import { MessageService } from 'app/shared/components/message/message.service';
import { SearchService } from 'app/shared/components/search/search.service';
import { Alert } from '../../../../shared/components/message/message.alert.enum';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss']
})
export class DeviceListComponent implements OnInit {
  title = null;
  devices: any[] = null;

  public deviceCount: number = 0;
  public pageDensity: number = 50;
  public currentPage: number = 0;
  public disableNext: boolean = false;
  public disablePrevious: boolean = true;
  public pageCount: number = null;
  public showLoader: boolean = true;
  public columns = [];
  public rows: any[][] = new Array();
  public tableData;

  constructor(
    private _fotaService: FotaService,
    private _messageService: MessageService,
    private _searchService: SearchService
  ) { }

  ngOnInit() {
    this.getData();
    this.title = this._fotaService.getTitle();
  }

  private loadTableData() {
    this.tableData = [];
    this.rows = [];
    this.columns = ["ID", "Serial Number", "Current", "Previous", "Target",];
    this.pageCount = Math.ceil(this.deviceCount / this.pageDensity);

    this.devices.forEach((ele) => {
      this.tableData.push([ele.id, ele.device_serial, ele.vfota_version, ele.vfota_previous, ele.vfota_newpn,]);
    });
  }

  getData(param = { cp: this.currentPage, skip: this.currentPage * this.pageDensity }) {
   
    this.currentPage = param.cp;
    let skip = param.skip;

    this._fotaService.getDeviceCount().subscribe((res) => {
      this.deviceCount = res.count;
      this.getDevices(skip);
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  private getDevices(skip: number) {
    this._fotaService.getDevices(this.pageDensity, skip).subscribe((res) => {
      this.devices = res;
      this.loadTableData();

      let data = this._searchService.configDisabledFlags(this.currentPage, this.disableNext, this.disablePrevious, this.pageCount);
      this.disableNext = data[0];
      this.disablePrevious = data[1];

      this.showLoader = false;
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }

  onSetPageDensity(val) {
    this.pageDensity = val;
    this.currentPage = 0;
    this.getData();
  }
}
