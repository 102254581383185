export type RFDataObject = {
    serialNumber: string;
    sensitivity: number;
    sensorRSSI: string;
    voltage: number;
    commID: number;
    firmwareVersion?: string;
    hubRSSI?: string;
    busyCount?: number;
    retryCount?: number;
    triggerBackOff?: number;
    pause?: number;
    minBackOff?: number;
    heartbeat?: number;
    cpuTemperature?: string;
    timestamp?: string;
    status: {
        eventType: string;
        sensorTest: boolean;
        poweredOff: boolean;
        poweredOn: boolean;
        heartbeat: boolean;
        trigger: boolean;
        reset: boolean;
    };
    extendedData: {
        moduleStatus?: number;
        moduleValue?: number;
    };
}

export type RFRawObject = {
    serialNumber: string;
    sensorRSSI: string;
    sensitivity: string;
    voltage: string;
    commID: string;
    status: string;
    extendedData: string;
    hubRSSI: string;
    firmwareVersion: string;
    busyCount: string;
    retryCount: string;
    triggerBackOff: string;
    pause: string;
    minBackOff: string;
    heartbeat: string;
    cpuTemperature: string;
    timestamp: string;
}

type SubstringRef = { start: number; end: number; }

const segmentMap = {
    serialNumber: { start: 0, end: 10 },
    status: { start: 10, end: 12 },
    extendedData: { start: 12, end: 14 },
    voltage: { start: 14, end: 16 },
    sensitivity: { start: 16, end: 18 },
    commID: { start: 18, end: 20 },
    firmwareVersion: { start: 20, end: 22 },
    hubRSSI: { start: 22, end: 24 },
    busyCount: { start: 24, end: 26 },
    retryCount: { start: 26, end: 28 },
    triggerBackOff: { start: 28, end: 30 },
    pause: { start: 30, end: 32 },
    minBackOff: { start: 32, end: 34 },
    heartbeat: { start: 34, end: 36 },
    cpuTemperature: { start: 36, end: 38 },
    timestamp: { start: 38, end: 46 }
}

const prefixMap = {
    '01': 'TRP',
    '02': 'MSE',
    '03': 'BAT',
    '04': 'RED',
    '05': 'BAP',
    '06': 'SRE',
    '07': 'BTY',
    '08': 'BLG',
    '09': 'PIN',
    '0A': 'PIR',
    '0B': 'FFC',
    '0C': 'TLT',
    '0D': 'RGE',
    '0E': 'SND',
    '0F': 'LTH',
    '11': 'SRN'
};

const eventMap = {
    '8': 'Temperature',
    '9': 'Humidity',
    'A': 'Contact',
    'B': 'Sound',
    'C': 'Range',
    'D': 'Sensitivity',
    '1': 'Accel',
    '2': 'Reed',
    '3': 'PIR/IR',
    '4': 'Water Contact',
    '5': 'Battery Voltage',
    '6': 'Bilge',
    '7': 'Shore',
    '0': 'Device'
};

const techMap = {
    'TRP': 'Accel|Reed', //Trap Sensor
    'MSE': 'Accel|Reed', //Mouse Trap Sensor
    'BAT': 'IR', //Bait Sensor
    'RED': 'Accel|Reed', //Reed Switch Sensor
    'SRE': 'Power', //Shore Power Sensor
    'BTY': 'Power', //Battery Sensor
    'BLG': 'Power', //Bilge Sensor
    'PIN': 'Temp|Humidity|Resistance', //THW Sensor - PIN version
    'PIR': 'PIR|Accel', //PIR Sensor
    'FFC': 'Temp|Humidity|Water|Cable', //THW Sensor - FFC version
    'RGE': 'Laser', //Range Sensor
    'SND': 'Mic', //Sound Sensor
    'SRN': 'Audio', //Siren Sensor
    'LTH': 'Accel|IR|Servo', //Auto-Latch Sensor
    'BAP': 'PIR', //Bait Sensor
    'TLT': 'Accel' //Toilet Sensor
};

const padLeft = (str: string, char: string, length: number) => {
    while (str.length < length) str = `${char}${str}`;
    return str;
}

const parseSignedInt = (data: string, radix: number) => {
    let a = parseInt(data, radix);
    if ((a & 0x80) > 0) a = a - 0x100;

    return a;
}

const ceilDec = function (data: number, decimalPoints: number) {
    let n = data.toString();
    let i = n.indexOf('.');

    if (i > -1) {
        let t = n.substring((i + 1)).split('').map(e => Number(e));

        if (decimalPoints < t.length && t[decimalPoints] >= 5) t[(decimalPoints - 1)] += 1;

        t.splice(decimalPoints);
        n = `${n.substring(0, i)}.${t.join('')}`;
    }

    return Number(n);
}

const getSegment = (data: string, stringRef: SubstringRef) => {
    if (data.length < stringRef.end) return null;
    return data.substring(stringRef.start, stringRef.end);
}

const parseSerial = (data: string) => {
    let serial = prefixMap[data.substring(0, 2)];
    serial += padLeft(parseInt(data.substring(2, 4), 16).toString(), '0', 2);
    serial += padLeft(parseInt(data.substring(4, 6), 16).toString(), '0', 2);
    serial += padLeft(parseInt(data.substring(6, 8), 16).toString(), '0', 2);
    serial += padLeft(parseInt(data.substring(8, 10), 16).toString(), '0', 2);

    return serial;
}

const parseStatus = (data: string) => {
    let status: any = padLeft(parseInt(data[1], 16).toString(2), '0', 4)
    status = status.split('').map((e) => (e === '1'));

    let hb = (data[0] !== 'D' && !status.find((e) => e === true));
    let reset = (!hb && status[2] && status[3]);

    return {
        eventType: eventMap[data[0]],
        trigger: status[0],
        sensorTest: status[1],
        poweredOn: (!reset && status[2]),
        poweredOff: (!reset && status[3]),
        reset: reset,
        heartbeat: hb
    };
}

const parseExtendedData = (data: string, overflow: string, grf: string) => {
    let serial = parseSerial(getSegment(grf, segmentMap.serialNumber));
    let status = parseStatus(getSegment(grf, segmentMap.status));

    let tech = techMap[serial.substring(0, 3)];

    let tmp: any = parseInt(data, 16);
    let extended: any = {};

    let statusTypes = ['Reed', 'Shore', 'Water Contact', 'Contact', 'Temperature', 'Humidity'];

    if (status.eventType === 'Battery Voltage') {
        //Add overflow from data period 8, only if last four bits = '0001'
        if (overflow[1] === '1') {
            tmp += parseInt(overflow[0], 16);
        }

        //Data is in 100mV divisions, divide by 10 to convert to volts
        tmp = (tmp / 10).toFixed(2);
    }

    //If the event has a status (on-off/1-0) field type set moduleStatus otherwise set moduleValue
    if (statusTypes.indexOf(status.eventType) !== -1 || tech.indexOf('Reed') !== -1) {
        extended.moduleStatus = (tmp == 2 ? 1 : 0);
    } else {
        extended.moduleValue = tmp;
    }

    return extended;
}

export function readGrfPayload(data: string): ({ data: RFDataObject; raw: RFRawObject } | null) {
    let grf = data.split(':');
    let sensorRSSI = grf[1];
    let payload = grf[0];

    if (payload.toLowerCase() === 'n/a') return null;

    let raw: RFRawObject = {
        firmwareVersion: getSegment(payload, segmentMap.firmwareVersion),
        extendedData: getSegment(payload, segmentMap.extendedData),
        serialNumber: getSegment(payload, segmentMap.serialNumber),
        sensitivity: getSegment(payload, segmentMap.sensitivity),
        voltage: getSegment(payload, segmentMap.voltage),
        hubRSSI: getSegment(payload, segmentMap.hubRSSI),
        commID: getSegment(payload, segmentMap.commID),
        status: getSegment(payload, segmentMap.status),
        busyCount: getSegment(payload, segmentMap.busyCount),
        retryCount: getSegment(payload, segmentMap.retryCount),
        triggerBackOff: getSegment(payload, segmentMap.triggerBackOff),
        pause: getSegment(payload, segmentMap.pause),
        minBackOff: getSegment(payload, segmentMap.minBackOff),
        heartbeat: getSegment(payload, segmentMap.heartbeat),
        cpuTemperature: getSegment(payload, segmentMap.cpuTemperature),
        timestamp: getSegment(payload, segmentMap.timestamp),
        sensorRSSI
    };

    let parsedData: RFDataObject = {
        serialNumber: parseSerial(raw.serialNumber),
        sensitivity: parseInt(raw.sensitivity, 16),
        voltage: ceilDec((2 + (parseInt(raw.voltage, 16) * 0.010)), 2),
        commID: parseInt(raw.commID, 16),
        status: parseStatus(raw.status),
        sensorRSSI: `${raw.sensorRSSI} dB`,
        extendedData: parseExtendedData(raw.extendedData, raw.sensitivity, data)
    };

    if (raw.hubRSSI) parsedData.hubRSSI = `${parseSignedInt(raw.hubRSSI, 16)} dB`;
    if (raw.firmwareVersion) parsedData.firmwareVersion = `T${padLeft(parseInt(raw.firmwareVersion, 16).toString(), '0', 3)}`;
    if (raw.busyCount) parsedData.busyCount = parseInt(raw.busyCount, 16);
    if (raw.retryCount) parsedData.retryCount = parseInt(raw.retryCount, 16);
    if (raw.triggerBackOff) parsedData.triggerBackOff = parseInt(raw.triggerBackOff, 16);
    if (raw.pause) parsedData.pause = parseInt(raw.pause, 16);
    if (raw.minBackOff) parsedData.minBackOff = parseInt(raw.minBackOff, 16);
    if (raw.heartbeat) parsedData.heartbeat = parseInt(raw.heartbeat, 16);
    if (raw.cpuTemperature) parsedData.cpuTemperature = `${parseInt(raw.cpuTemperature, 16)} °c`
    if (raw.timestamp) parsedData.timestamp = new Date(parseInt(raw.timestamp, 16) * 1000).toLocaleString();

    return { data: parsedData, raw };
}