<div class="apex-content content container-fluid">
  <h1>Add Testing Sensor</h1>
  <div class="alert alert-danger" role="alert">
    <h3>This functionality should <b>only be used on development/testing</b> servers.</h3>
  </div>

  <app-message></app-message>

  <div class="content-wrapper">
    <div class="content-body">
      <form [formGroup]="updateForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label>Sensor Serial</label>
          <input formControlName="sensorSerial" type="text" style="text-transform: uppercase;" class="form-control" required>
          <p *ngIf="sensorSerial.invalid && sensorSerial.touched" class="text-danger">
            Serial should be 11 characters
          </p>
        </div>
        <div class="form-group">
          <label>Service Tag</label>
          <input formControlName="serviceTag" type="text" class="form-control">
        </div>
        <div class="form-group">
          <label>Bonded To</label>
          <input formControlName="bondedTo" type="text" class="form-control">
        </div>
        <div class="form-group">
          <label>Status</label>
          <input formControlName="status" type="text" class="form-control">
        </div>

        <button class="btn btn-outline-warning" (click)="onSubmit()" [disabled]="updateForm.invalid" type="button">
          Add Sensor
        </button>
      </form>
    </div>
  </div>
</div>