import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnterpriseDetailComponent } from './enterprise-detail/enterprise-detail.component';
import { EnterpriseListComponent } from './enterprise-list/enterprise-list.component';

const routes: Routes = [
  { path: 'list', component: EnterpriseListComponent, data: { title: 'Enterprise List' } },
  { path: 'detail/:id', component: EnterpriseDetailComponent, data: { title: 'Enterprise Detail' } }
];
 
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EnterpriseRoutingModule { }

export const USER_ROUTING_COMPONENTS = [
  EnterpriseListComponent,
  EnterpriseDetailComponent
];
