import { Component, OnInit } from '@angular/core';
import { User } from '../user.interface';
import { Router, NavigationExtras } from '@angular/router';
import { SearchService } from 'app/shared/components/search/search.service';
import { HttpClientService } from 'app/http-client.service';
import { MessageService } from 'app/shared/components/message/message.service'
import { AbstractControl } from '@angular/forms';
import { Alert } from '../../shared/components/message/message.alert.enum';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})

export class UserListComponent implements OnInit {
  public users: User[];
  public userCount: number;
  public pageCount: number;
  public pageDensity: number;
  public currentPage: number;
  public disableNext: boolean;
  public disablePrevious: boolean;
  public pageDensityValues: number[];
  public showLoader: boolean;
  public pId: number;
  public pSearchTerm: string;
  public pCurrentPage: number;
  public pPageDensity: number;
  public sortData: boolean = false;

  placeholder = "Search by email/phone/first name/last name";

  private goToPage: any;
  private searchTerm: AbstractControl;

  fields = [
    { field: 'id', display: 'ID' },
    { field: 'email', display: 'Email' }, 
    { field: 'phone', display: 'Phone' }
  ]

  constructor(
    private _httpClientService: HttpClientService,
    private _router: Router,
    private _searchService: SearchService,
    private _messageService: MessageService
  ) { }
    
  ngOnInit() {
    this.initVariables();

    this.searchTerm.setValue('');
    this.goToPage.setValue(1);

    // Parse optional parameters
    let params = JSON.parse(sessionStorage.getItem("usrParams"));

    if (params !== null) {
      this.pId = params.id;
      this.pSearchTerm = params.st;
      this.pCurrentPage = params.cp;
      this.pPageDensity = params.pd;
      
      this.getDataWithParams();
    } else {
      this.getData();
    }
  }

  private getDataWithParams() {
    this.pageDensity = this.pPageDensity;
    this.currentPage = this.pCurrentPage;

    this.goToPage.setValue(this.currentPage + 1);
    this.searchTerm.setValue(this.pSearchTerm);
 
    this.getData({ cp: this.currentPage, skip: (this.currentPage * this.pageDensity) });
  }

  onEdit(user) {
    let queryParams: any = {};
    queryParams.params = JSON.stringify({ id: user.id, cp: this.currentPage, pd: this.pageDensity, st: this.searchTerm.value });
    let navigationExtras: NavigationExtras = { queryParams };

    this._router.navigate([`./user/detail/${user.id}`], navigationExtras);
  }

  onSetPageDensity(val) {
    this.pageDensity = val;
    this.goToPage.setValue(1);
    this.getData({ cp: 0, skip: 0 }, true);
  }

  private initVariables() {
    this.goToPage = this._searchService.goToPage;
    this.searchTerm = this._searchService.searchTerm;
    this.userCount = null;
    this.pageCount = null;
    this.users = [];
    this.pageDensity = 50;
    this.currentPage = 0;
    this.disableNext = false;
    this.disablePrevious = true;
    this.pageDensityValues = [50, 100, 200];
    this.showLoader = true;
  }

  public getData(param = { cp: this.currentPage, skip: 0 }, sortData = false) {

    this.sortData = sortData;
    this.currentPage = param.cp;
    var skip = param.skip;

    this.showLoader = true;
    if (skip < 0) {
      skip = 0;
    }
    this._httpClientService.getUserCount(this.searchTerm.value).subscribe((res) => {
      this.userCount = res.length;
      this.pageCount = Math.ceil(this.userCount / this.pageDensity);

      if (this.userCount === 0) {
        this.currentPage = -1;
        this.goToPage.setValue(0);
      }
      
      this.getUsers(skip);

      let data = this._searchService.configDisabledFlags(this.currentPage, this.disableNext, this.disablePrevious, this.pageCount);
      this.disableNext = data[0];
      this.disablePrevious = data[1];
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error)
    });
  }

  private getUsers(skip: number) {
    this._httpClientService.getUsers(this.pageDensity, skip, this.searchTerm.value).subscribe((res) => {
      this.users = res.slice();
      this.showLoader = false;
    }, (error) => {
      this._messageService.setMessage(Alert.DANGER, error);
    });
  }
}
