<div class="row">
  <div class="col-4">
    <ul class="pagination pagination-sm">

      <li class="page-item mr-1">
        <button type="button" class="btn btn-md" [class.not-allowed]="disablePrevious" [disabled]="disablePrevious"
          (click)="emitOnDisplayPrevious()"> &#60; Previous
        </button>
      </li>

      <li class="page-item ml-1">
        <button type="button" class="btn btn-md" [class.not-allowed]="disableNext" [disabled]="disableNext"
          (click)="emitOnDisplayNext()">Next &#62;
        </button>
      </li>

    </ul>
  </div>

  <!-- Page Density -->
  <div class="col-8">
    <div class="pull-right">
      <p class="m-0 text-muted text-small"><i><small>Page Density (Rows/Page)</small></i></p>
      <div *ngFor="let val of pageDensityValues" class="form-check form-check-inline">

        <input *ngIf="val == pageDensity" (click)="emitOnSetPageDensity(val)" class="form-check-input" type="radio"
          value="{{val}}" checked="true" name="_radioButtons_">

        <input *ngIf="val != pageDensity" (click)="emitOnSetPageDensity(val)" class="form-check-input" type="radio"
          value="{{val}}" name="_radioButtons_">

        <label class="form-check-label">{{val}}</label>
      </div>
    </div>
  </div>
</div>