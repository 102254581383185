<div class="modal-header bg-primary" style="border: solid 3px #fff;">
  <h4 class="modal-title text-light">{{ createBus.title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="decline()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="data-set">
    <div class="title">
      <h5>Details:</h5>
      <button (click)="update('details')">Update</button>
    </div>
    <div class="data">
      <div class="data-point" *ngFor="let d of detailsEntries">
        <h6>{{d[0]}}:</h6>
        <span>{{d[1]}}</span>
      </div>
    </div>
  </div>
  <div class="data-set">
    <div class="title">
      <h5>Owner:</h5>
      <button (click)="update('owner')">Update</button>
    </div>
    <div class="data">
      <div class="data-point" *ngFor="let d of ownerEntries">
        <h6>{{d[0]}}:</h6>
        <span>{{d[1]}}</span>
      </div>
    </div>
  </div>
  <div class="data-set">
    <div class="title">
      <h5>Billing User:</h5>
      <button (click)="update('billing-user')">Update</button>
    </div>
    <div class="data">
      <div class="data-point" *ngFor="let d of billingUserEntries">
        <h6>{{d[0]}}:</h6>
        <span>{{d[1]}}</span>
      </div>
    </div>
  </div>
  <div class="data-set">
    <div class="title">
      <h5>Billing Information:</h5>
      <button (click)="update('billing-info')">Update</button>
    </div>
    <div class="data">
      <div class="data-point" *ngFor="let d of billingInfoEntries">
        <h6>{{d[0]}}:</h6>
        <span>{{d[1]}}</span>
      </div>
    </div>
  </div>
  <div class="data-set" *ngIf="customPricingEntries?.length">
    <div class="title">
      <h5>Custom Pricing:</h5>
      <button (click)="update('custom-pricing')">Update</button>
    </div>
    <div class="data">
      <div class="data-point" *ngFor="let d of customPricingEntries">
        <h6>{{d[0]}}:</h6>
        <span>{{d[1]}}</span>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button id="next" type="button" class="btn btn-outline-warning" (click)="next()">{{createBus.nextText}}</button>
  <button id="cancel" type="button" class="btn btn-outline-warning" (click)="decline()">Cancel</button>
</div>