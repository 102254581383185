<!--Footer Starts-->
<footer>
    <div class="container-fluid">
        <div class="copyright text-center m0 p0">
            <p class=""><b>Skyhawk Support Utility</b> <br /> <small class="text-muted">({{ ver }})</small></p>
            <p class="text-muted"><small>&copy; {{currentDate | date: 'yyyy'}} Copyright Pica Product Development.</small></p>
        </div>
    </div>
</footer>
<!--Footer Ends-->
