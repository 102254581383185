import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FotaService {
  lb4Url = environment.API_BASE_URL;

  constructor(private _httpClient: HttpClient) {
  }

  triggerDeviceUpdate(firmware: any, devices): Observable<any> {
    let serials = [];
    devices.forEach((ele: { device_serial: string }) => { 
      serials.push(ele.device_serial);
    });
    let w = { "device_serial": { "inq": serials } };

    let url = `${this.lb4Url}/devices?where=${JSON.stringify(w)}`;
    return this._httpClient.patch<any[]>(url, { unit_allowedfirm: firmware }).pipe(catchError(this.handleError));;
  }

  getFirmwareDevicePopulation(): Observable<any> {
    let filter = {"fields":{"device_serial": true, "unit_currentfirm": true}}

    let url = `${this.lb4Url}/devices?filter=${JSON.stringify(filter)}`;
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError));;
  }

  getTargetFirmware(): Observable<any[]> {
    let w = {"order": "version DESC"}
    const url = `${this.lb4Url}/firmware?filter=${JSON.stringify(w)}`;
    return this._httpClient.get<any[]>(url).pipe(catchError(this.handleError));
  }

  getDeployedFirmware(): Observable<any> {
    let filter = JSON.stringify({ "fields": { "version": true }, "order": "version DESC" });
    let url = `${this.lb4Url}/firmware?filter=${filter}`;
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError));
  }

  uploadFirmwareFile(file: string, model: string, version: string) {
    let url = `${this.lb4Url}/firmware/addFirmware`;
    const body = {
      firmware: file,
      model: model,
      version: version
    }
    return this._httpClient.post(url, body).pipe(catchError(this.handleError));;
  }
  
  updateReleaseNotes(id: number, notes: string) {
    let url = `${this.lb4Url}/firmware/${id}`;
    return this._httpClient.patch(url, { notes: notes }).pipe(catchError(this.handleError));;
  }

  deleteFirmwareFile(id): Observable<any[]> {
    let url = `${this.lb4Url}/firmware/${id}`;
    return this._httpClient.delete<any[]>(url).pipe(catchError(this.handleError));;
  }

  getDevices(): Observable<any> {
    let url = `${this.lb4Url}/devices`;
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError));;
  }

  private handleError(e: HttpErrorResponse) {
    if (e.error instanceof ErrorEvent) {
      console.log('Client side error: ', e.error.message);
    } else {
      console.log('Server side error: ', e.error.message);
    }
    return throwError('There was a problem with the request.');
  }

  getDevicesByFirmware(f, s): Observable<any> {
    let w = {};
    let url = '';
    let limit = 500;
    if (!s) {
      if (f != 'all') {
        w = { "where": { "unit_currentfirm": f }, "limit": limit };
      } else {
        w = { "where": { "unit_currentfirm": { "regexp": "." } } };
        w = { "limit": limit}; // This was added to find devices w/out any firmware assigned. This is not normally the case, so will leave it commented for the time being.
      }
    } else {
      if (f != 'all') {
        w = { "where": { "and": [{ "unit_currentfirm": f }, { "device_serial": { "like": '%25' + s + '%25' } }] }, "limit": limit }
      } else {
        w = { "where": { "and": [{ "device_serial": { "like": '%25' + s + '%25' } }] }, "limit": limit }
      }
    }
    url = `${this.lb4Url}/devices?filter=${JSON.stringify(w)}`;
    return this._httpClient.get<any>(url).pipe(catchError(this.handleError));
  }

  getTitle() {
    return "Skyhawk";
  }

}
