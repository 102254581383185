<h1>Enterprise List</h1>
<div class="content-body">
  <div [hidden]="!showLoader" class="loaderCircle"></div>

  <app-table-header [title]="title" [deviceCount]="userCount" [currentPage]="currentPage" [pageCount]="pageCount"></app-table-header>

  <app-search (onSearch)="getData($event, true)" (onDisplayPage)="getData($event, true)" [pageCount]="pageCount" 
    [placeholder]="placeholder" [pageDensity]="pageDensity">
  </app-search>

  <app-table-pagination [disableNext]="disableNext" [disablePrevious]="disablePrevious" [pageDensity]="pageDensity"
    (onSetPageDensity)="onSetPageDensity($event)" (onDisplayNext)="getData($event, true)" 
    (onDisplayPrevious)="getData($event, true)"  [currentPage]="currentPage" [pageDensityValues]="pageDensityValues">
  </app-table-pagination>

  <app-message *ngIf="messageService.ownerKey !== 'create-bus'"></app-message>

  <div *ngIf="businesses.length > 0">
    <app-table-header-sort [fields]="fields" [(data)]="businesses" [_sortData]="sortData" [scroll]="true" >
      <tbody>
        <tr *ngFor="let u of businesses" [class.table-active]="u.id===pId">
          <td class="btn-link" (click)="onShowDetail(u.id)">{{ u.name }}</td>
          <td class="email-overflow">{{ u.city }}</td>
          <td>{{ u.state }}</td>
        </tr>
      </tbody>
    </app-table-header-sort>
  </div>
  <br>
  <a class="btn btn-outline-secondary" (click)="createBusiness()">Add Enterprise Customer...</a>
</div>
