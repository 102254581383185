<h1>User List</h1>
<div class="content-body">
  <div [hidden]="!showLoader" class="loaderCircle"></div>

  <div class="row mb-1 text-muted">
    <div class="col-9">
      <small class="pull-left"><i>Total User Count: {{ userCount }}</i></small>
    </div>
    <div class="col-3">
      <small class="pull-right"><i>Page {{ currentPage+1 }} of {{pageCount}}</i></small><br />
    </div>
  </div>

  <app-search (onSearch)="getData($event, true)" (onDisplayPage)="getData($event, true)" [pageCount]="pageCount" 
    [placeholder]="placeholder" [pageDensity]="pageDensity">
  </app-search>

  <app-table-pagination [disableNext]="disableNext" [disablePrevious]="disablePrevious" [pageDensity]="pageDensity"
    (onSetPageDensity)="onSetPageDensity($event)" (onDisplayNext)="getData($event, true)"
    (onDisplayPrevious)="getData($event, true)" [currentPage]="currentPage"> 
  </app-table-pagination>

  <app-message></app-message>
  <div class="horizontal-scroll" >
    <app-table-header-sort [fields]="fields" [(data)]="users" [_sortData]="sortData">
      <tbody>
        <tr *ngFor="let u of users"  [class.table-active]="u.id===pId">
          <td>{{ u.id }} </td>
          <td class="btn-link" (click)="onEdit(u)">{{ u.email }} </td>
          <td>{{ u.phone }} </td>
        </tr>
      </tbody>
    </app-table-header-sort>
  </div>
</div>