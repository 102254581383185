<div class="apex-content content container-fluid">
  <h1 *ngIf="!submitted">Manage Devices <small>(Skyhawk Firmware)</small></h1>

  <div *ngIf="submitted" class="content-wrapper">
    <div class="content-body">
      <div class="card p-2">
        <div class="card-header">
          <h5 class="card-title text-muted">Request Submitted </h5>
        </div>
        <div class="card-body" aria-expanded="true">
          <div class="card-block">

            <p class="card-text">A request has been submitted to upgrade the Skyhawk firmware to
              <b>{{ selectedTargetFirmware }}</b> for the following {{ selectedDevices.length }} device(s). It will take
              some time for the units to be updated.
            </p>

            <button class="btn btn-outline-secondary mt-0 px-3" (click)="reload()">OK</button>
            <h3 class="text-muted text-center">Device List</h3>
            <ul>
              <li *ngFor="let d of selectedDevices" class="list-group-item">{{ d.device_serial }}</li>
            </ul>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
  </div>

  <div *ngIf="!submitted" class="content-wrapper">
    <div class="content-body">
      <form [formGroup]='manageForm' (ngSubmit)='onSubmit()'>
        <div class="form-group">
          <label>Currently Deployed Firmware</label>

          <select class="form-control" formControlName="currentFirmware"
             (change)="onChangeCurrentFirmware()" required>
            <option value="">Please select devices by firmware</option>
            <option value="{{c.version}}" *ngFor="let c of currentFirmArray">{{ c.version }} </option>
            <option value="all">All devices running any firmware</option>
            <option *ngIf="param_devices.length > 0" value="user">User Devices</option>
          </select>

          <small class="text-danger" *ngIf="currentFirmware.invalid && currentFirmware.touched">
            Please choose a firmware version.
          </small>
        </div>

        <button [class.not-allowed]="invalid()" [disabled]="invalid()" class="btn btn-outline-warning" [ngClass]="{'btn-outline-danger': forceUpdate === true}">
          Update Device Firmware
        </button>
        <br>
        <div>
          <input type="checkbox" class="checkbox input-position" name="force-update" id="force-update-checkbox" (click)="setForceUpdate()" />
          <label for="force-update">Force Update</label>
          <p style="font-size: 13px;font-style: italic;">(Checking this option will cause the unit to update the next time it connects into the server. Otherwise, the unit will update the next time it is power cycled.)</p>
        </div>
        <app-message></app-message>
      </form>
    </div>
  
    <div *ngIf="currentFirmware.valueChanges && currentFirmware.value !== ''" class="form-group my-1 content-body content-position">
      <app-search (onSearch)="getDevicesByFirmware()" (onDisplayPage)="getData($event)" [pageCount]="pageCount" 
        [placeholder]="placeholder" [pageDensity]="pageDensity" [showGoToPage]="false">
      </app-search>

      <p *ngIf="this.shb?.length == 0 && this.shp?.length == 0 && this.shh?.length == 0">
        No devices found...
      </p>
      
      <app-device-update-list [devices]="shb" [title]="'SHB - Kiwi'" [targetFirmArray]="targetFirm" *ngIf="shb && targetFirm"
        (targetFirmware)="onSetTargetFirm($event)" (onChangeSelect)="selectDevice($event, shbSelected)">  
      </app-device-update-list>
      
      <app-device-update-list [devices]="shp" [title]="'SHP - PIR'" [targetFirmArray]="targetFirm" *ngIf="shp && targetFirm"
        (targetFirmware)="onSetTargetFirm($event)" (onChangeSelect)="selectDevice($event, shpSelected)">
      </app-device-update-list>

      <app-device-update-list [devices]="shh" [title]="'SHH - Hub'" [targetFirmArray]="targetFirm" *ngIf="shh && targetFirm"
        (targetFirmware)="onSetTargetFirm($event)" (onChangeSelect)="selectDevice($event, shhSelected)">
      </app-device-update-list>
    </div>
  </div>
</div>