import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QaToolsComponent } from './qa-tools.component'
const routes: Routes = [
  { path: 'show', component: QaToolsComponent, data: { title: 'User List' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QaToolsRoutingModule { }

export const QATOOLS_ROUTING_COMPONENTS = [QaToolsComponent];