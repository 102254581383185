import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TablesComponent } from './tables.component';

const routes: Routes = [
  { path: 'list', component: TablesComponent, data: { title: 'Tables' } },
];
 
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TablesRoutingModule { }

export const USER_ROUTING_COMPONENTS = [TablesComponent];
