import { Injectable } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DeviceUpdateListService {

  targetFirmArrayRef: any[];

  constructor(private _fb: FormBuilder) { }

  targetForm = this._fb.group({
    shbTargetFirm: ['', [Validators.required]],
    shpTargetFirm: ['', [Validators.required]],
    shhTargetFirm: ['', [Validators.required]],
    selection: ['', [Validators.required]],
  });

  get shbTargetFirm() { return this.targetForm.get('targetFirm'); }
  get shpTargetFirm() { return this.targetForm.get('targetFirm'); }
  get shhTargetFirm() { return this.targetForm.get('targetFirm'); }
  get selection() { return this.targetForm.get('selection'); }

  manageForm = this._fb.group({
    currentFirmware: ['', [Validators.required]]
  });

  get currentFirmware() { return this.manageForm.get('currentFirmware'); }
}
