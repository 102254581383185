import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styles: [`
  .custom-label {
    color: #75787d;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 500;
    margin-right: 20px;
    bottom: 1px;
    position: relative;
  }
  `]
})
export class ConfirmationDialogComponent {

  @Input() title: string;
  @Input() message: string;
  @Input() note: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() modalClass: string = '';
  @Input() optionText: string = null;

  option = false;

  constructor(private activeModal: NgbActiveModal) { }

  setChecked() {
    this.option = (<HTMLInputElement>document.getElementById('option') as HTMLInputElement).checked;
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}