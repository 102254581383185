import { Component, OnInit } from '@angular/core';
import { FotaService } from '../../fota.service';
import { FotaFirmModel } from '../../models/fotafirm.model';
import { NotesDialogService } from '../../../../../app/shared/dialog/notes-dialog/note-dialog.service';
import { AuthService } from 'app/shared/auth/auth.service';

@Component({
  selector: 'app-firmware-list',
  templateUrl: './firmware-list.component.html',
  styleUrls: ['./firmware-list.component.scss']
})
export class FirmwareListComponent implements OnInit {
  fotaFirmFiles: FotaFirmModel[];
  errorMessage = '';
  title = null;
  disabled = true;
  displayInput = false;
  showDialog: boolean = false;
  notes: string;

  constructor(
    private _fotaService: FotaService,
    private _notesDialogService: NotesDialogService,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.getFotaFiles();
    this.title = this._fotaService.getTitle();
  }

  // Update release notes from notes dialog
  onEdit(f) {
    this._notesDialogService.open("Release Notes", 'Notes: ', 'Update', 'Cancel', f.notes).then((notes: any) => {
      if (notes !== false) {
        if (f.notes != notes || f.notes === '' && notes === '') {
          this._fotaService.updateReleaseNotes(f.id, notes).subscribe((response) => {
            this.errorMessage = '';
            this.getFotaFiles();
          }, (err) => { 
            this.errorMessage = err.error.error.message; 
          });
        }
      }
    });
  }

  onDelete(f) {
    if (confirm("Are you sure you want to delete the following firmware instance: " + f.id + "?")) {
      this._fotaService.deleteFirmwareFile(f.id).subscribe((response) => {
        let i = this.fotaFirmFiles.indexOf(f);
        this.fotaFirmFiles.splice(i, 1);
        this.errorMessage = null;
      }, (err) => {
        this.errorMessage = err.error.error.message;
      });
    }
  }

  private getFotaFiles() {
    this._fotaService.getTargetFirmware().subscribe((data) => {
      for (let ele of data) {
        ele.created = new Date(ele.created).toLocaleString();
      }
      this.fotaFirmFiles = data;
    }, (error) => {
      this.errorMessage = error;
    });
  }
}
