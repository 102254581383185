<div class="apex-content content container-fluid">
  <h1>Manage Verizon's Firmware</h1>
  <p *ngIf="errorMessage" class="text-danger ml-3">Error detected: {{ errorMessage }}</p>
  <div class="content-wrapper">
    <div class="content-body">
      <div class="horizontal-scroll">
      <h2>Verizon Firmware <small>(S3/DB)</small></h2>
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Package Name</th>
            <th>Upload Date</th>
            <th>Type</th>
            <th>Req</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let f of fotaFirmFiles">
            <td>{{ f.id }} </td>
            <td>{{ f.vfota_pn }} </td>
            <td>{{ f.vfota_date | date:'medium'}} </td>
            <td>{{ f.vfota_type }} </td>
            <td>{{ f.vfota_req }} </td>
            <td><a (click)="onDelete(f)" class="text-danger" [attr.disable-item]="!auth.isMaster"><i class="feather ft-delete"></i> Delete</a></td>
          </tr>
        </tbody>
      </table>
      <a class="btn btn-outline-secondary" routerLink="../upload">Add New Firmware ...</a>
      </div>
    </div>
  </div>
</div>