import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SearchService } from './search/search.service';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styles: [`
    ul {
      margin-bottom: 0 !important;
    }
  `]
})
export class TablePaginationComponent implements OnInit {

  constructor(private _searchService: SearchService) { }

  @Input() pageDensity = 50;
  @Input() disableNext = true;
  @Input() disablePrevious = true;
  @Input() pageDensityValues = [50, 100, 200];
  @Input() currentPage = 0;

  @Output() onDisplayNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDisplayPrevious: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSetPageDensity: EventEmitter<any> = new EventEmitter<any>();

  goToPage: AbstractControl;

  ngOnInit() {
    this.goToPage = this._searchService.goToPage;
  }

  emitOnDisplayNext() {
    this.currentPage++;
    this.goToPage.setValue(this.goToPage.value + 1)
    this.onDisplayNext.emit({ cp: this.currentPage, skip: this.skip() });
  }

  emitOnDisplayPrevious() {
    this.currentPage--;
    this.goToPage.setValue(this.goToPage.value - 1)
    this.onDisplayPrevious.emit({ cp: this.currentPage, skip: this.skip() });
  }

  emitOnSetPageDensity(val) {
    this.pageDensity = val;
    this.onSetPageDensity.emit(val);
  }

  private skip() {
    return (this.currentPage * this.pageDensity)
  }

}
