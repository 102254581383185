import { Component, OnInit, Input } from '@angular/core';

@Component({ // class="table table-striped table-bordered"
  selector: 'app-table-list',
  template: ` 
  <div class="overflow">
    <table class="table table-bordered table-striped">

      <thead>
        <tr>
          <th *ngFor="let c of columns" class="">{{ c | uppercase }}</th>
        </tr>
      </thead>

      <tbody>
        <tr  *ngFor="let r of rows">
          <td>{{ r.attribute }}</td>
          <td><b>{{ r.value }}</b></td>
        </tr>
      </tbody>  

    </table>
  </div>
  `,
  styles: [`
  .overflow {
    max-height: 320px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  `]
})
export class TableListComponent implements OnInit {

  constructor() { }

  @Input() columns;
  @Input() rows;

  ngOnInit() { }

}


