// animations.ts
import {
    trigger,
    state,
    style,
    animate,
    transition,
  } from '@angular/animations';

export const Animations = {
    openClose: 
    trigger('openClose', [
        state('open', style({
          opacity: 1.0
        })),
        state('closed', style({
          height: '0px',
          opacity: 0.0
        })),
        transition('open => closed', [
          animate('0.33s')
        ]),
        transition('closed => open', [
          animate('0.33s')
        ]),
      ])
}