import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-table-header-sort',
  templateUrl: './table-header-sort.component.html',
  styleUrls: ['./table-header-sort.component.scss']
})

export class TableHeaderSortComponent {
  private _data: any;
  private active: string;
  private direction: any;
  private queue = [];
  private reset = false;

  @Input() fields: any;
  @Input() scroll = false;
  @Input() _sortData = false;
  @Input() sticky = true;
  @Input() defaultSort?;
  
  /**
   *  When data is set, sort data if needed.
   *  This is a work-around solution because MatSort 
   *  does not support our custom pagination
   */
  @Input() set data(value: any) {  
    this._data = value;

    if (this._sortData === true && !this.reset) {
      let sort = new MatSort();
      if (this.defaultSort) {
        sort.active = this.defaultSort
        sort.direction = "asc"
      } else {
        sort.active = this.active;
        sort.direction = this.direction;
      }
      this.queue = [this._data.slice()];
      this.sortData(sort);
    }
  }

  @Output() dataChange = new EventEmitter<any[]>();

  constructor() { }

  sortData(sort: MatSort) {
    this.active = sort.active;
    this.direction = sort.direction;
    this.reset = false;

    if (this.queue.length < 1) {
      this.queue.push(this._data.slice());
    }
    if (!sort.active || sort.direction === '') {
      this.reset = true;
      return this.dataChange.emit(this.queue.shift());
    }
    const dataSort = this._data;

    this._data = dataSort.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      for (let ele of this.fields) {
        if (ele.field === sort.active) {
          return this.compare(a[ele.field], b[ele.field], isAsc);
        }
      }
      return 0;
    });

    return this.dataChange.emit(this._data);
  }

  compare(a: string | number, b: string | number, isAsc: boolean) {
    // null values are not well supported
    if (a === null) { a = ''; }
    if (b === null) { b = ''; }

    // pervent sorting from being case sensitive 
    if (typeof a === 'string' && typeof b === 'string') {
      a = a.toLowerCase();
      b = b.toLowerCase();
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
