import { Injectable, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private _fb: FormBuilder) { }

  // Form Setup
  searchForm = this._fb.group({
    searchTerm: ['']
  });
  get searchTerm() { return this.searchForm.get('searchTerm'); }
  
  goToForm = this._fb.group({
    goToPage: [1, Validators.required]
  });
  get goToPage() { return this.goToForm.get('goToPage'); }
  // End Form Setup

  public configDisabledFlags(currentPage, disableNext, disablePrevious, pageCount): any[] {
    if (currentPage == 0) {
      disablePrevious = true;
      disableNext = false;
    } else if (currentPage == pageCount - 1) {
      disablePrevious = false;
      disableNext = true;
    } else if (currentPage != 0 && (currentPage != (pageCount - 1))) {
      disablePrevious = false;
      disableNext = false;
    }
    if (pageCount <= 1) {
      disablePrevious = true;
      disableNext = true;
    }
    return [disableNext, disablePrevious]
  }
}
