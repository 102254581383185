import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Firmware } from '../../models/firmware.model';
import { FirmwareReq } from '../../models/firmware-req.model';
import { FirmwareType } from '../../models/firmware-type.model';
import { FotaService } from '../../fota.service';
import { environment } from 'environments/environment'
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'app/shared/components/message/message.service'
import { Alert } from '../../../../shared/components/message/message.alert.enum';

@Component({
  selector: 'app-firmware-upload',
  templateUrl: './firmware-upload.component.html',
  styleUrls: ['./firmware-upload.component.scss']
})
export class FirmwareUploadComponent implements OnInit {
  
  selectedFirmware = new Firmware(1, "", "", "", new Date(), "");
  selectedFileToUpload = null;
  firmwareTypes: FirmwareType[];
  firmwareReqs: FirmwareReq[];
  submitted = false;
  fileContent: string;
  title = null;
  
  uploadForm = this._fb.group({
    model: ['', [
      Validators.required,
      Validators.minLength(3),
      Validators.pattern('[A-Z]{3}'),
    ]],
    file: ['', [Validators.required]],
    version: ['', [
      Validators.required, 
      Validators.minLength(2), 
      Validators.maxLength(5), 
      Validators.pattern("^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]{2,5}$")
    ]]
  });
  get model() { return this.uploadForm.get('model'); }
  get version() { return this.uploadForm.get('version'); }
  
  constructor(
    private _fotaService: FotaService,
    private _fb: FormBuilder,
    private _messageService: MessageService
  ) { }

  ngOnInit() {
    if (environment.DEBUG) { console.log('in firmware.component.ngOnInit ...'); }
    this.submitted = false;
    this.selectedFileToUpload = null;
    this.title = this._fotaService.getTitle();
  }

  onFileSelected(event) {
    if (environment.DEBUG) { console.log('in firmware.component.onFileSelected ...'); }
    this.selectedFileToUpload = event.target.files[0];
    this.selectedFirmware.name = this.selectedFileToUpload.name;
    if (environment.DEBUG) { console.log(event); console.log(this.selectedFileToUpload); }
  }

  // Upload firmware file to the cloud.
  onFileUpload() {
    this.selectedFileToUpload.model = this.model.value;
    let name = this.selectedFileToUpload.name;
    this.selectedFileToUpload.version = this.version.value;
    
    let reader = new FileReader();
    reader.onload = () => {
      let fileContent = reader.result.toString();
      this.uploadFirmware(fileContent, name);
    }
    reader.readAsText(this.selectedFileToUpload);
  }

  private uploadFirmware(fileContent: string, name: any) {
    this._fotaService.uploadFirmwareFile(fileContent, this.model.value, this.version.value).subscribe(() => {
      this.submitted = true;
      this._messageService.setMessage(Alert.SUCCESS, "Uploaded file: " + name);
    }, (err) => {
      this._messageService.setMessage(Alert.DANGER, err);
      console.log('Error: firmware.component.onFileUpload', err);
    });
  }

  onAddAnother() {
    this.submitted = !this.submitted;
  }
}
