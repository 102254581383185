import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeviceAddComponent } from './device-add/device-add.component';
import { SensorAddComponent } from './sensor-add/sensor-add.component';
import { VzwreqsComponent } from './vzreqs/vzwreqs.component';

const routes: Routes = [
  { path: 'add-device', component: DeviceAddComponent, data: { title: 'Device Add' } },
  { path: 'add-sensor', component: SensorAddComponent, data: { title: 'Sensor Add' } },
  { path: 'vzwreqs', component: VzwreqsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdvancedRoutingModule { }

export const ADVANCED_ROUTING_COMPONENTS = [
  DeviceAddComponent,
  SensorAddComponent,
  VzwreqsComponent
];
