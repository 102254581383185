<div *ngIf="dataLoaded" class="apex-content content container-fluid">
  <h1>Sensor Details : {{ sensor.sensor_serial }}</h1>
  <div class="content-wrapper">
    <div class="content-body">

      <form [formGroup]="updateForm" (ngSubmit)="onUpdate()">
        <div class="form-group">
          <label>Sensor Name</label>
          <input formControlName="deviceName" type="text" class="form-control" required>
          <div *ngIf="deviceName.invalid && (deviceName.dirty || deviceName.touched)" class="alert alert-danger">
            <div *ngIf="deviceName.errors.required"> Sensor Name is required. </div>
            <div *ngIf="deviceName.errors.minlength"> Sensor Name must be at least 2 characters long.</div>
          </div>
        </div>

        <div class="form-group">
          <label>Sensor Description</label>
          <input formControlName="deviceDescription" type="text" class="form-control">
          <div *ngIf="deviceDescription.invalid && (deviceDescription.dirty || deviceDescription.touched)"
            class="alert alert-danger">
            <div *ngIf="deviceDescription.errors.required"> Sensor Description is required. </div>
          </div>
        </div>

        <button id="btn-swap" class="btn btn-outline-warning" (click)="onSwap()" type="button">Swap Sensor
        </button>

        <button id="btn-update" [disabled]="updateForm.invalid" class="btn btn-outline-warning" (click)="onUpdate()"
          type="button">Update Sensor
        </button>

        <button id="btn-delete" [disabled]="updateForm.touched" class="btn btn-outline-danger" style="margin-left: 20px"
          type="button" (click)="onDelete()" [disabled]="!auth.isMaster">Delete Sensor
        </button>
      </form>

      <app-message></app-message>

      <div class="row">
        <div class="col">
          <div class="shadow">
            <div class="card-body m-1">
              <div>

                <div class="table-title">
                  <p>User</p>
                  <button id="userLink" class="btn btn-outline-secondary" type="button"
                    routerLink="{{ userDetailLink }}">User Detail
                  </button>
                </div>

              </div>
              <app-table-list [columns]="[]" [rows]="userRelated"></app-table-list>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="shadow">
            <div class="card-body m-1">

              <div class="table-title">
                <p>Device Tracking</p>
                <button id="btn-reset-tc" class="btn btn-outline-secondary" type="button"
                  title="Reset Lifetime Trigger Count" (click)="onResetTC()">
                  Reset TC
                </button>
              </div>

              <app-table-list [columns]="[]" [rows]="trackingRelated"></app-table-list>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="shadow">
            <div class="card-body m-1">
              <p>Subscription</p>
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Plan</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let s of subscription">
                    <td> {{ s.subscription_id }} </td>
                    <td> {{ s.plan_id}} </td>
                    <td> {{ s.status }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="shadow">
            <div class="card-body m-1">
              <div class="table-title">
                <p>Logs</p>
                <div>
                  <a class="btn btn-outline-secondary" href="{{ deviceLogSearch }}" target="_blank">Kibana Search</a>
                </div>
              </div>
              <div>
                <app-table-header [deviceCount]="logCount" [currentPage]="currentPage" [pageCount]="pageCount"
                  [title]="'Log Count'"></app-table-header>

                <app-table-pagination [disableNext]="disableNext" [disablePrevious]="disablePrevious"
                  [pageDensity]="pageDensity" (onSetPageDensity)="onSetPageDensity($event)"
                  (onDisplayNext)="getLogs($event, true)" (onDisplayPrevious)="getLogs($event, true)"
                  [currentPage]="currentPage">
                </app-table-pagination>
              </div>
              <app-table-logs id="logs" [rows]="logRelated" [sortData]="sortData" [comm]="'sensor'" [currentPage]="currentPage"></app-table-logs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>