<div id="qa-tools">
  <h1>QA Tools</h1>
  <div id="device-holder">
    <h4>Your Devices</h4>
    <app-message></app-message>
    <div id="dev-table">
      <app-table-header-sort [fields]="userFields" [(data)]="userDevices" [_sortData]="true" [sticky]="false" [defaultSort]="'type'">
        <tbody>
          <tr *ngFor="let d of userDevices">
            <td style="min-width: 180px; width: 180px;">
              <div class="flex-cv serial">
                <div>{{d.device_serial}}</div>
                <img [src]="d.qr" (click)="enlargeQr(d)">
              </div>
            </td>
            <td>{{d.type}}</td>
            <td>
              <div class="flex-cv imei-iccid">
                <div *ngIf="d.unit_imei !== ''; else imeiTemplate">{{d.unit_imei}}, {{d.unit_iccid}}</div>
              </div>
            </td>
            <td style="min-width: 110px; width: 110px;">
              <button *ngIf="d.isVirtual; else deleteDeviceTemplate" id="rm-acct" (click)="rmAcct(d)">
                Remove From Account
              </button>
              <ng-template #deleteDeviceTemplate>
                <button id="del-dev" class="feather ft-delete" (click)="rmAcct(d, true)"></button>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </app-table-header-sort>
    </div>
    <br>
    <h4>Virtual Devices</h4>
    <div id="dev-table" #virtDiv>
      <app-table-header-sort [fields]="virtualFields" [(data)]="virtualDevices" [_sortData]="true" [sticky]="false">
        <tbody>
          <tr *ngFor="let vr of virtualDevices">
            <td style="min-width: 180px; width: 180px;">
              <div class="flex-cv serial">
                <div>{{vr.device_serial}}</div>
                <img [src]="vr.qr" (click)="enlargeQr(vr)">
              </div>
            </td>
            <td style="min-width: 410px;">
              <div class="flex-cv imei-iccid">
                <div *ngIf="vr.unit_imei !== ''; else imeiTemplate">{{vr.unit_imei}}, {{vr.unit_iccid}}</div>
                <button id="edit-imei-iccid" class="blue-btn" (click)="editImeiIccid(vr)" [disabled]="vr.unit_imei === ''">Edit</button>
              </div>
            </td>
            <td style="min-width: 80px; width: 80px;">
              <button id="add-acct" (click)="editImeiIccid(vr, true)" [disabled]="!vr.isVirtual">Add</button>
            </td>
            <td style="min-width: 55px; width: 55px; border-right: none;">
              <button id="del-dev" class="feather ft-delete" (click)="delete(vr.device_serial)" [disabled]="!vr.isVirtual || !auth.isMaster"></button>
            </td>
          </tr>
        </tbody>
      </app-table-header-sort>
    </div>

    <div *ngIf="auth.isMaster">
      <div class="btn-group" dropdown #dropdown="bs-dropdown" container="body">
        <button id="button-nested" dropdownToggle type="button" class="btn btn-outline-secondary dropdown-toggle">
          {{ btnText }}
        </button>
        <ul *dropdownMenu class="dropdown-menu">
          <ng-container *ngFor="let u of unitTypes">
            <li *ngIf="u.name !== 'Sensor'; else sensorTemplate" class="dropdown-item" (click)="onSelect($event.target.textContent)">{{u.name}}</li>
          </ng-container>
        </ul>
      </div>
      <button class="btn btn-primary" style="margin-bottom: 0px;" id="add-vr" (click)="addVirtual()">Submit</button>
    </div>
  </div>
</div>

<ng-template #sensorTemplate>
  <div class="sensor-hover">
    <li class="sensor" (click)="onSelect('Sensor')">Sensor</li>
    <select id="ut-sel" [(ngModel)]="sensorType" (click)="$event.stopPropagation()" (change)="onSelect('Sensor'); dropdown.hide()">
      <option *ngFor="let s of sensors" value="{{ s }}">{{ s }}</option>
    </select>
  </div>
</ng-template>

<ng-template #imeiTemplate>
  <div></div>
</ng-template>