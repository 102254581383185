import { Component, OnInit } from '@angular/core';
import { Animations } from 'app/shared/animations/animations';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClientService } from 'app/http-client.service';

@Component({
  templateUrl: './vzwreqs.component.html',
  animations: [Animations.openClose]
})

export class VzwreqsComponent implements OnInit {

  public vzreqs;

  constructor(
    private _httpClientService: HttpClientService,
    private _fb: FormBuilder
  ) { }

  searchForm = this._fb.group({
    searchTerm: ['', [Validators.required]]
  });
  get searchTerm() { return this.searchForm.get('searchTerm'); }

  ngOnInit() { }

  public getVzwreqs() {
    this._httpClientService.getVzreqs(this.searchTerm.value).subscribe((res) => {

      this.checkForUnknownDate(res);
      
      this.vzreqs = res;
    }, (error) => { console.error(error); }
  )};

  private checkForUnknownDate(res) {
    for (let i = 0; i < res.length; i++) {
      let created = res[i].created;
      
      if (created === 0) {
        res[i].created = "Unknown";
      } else {
        res[i].created = new Date(created).toLocaleString();
      }
    }
  }
}