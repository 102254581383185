import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth-guard.service';

// Routes for content layout with sidebar, navbar and footer
export const FULL_ROUTES: Routes = [
  { path: 'fota/skyhawk', loadChildren: () => import('../../fota/skyhawk/fota.module').then(m => m.FotaModule), canActivate: [AuthGuard] },
  { path: 'fota/verizon', loadChildren: () => import('../../fota/verizon/fota.module').then(m => m.FotaModule), canActivate: [AuthGuard] },
  { path: 'user', loadChildren: () => import('../../user/user.module').then(m => m.UserModule), canActivate: [AuthGuard] },
  { path: 'device', loadChildren: () => import('../../device/device.module').then(m => m.DeviceModule), canActivate: [AuthGuard] },
  { path: 'sensor', loadChildren: () => import('../../sensor/sensor.module').then(m => m.SensorModule), canActivate: [AuthGuard] },
  { path: 'advanced', loadChildren: () => import('../../advanced/advanced.module').then(m => m.AdvancedModule), canActivate: [AuthGuard] },
  { path: 'enterprise-customer', loadChildren: () => import('../../enterprise-customer/enterprise.module').then(m => m.EnterpriseModule), canActivate: [AuthGuard] },
  { path: 'qa-tools', loadChildren: () => import('../../qa-tools/qa-tools.module').then(m => m.QaToolsModule), canActivate: [AuthGuard] },
  { path: 'tables', loadChildren: () => import('../../tables/tables.module').then(m => m.TablesModule), canActivate: [AuthGuard] },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

