import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeviceUpdateComponent } from './device/device-update/device-update.component';
import { DeviceListComponent } from './device/device-list/device-list.component';
import { FirmwareListComponent } from './firmware/firmware-list/firmware-list.component';
import { FirmwareUploadComponent } from './firmware/firmware-upload/firmware-upload.component';
import { FirmwarePopulationComponent } from './firmware/firmware-population/firmware-population.component';

const routes: Routes = [
  { path: 'device/update', component: DeviceUpdateComponent, data: { title: 'Manage Devices' } },
  { path: 'device/list', component: DeviceListComponent, data: { title: 'Device Listing' } },
  { path: 'firmware/population', component: FirmwarePopulationComponent, data: { title: 'Firmware Population' } },
  { path: 'firmware/list', component: FirmwareListComponent, data: { title: 'Firmware' } },
  { path: 'firmware/upload', component: FirmwareUploadComponent, data: { title: 'Firmware Upload' } },
  { path: '', redirectTo: '/fota/verizon/firmware/population', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FotaRoutingModule { }
