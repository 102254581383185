interface String {
    padLeft(length: number, char: string | number): string;
    padRight(length: number, char: string | number): string;
}

const _pad = (str: string, toLength: Number, char, left: Boolean) => {
    while (str.length < toLength) {
        if (left) {
            str = (char + str);
        } else {
            str += char;
        }
    }
    
    return str;
}

String.prototype.padLeft = function (this: string, length: Number, char: string | number) {
    return _pad(this, length, char, true);
}

String.prototype.padRight = function (this: string, length: Number, char: string | number) {
    return _pad(this, length, char, false);
}